import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let AddPaymentMethod = class AddPaymentMethod {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ngOnInit() {
        let formBuilder = new FormBuilder();
        this.form = formBuilder.group({
            number: [null, [Validators.required]],
            exp_month: [null, [Validators.required]],
            exp_year: [null, [Validators.required]],
            cvc: [null, [Validators.required]]
        });
    }
    onSubmit() {
        this.data.addPaymentMethod(this.form.getRawValue());
    }
};
AddPaymentMethod = __decorate([
    Component({
        selector: "add-payment-method",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], AddPaymentMethod);
export { AddPaymentMethod };
