import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../../../constant/environment";
import { Api } from "../../api";
let CheckoutService = class CheckoutService {
    constructor(api) {
        this.api = api;
    }
    createCheckoutSession(priceId, stripeCustomerId) {
        let endpoint = this.getCheckoutSessionEndpoint();
        return this.api.request(endpoint, 'POST', { priceId, stripeCustomerId });
    }
    listPlans() {
        let endpoint = this.getPlans();
        return this.api.request(endpoint);
    }
    listPlanPrices() {
        let endpoint = this.getPlanPrices();
        return this.api.request(endpoint);
    }
    getCheckoutSessionEndpoint() {
        return environment.endpoint.admin.createCheckoutSession();
    }
    getPlans() {
        return environment.endpoint.admin.getCheckoutPlans();
    }
    getPlanPrices() {
        return environment.endpoint.admin.getPlanPrices();
    }
};
CheckoutService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], CheckoutService);
export { CheckoutService };
