import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let AddressSummary = class AddressSummary {
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], AddressSummary.prototype, "Address", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AddressSummary.prototype, "title", void 0);
AddressSummary = __decorate([
    Component({
        selector: 'address-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], AddressSummary);
export { AddressSummary };
