import { __decorate } from "tslib";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AddressFormFactory } from "./address";
let AllocateJobFactory = class AllocateJobFactory {
    static form(Allocation) {
        let fb = new FormBuilder();
        let FormGroup = fb.group({
            dangerousForm: [null, null],
            wasteFacilityId: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required, AddressFormFactory.validateAddress],
            ],
            vehicleId: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            collectionDate: [null, [Validators.required]],
            dropOffDate: [null, [Validators.required]],
            multipleCollections: [null, null],
            collectionInfo: fb.array([
                fb.group({
                    roundNumber: [null, null],
                    collectionNumber: [null, null],
                }),
            ]),
            shippersReference: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            wasteCarrierLicense: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            transport: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            PPE: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            areaType: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            destination: [
                {
                    value: null,
                    disabled: true,
                },
                [Validators.required],
            ],
            freightForwarderReference: [null, null],
            shipName: [null, null],
            loadingPlace: [null, null],
            dischargePlace: [null, null],
        });
        !!Allocation &&
            Object.keys(Allocation).forEach((name) => {
                if (FormGroup.controls[name]) {
                    FormGroup.controls[name].patchValue(Allocation[name]);
                }
            });
        return FormGroup;
    }
};
AllocateJobFactory = __decorate([
    Injectable()
], AllocateJobFactory);
export { AllocateJobFactory };
