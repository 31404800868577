import { __decorate } from "tslib";
import { Component } from "@angular/core";
let BasicFooter = class BasicFooter {
};
BasicFooter = __decorate([
    Component({
        selector: "basic-footer",
        template: require('./component.html')
    })
], BasicFooter);
export { BasicFooter };
