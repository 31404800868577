import { __decorate, __metadata } from "tslib";
import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VerifyModal } from '../../verify/component';
import { UserService } from '../../../services/user-api';
let LoginForm = class LoginForm {
    constructor(UserService, dialog, router) {
        this.UserService = UserService;
        this.dialog = dialog;
        this.router = router;
        this.handleLogin = new EventEmitter();
        this.loading = false;
        this.error = null;
        this.forgotPass = false;
    }
    ngOnInit() {
        this.form = (new FormBuilder).group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required]],
        });
    }
    onSubmit() {
        let Login = this.form.getRawValue();
        // start loading
        this.error = null;
        this.loading = true;
        this.UserService
            .login(Login)
            .then(loggedIn => this.handleLogin.next(loggedIn), error => this.handleLoginError(error));
    }
    confirmRegistration(cognitoUser) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = { username: cognitoUser.Username };
        let dialogRef = this.dialog.open(VerifyModal, config);
        dialogRef
            .afterClosed()
            .subscribe(() => this.onSubmit());
    }
    handleLoginError(error) {
        this.loading = false;
        this.forgotPass = false;
        // display error
        if (error.code == 'UserNotConfirmedException') {
            this.confirmRegistration(this.form.getRawValue());
            // open the confirmation modal
            // add ability to resend confirmation code within modal
        }
        else if (error.message === 'Incorrect username or password.') {
            this.forgotPass = true;
            this.error = error.message;
        }
        else {
            this.error = error.message;
        }
    }
};
__decorate([
    Output(),
    __metadata("design:type", Object)
], LoginForm.prototype, "handleLogin", void 0);
LoginForm = __decorate([
    Component({
        selector: 'user-login-form',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [UserService,
        MatDialog,
        Router])
], LoginForm);
export { LoginForm };
