import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EwcCodeService } from "../../../../../../app/services/ewc-codes";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";
let ConsignmentNoteEditWaste = class ConsignmentNoteEditWaste {
    constructor(dialogRef, config) {
        this.dialogRef = dialogRef;
        this.config = config;
    }
    ngOnInit() {
        this.form = this.config.WasteForm;
        this.EwcCodeService = new EwcCodeService(this.form
            .get("ewcCode")
            .valueChanges.pipe(startWith(null))
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged()));
        this.filteredEwcCodes = this.EwcCodeService.filteredEwcCodes;
    }
    onSubmit() {
        this.dialogRef.close(this.form);
    }
    displayEwcCode(EwcCode) {
        return !!EwcCode ? EwcCode.code + " (" + EwcCode.description + ")" : "";
    }
};
ConsignmentNoteEditWaste = __decorate([
    Component({
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], ConsignmentNoteEditWaste);
export { ConsignmentNoteEditWaste };
