import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let JobSummary = class JobSummary {
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], JobSummary.prototype, "Consignment", void 0);
JobSummary = __decorate([
    Component({
        selector: 'job-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], JobSummary);
export { JobSummary };
