import { __decorate } from "tslib";
import { Component } from '@angular/core';
import { environment } from "../../../constant/environment";
let DriverMenu = class DriverMenu {
    constructor() {
        this.driversUrl = environment.driverUrl;
    }
};
DriverMenu = __decorate([
    Component({
        selector: 'driver-menu',
        template: require('./component.html'),
        styles: [require('./component.scss')]
    })
], DriverMenu);
export { DriverMenu };
