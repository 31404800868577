import { __decorate } from "tslib";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnyWasteMaterialModule } from "../material.module";
import { Api } from "../../app/services/api";
import { AdminMenu } from "./components/menu/component";
import { DirectivesModule } from "../directives/module";
import { routing } from "../../app/routing.module";
import { AdminOrganisationApi } from "./services/organisation-api";
import { PreferredRecyclersApi } from "./services/preferred-recyclers-api";
import { SubscriptionApi } from "./services/subscription-api";
import { EAReturnsComparisonReport } from "./components/reports/ea-returns-comparison-report/component";
import { AdminReportApi } from "./services/report-api";
import { InviteRecyclersApi } from "./services/invite-recyclers-api";
import { PaymentManagementApi } from "./services/payment-management-api";
import { InvoiceApi } from "./services/invoice-api";
import { ReportsApi } from "./services/reports-api";
import { ManageUsersList } from "./components/manage-users/list/component";
import { EditUserModal } from "./components/manage-users/edit-user/component";
import { ManageOrg } from "./components/manage-orgs/edit/component";
import { UserModule } from "../user/module";
import { PreferredRecyclers } from "./components/preferred-recyclers/component";
import { UpdateSubscription } from "./components/update-subscription/component";
import { ManageWasteFacilitiesList } from "./components/manage-waste-facilities/list/component";
import { InviteRecyclers } from "./components/preferred-recyclers/invite-recyclers/component";
import { PaymentManagement } from "./components/payment-management/component";
import { Billing } from "./components/billing/component";
import { AddPaymentMethod } from "./components/payment-management/add-payment-method/component";
import { VerifyPassword } from "./components/payment-management/verify-password/component";
let AdminModule = class AdminModule {
};
AdminModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            routing,
            AnyWasteMaterialModule,
            DirectivesModule,
            UserModule,
        ],
        declarations: [
            AdminMenu,
            EAReturnsComparisonReport,
            ManageUsersList,
            ManageWasteFacilitiesList,
            ManageOrg,
            EditUserModal,
            PreferredRecyclers,
            UpdateSubscription,
            InviteRecyclers,
            PaymentManagement,
            Billing,
            AddPaymentMethod,
            VerifyPassword
        ],
        entryComponents: [],
        providers: [
            Api,
            AdminOrganisationApi,
            AdminReportApi,
            PreferredRecyclersApi,
            SubscriptionApi,
            InviteRecyclersApi,
            PaymentManagementApi,
            InvoiceApi,
            ReportsApi
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA],
        exports: [AdminMenu],
    })
], AdminModule);
export { AdminModule };
