import { __decorate, __metadata } from "tslib";
import { Component, ViewEncapsulation } from "@angular/core";
import { Dashboard as DashboardService } from "../../services/dashboard";
import { Api } from "../../../../app/services/api";
// const consignmentNotUpdate: any = require("../consignment-notes/view/");
let BrokerDashboard = class BrokerDashboard {
    constructor(DashboardService, Api) {
        this.DashboardService = DashboardService;
        this.Api = Api;
    }
    ngOnInit() {
        this.DashboardService._list.subscribe((Dashboard) => {
            this.waiting = Dashboard.waiting;
            this.quoted = Dashboard.quoted;
            this.live = Dashboard.live;
            this.closed = Dashboard.closed;
        });
        this.DashboardService.load();
    }
    getConsignmentNote(jobId) {
        const endpoint = "https://gdacgtvud3.execute-api.eu-central-1.amazonaws.com/local/consignment-notes/get/" +
            jobId;
        return this.Api.request(endpoint, "GET");
    }
    updateConsignmentNote(jobId) {
        const endpoint = "https://gdacgtvud3.execute-api.eu-central-1.amazonaws.com/local/consignment-notes/update/" +
            jobId;
        // this.Api.request(endpoint, "PUT", consignmentNotUpdate);
    }
    getConsignmentNoteHistory(jobId, dateTime) {
        const endpoint = "https://gdacgtvud3.execute-api.eu-central-1.amazonaws.com/local/consignment-notes/get/" +
            jobId +
            "/" +
            dateTime;
        this.Api.request(endpoint);
    }
};
BrokerDashboard = __decorate([
    Component({
        selector: "broker-dashboard",
        template: require('./component.html'),
        styles: [require('./component.scss')],
        encapsulation: ViewEncapsulation.None,
    }),
    __metadata("design:paramtypes", [DashboardService,
        Api])
], BrokerDashboard);
export { BrokerDashboard };
