import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, Validators, } from "@angular/forms";
import { UserOrganisationsApi } from "../../../../carrier/services/organisaton-users-api";
let EditUserModal = class EditUserModal {
    constructor(fb, dialogRef, UserOrganisationsApi, data) {
        this.fb = fb;
        this.dialogRef = dialogRef;
        this.UserOrganisationsApi = UserOrganisationsApi;
        this.data = data;
        this.loading = false;
        this.loaded = false;
        this.editProfile = false;
    }
    ngOnInit() {
        const { data: { user, editProfile }, } = this;
        this.editProfile = editProfile;
        if (editProfile) {
            this.title = "Edit profile";
        }
        else {
            this.title = !user ? "Add User" : "Edit User";
        }
        this.buildForm();
        !!user && this.loadFormData(user);
    }
    getErrorMessage() {
        if (this.form.hasError("required")) {
            return "You must enter a value";
        }
        return this.form.hasError("email", "form.email") ? "Not a valid email" : "";
    }
    onSubmit() {
        const userId = this.data.user ? this.data.user.userId : null;
        const formData = this.form.getRawValue();
        this.UserOrganisationsApi.edit(Object.assign(Object.assign({}, formData), { groups: this.data.orgGroups }), userId).then(() => this.dialogRef.close(), (error) => this.submitError = error.error.error);
    }
    loadFormData(user) {
        const { email, name, admin, phone_number } = user;
        const nameSplit = name.split(" ").filter(element => element);
        const firstName = nameSplit.length > 2 ? nameSplit.slice(0, -1).join(" ") : nameSplit[0];
        const lastName = nameSplit[nameSplit.length - 1];
        this.form.patchValue({
            firstName,
            lastName,
            email,
            phone_number,
            admin,
        });
    }
    buildForm() {
        this.form = this.fb.group({
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            phone_number: [null, [Validators.required]],
            admin: [false],
        });
    }
};
EditUserModal = __decorate([
    Component({
        selector: "edit-users-modal",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [FormBuilder,
        MatDialogRef,
        UserOrganisationsApi, Object])
], EditUserModal);
export { EditUserModal };
