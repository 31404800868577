import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
let PreferredRecyclersApi = class PreferredRecyclersApi {
    constructor(Api) {
        this.Api = Api;
    }
    list(organisationId) {
        let endpoint = environment.endpoint.admin.preferredRecyclers(organisationId);
        return this.Api.request(endpoint, "GET");
    }
    insert(carrier) {
        let endpoint = environment.endpoint.admin.preferredRecyclers();
        return this.Api.request(endpoint, "POST", carrier);
    }
    delete(carrier) {
        let endpoint = environment.endpoint.admin.preferredRecyclers();
        return this.Api.request(endpoint, "DELETE", carrier);
    }
};
PreferredRecyclersApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], PreferredRecyclersApi);
export { PreferredRecyclersApi };
