import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { Validators, FormGroupDirective } from "@angular/forms";
import { EnvironmentAgencyService } from "../../../../../../../../app/services/v2/environment-agency.service";
let EnvironmentAgencyFormGroup = class EnvironmentAgencyFormGroup {
    constructor(rootFormGroup, environmentApi) {
        this.rootFormGroup = rootFormGroup;
        this.environmentApi = environmentApi;
        this.propagateChange = (_) => { };
    }
    ngOnInit() {
        this.permitForm = this.rootFormGroup.control.get(this.formGroupName);
        this.permitForm.get("permits").valueChanges.subscribe((res) => {
            if (res !== "" && res !== undefined) {
                this.permitForm.controls["validPermits"].addValidators(Validators.required);
                if (this.countryName !== "England") {
                    this.permitForm.controls["validPermits"].patchValue([res]);
                }
                this.permitForm.controls["validPermits"].updateValueAndValidity();
            }
            else {
                this.permitForm.controls["validPermits"].clearValidators();
                this.permitForm.controls["validPermits"].updateValueAndValidity();
            }
        });
        this.permitForm.get("exemptions").valueChanges.subscribe((res) => {
            if (res !== "" && res !== undefined) {
                this.permitForm.controls["validExemptions"].addValidators(Validators.required);
                if (this.countryName !== "England") {
                    this.permitForm.controls["validExemptions"].patchValue([res]);
                }
                this.permitForm.controls["validExemptions"].updateValueAndValidity();
            }
            else {
                this.permitForm.controls["validExemptions"].clearValidators();
                this.permitForm.controls["validExemptions"].updateValueAndValidity();
            }
        });
        this.formValueSubscription = this.permitForm.valueChanges.subscribe((value) => {
            this.propagateChange(value);
        });
    }
    ngOnDestroy() {
        this.formValueSubscription.unsubscribe();
    }
    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    validatePermits(permits) {
        const validPermits = [];
        const invalidPermits = [];
        const permitNumber = permits.split(",");
        Promise.all(permitNumber.map((permit) => {
            const formattedPermit = permit.trim().replace("/", "-");
            return this.environmentApi
                .validateWastePermit(formattedPermit)
                .then((data) => {
                !!data && validPermits.push(permit);
            })
                .catch((error) => invalidPermits.push(permit));
        })).then(() => {
            this.validPermits = validPermits;
            this.invalidPermits = invalidPermits;
            validPermits.length > 0 &&
                this.permitForm.controls["validPermits"].patchValue(validPermits);
        });
    }
    validateExemptions(exemptions) {
        const validExemptions = [];
        const invalidExemptions = [];
        const permitNumber = exemptions.split(",");
        Promise.all(permitNumber.map((permit) => {
            const formattedPermit = permit.trim().replace("/", "-");
            return this.environmentApi
                .validateWasteExemption(formattedPermit)
                .then((data) => {
                !!data && validExemptions.push(permit);
            })
                .catch((error) => invalidExemptions.push(permit));
        })).then(() => {
            this.validExemptions = validExemptions;
            this.invalidExemptions = invalidExemptions;
            validExemptions.length > 0 &&
                this.permitForm.controls["validExemptions"].patchValue(validExemptions);
        });
    }
    clear(type) {
        if (type === "permits") {
            this.permitForm.controls["validPermits"].patchValue("");
            this.permitForm.controls["permits"].patchValue("");
            this.invalidPermits = "";
        }
        else {
            this.permitForm.controls["validExemptions"].patchValue("");
            this.permitForm.controls["exemptions"].patchValue("");
            this.invalidExemptions = "";
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], EnvironmentAgencyFormGroup.prototype, "formGroupName", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], EnvironmentAgencyFormGroup.prototype, "companyType", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], EnvironmentAgencyFormGroup.prototype, "countryName", void 0);
EnvironmentAgencyFormGroup = __decorate([
    Component({
        selector: "app-ea-form",
        template: require('./environment-agency-form.component.html'),
        styles: [require('./environment-agency-form.component.scss')],
    }),
    __metadata("design:paramtypes", [FormGroupDirective,
        EnvironmentAgencyService])
], EnvironmentAgencyFormGroup);
export { EnvironmentAgencyFormGroup };
