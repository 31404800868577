import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { CarrierWasteTransferNoteApi } from "../../../services/waste-transfer-note-api";
let WasteTransferNoteTransfereeSignature = class WasteTransferNoteTransfereeSignature {
    constructor(WasteTransferNoteApi) {
        this.WasteTransferNoteApi = WasteTransferNoteApi;
    }
    ngOnInit() {
        this.WasteTransferNoteApi._wasteTransferNote.subscribe((wasteTransferNote) => {
            this.jobId = wasteTransferNote ? wasteTransferNote.jobId : null;
            this.TransfereeSignature = wasteTransferNote
                ? wasteTransferNote.transfereeSignature
                : null;
        });
    }
};
WasteTransferNoteTransfereeSignature = __decorate([
    Component({
        selector: "waste-transfer-note-transferee-signature",
        template: require('../../../../directives/waste-transfer-notes-view/transferee-signature.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    }),
    __metadata("design:paramtypes", [CarrierWasteTransferNoteApi])
], WasteTransferNoteTransfereeSignature);
export { WasteTransferNoteTransfereeSignature };
