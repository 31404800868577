import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../../constant/environment";
import { HttpClient } from "@angular/common/http";
import { Api } from "../api";
let CompanyRegistrationService = class CompanyRegistrationService {
    constructor(Http, Api) {
        this.Http = Http;
        this.Api = Api;
    }
    registerCompany(data) {
        let endpoint = this.getRegisterCompanyEndpoint();
        return this.Api.request(endpoint, "POST", Object.assign({}, data));
    }
    saveCompanyLogo(putUrl, blob) {
        return this.Api.s3PutObject(putUrl, blob);
    }
    getRegisterCompanyEndpoint() {
        return environment.endpoint.admin.createOrganisation();
    }
};
CompanyRegistrationService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient, Api])
], CompanyRegistrationService);
export { CompanyRegistrationService };
