import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ProvideQuote } from "../provide/component";
import { AllocateJob } from "../../jobs/allocate/component";
import { ViewQuote } from "../view/component";
import { Dashboard } from "../../../services/dashboard";
import { CarrierQuoteApi } from "../../../services/quote-api";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
let QuoteList = class QuoteList {
    constructor(QuoteApi, Dashboard, dialog, ref) {
        this.QuoteApi = QuoteApi;
        this.Dashboard = Dashboard;
        this.dialog = dialog;
        this.ref = ref;
        this.status = null;
        this.loading = false;
        this.quoteDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "broker",
            "description",
            "collectionDate",
            "status",
            "add",
        ];
    }
    ngOnInit() {
        this.quoteDataSource.sort = this.sort;
        this.quoteDataSource.paginator = this.paginator;
        this.Dashboard._list.subscribe((list) => {
            list[this.status].map((data, index) => {
                list[this.status][index].formattedCollectionDate =
                    data.collectionDate &&
                        moment(data.collectionDate).format("DD/MM/YYYY");
                list[this.status][index].formattedDropOffDate =
                    data.dropOffDate && moment(data.dropOffDate).format("DD/MM/YYYY");
            });
            this.quoteDataSource.data = list[this.status];
            this.ref.detectChanges();
            this.quoteDataSource.sort = this.sort;
            this.quoteDataSource.paginator = this.paginator;
        });
        this.Dashboard._loading.subscribe((loading) => {
            this.loading = loading;
        });
    }
    view(Quote) {
        let config = new MatDialogConfig();
        config.data = {
            Quote: Quote,
            status: this.status,
        };
        config.disableClose = true;
        this.QuoteApi.getConsignment(Quote.quoteId, Quote.consignmentId)
            .then((Consignment) => (config.data.Consignment = Consignment))
            .then(() => {
            let dialog = this.dialog.open(ViewQuote, config);
            dialog.afterClosed().subscribe(() => this.refresh());
        });
    }
    applyQuoteFilter(event) {
        const searchValue = event.target.value;
        this.quoteDataSource.filter = searchValue.trim().toLowerCase();
    }
    provide(Quote) {
        let config = new MatDialogConfig();
        config.data = Quote;
        config.disableClose = true;
        let dialog = this.dialog.open(ProvideQuote, config);
        dialog.afterClosed().subscribe(() => this.refresh());
    }
    remove(Quote) {
        // if (Quote.carrierId === Quote.brokerId) {
        //     // is a carrier owned consignment - delete the whole thing
        //
        //     confirm('Are you sure you want to delete the job?') &&
        //     this.ConsignmentApi
        //         .remove(Quote.consignmentId)
        //         .then(() => this.Dashboard.load());
        //
        // } else {
        alert("Remove Quote: not yet implemented");
        // }
    }
    allocate(Quote) {
        this.QuoteApi.getConsignment(Quote.quoteId, Quote.consignmentId).then((Consignment) => {
            let config = new MatDialogConfig();
            config.data = Consignment;
            config.disableClose = true;
            let dialog = this.dialog.open(AllocateJob, config);
            dialog.afterClosed().subscribe(() => this.refresh());
        });
    }
    // public summary(consignmentId?: string) {
    //
    //     let config = new MatDialogConfig;
    //
    //     config.disableClose = true;
    //
    //     config.data = consignmentId;
    //
    //     let dialogRef = this.dialog.open(EditConsignment, config);
    //
    //     dialogRef
    //         .afterClosed()
    //         .subscribe((Consignment?: Consignment) => {
    //
    //             !!Consignment && this.save(Consignment);
    //         });
    // }
    // public createJob(Consignment: Consignment) {
    //
    //     if (!Consignment.jobId) {
    //
    //         let carrierName = this.UserService.getUsername();
    //
    //         this.ConsignmentApi.get(Consignment.consignmentId)
    //             .then(
    //                 (Consignment: Consignment) => this.Dashboard.carrierCreateJob(Consignment, carrierName),
    //                 () => alert('An issue making the job, please contact support.'),
    //             )
    //             .then(
    //                 () => this.listWaste(Consignment.consignmentId),
    //                 () => alert('An issue making the job, please contact support.'),
    //             );
    //     } else {
    //
    //         this.listWaste(Consignment.consignmentId);
    //     }
    //
    // }
    // public printConsignmentNote(Quote: Quote) {
    //
    //     this.ConsignmentPdf.printConsignmentNote(Quote.consignmentId);
    //
    // }
    // public listWaste(consignmentId: string) {
    //
    //     this.ConsignmentApi
    //         .get(consignmentId)
    //         .then((Consignment: Consignment) => {
    //
    //             let config = new MatDialogConfig;
    //
    //             config.data         = Consignment;
    //             config.disableClose = true;
    //
    //             let dialogRef = this.dialog.open(CarrierListWaste, config);
    //
    //             dialogRef
    //                 .afterClosed()
    //                 .subscribe(() => this.refresh());
    //         });
    // }
    refresh() {
        this.Dashboard.load();
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], QuoteList.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator),
    __metadata("design:type", MatPaginator)
], QuoteList.prototype, "paginator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], QuoteList.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], QuoteList.prototype, "status", void 0);
QuoteList = __decorate([
    Component({
        selector: "quote-list",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [CarrierQuoteApi,
        Dashboard,
        MatDialog,
        ChangeDetectorRef])
], QuoteList);
export { QuoteList };
