import { __awaiter, __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
import { environment } from "../../../../../app/constant/environment";
import { Api } from "../../../../../app/services/api";
import { MatDialog } from "@angular/material/dialog";
import { BrokerConsignmentNoteApi } from "../../../services/consignment-note-api";
let ConsignmentDangerousGoodsNoteDescription = class ConsignmentDangerousGoodsNoteDescription extends ConsignmentNoteBase {
    constructor(ConsignmentNoteApi, dialog, api) {
        super(ConsignmentNoteApi, dialog);
        this.api = api;
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.jobId = consignmentNote ? consignmentNote === null || consignmentNote === void 0 ? void 0 : consignmentNote.jobId : null;
            if (this.jobId) {
                this.loading = true;
                const endpoint = environment.endpoint.broker.dangerousGoodsNote(this.jobId);
                this.dgn = yield this.api.request(endpoint, "GET");
                this.dgn = ((_a = this.dgn) === null || _a === void 0 ? void 0 : _a.dangerousGoods)
                    ? this.dgn.dangerousGoods
                    : this.dgn.DangerousGoods;
                this.loading = false;
            }
        }));
    }
};
ConsignmentDangerousGoodsNoteDescription = __decorate([
    Component({
        selector: "consignment-dangerous-goods-note-description",
        template: require('../../../../directives/waste-transfer-notes-view/dangerous-goods-note.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    }),
    __metadata("design:paramtypes", [BrokerConsignmentNoteApi,
        MatDialog,
        Api])
], ConsignmentDangerousGoodsNoteDescription);
export { ConsignmentDangerousGoodsNoteDescription };
