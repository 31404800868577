import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WasteApi } from "../../../services/waste-api";
import { BehaviorSubject } from "rxjs";
let ViewWaste = class ViewWaste {
    constructor(WasteApi, dialogRef, data) {
        this.WasteApi = WasteApi;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this._Waste = new BehaviorSubject({
            consignmentId: null,
            wasteId: null,
            description: null,
            ewcCode: null,
            components: null,
            physicalForm: null,
            hazardCodes: null,
            container: null,
            qty: null,
            physicalDestructionRequired: null,
            dataDestructionRequired: null,
        });
    }
    ngOnInit() {
        this.loading = true;
        this.WasteApi.get(this.data.consignmentId, this.data.wasteId).then((data) => {
            this.loading = false;
            this._Waste.next(data);
        });
        this._Waste.subscribe((Waste) => {
            this.Waste = Waste;
        });
    }
};
ViewWaste = __decorate([
    Component({
        selector: "view-waste",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [WasteApi,
        MatDialogRef, Object])
], ViewWaste);
export { ViewWaste };
