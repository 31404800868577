import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AddressFormFactory } from "../../../../factories/address";
let EditAddress = class EditAddress {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.form = AddressFormFactory.form();
    }
    ngAfterContentInit() {
        this.contactDetails = !!this.data.contactDetails;
        !!this.data.address && this.reset();
    }
    getAddress(address) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this.addressInfo = address;
        this.form.setValue({
            name: ((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.name) || this.form.value.name,
            address: address.address,
            postcode: address.postcode,
            latitude: address.latitude,
            longitude: address.longitude,
            email: ((_d = (_c = this.data) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.email) || this.form.value.email,
            fax: ((_f = (_e = this.data) === null || _e === void 0 ? void 0 : _e.address) === null || _f === void 0 ? void 0 : _f.fax) || this.form.value.fax,
            mobile: ((_h = (_g = this.data) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.mobile) || this.form.value.mobile,
            telephone: ((_k = (_j = this.data) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 : _k.telephone) || this.form.value.telephone,
        });
    }
    onSubmit() {
        this.dialogRef.close(this.form.value);
    }
    reset() {
        this.form.setValue(this.data.address);
    }
};
__decorate([
    ViewChild("address"),
    __metadata("design:type", Object)
], EditAddress.prototype, "address", void 0);
EditAddress = __decorate([
    Component({
        selector: "edit-address",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], EditAddress);
export { EditAddress };
