import { __decorate } from "tslib";
import { Component } from '@angular/core';
let BrokerMenu = class BrokerMenu {
};
BrokerMenu = __decorate([
    Component({
        selector: 'broker-menu',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], BrokerMenu);
export { BrokerMenu };
