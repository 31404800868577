import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../../constant/environment";
import { Api } from "../api";
let SiteRegistrationService = class SiteRegistrationService {
    constructor(api) {
        this.api = api;
    }
    getWasteFacility(organisationId) {
        let endpoint = this.getRegisterSiteGetEndpoint(organisationId);
        return this.api.request(endpoint);
    }
    deleteWasteFacility(wasteFacilityId) {
        let endpoint = this.getRegisterSiteGetEndpoint(wasteFacilityId);
        return this.api.request(endpoint, "DELETE");
    }
    edit(data) {
        const { wasteFacilityId } = data;
        let endpoint = this.getRegisterSitePostEndpoint(wasteFacilityId);
        const method = !!wasteFacilityId ? "PATCH" : "POST";
        return this.api.request(endpoint, method, data);
    }
    getRegisterSitePostEndpoint(wasteFacilityId) {
        return environment.endpoint.admin.createWasteFacility(wasteFacilityId);
    }
    getRegisterSiteGetEndpoint(organisationId) {
        return environment.endpoint.user.getWasteFacility(organisationId);
    }
};
SiteRegistrationService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], SiteRegistrationService);
export { SiteRegistrationService };
