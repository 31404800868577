import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let PasswordForgotFormFactory = class PasswordForgotFormFactory {
    static form() {
        return (new FormBuilder).group({
            email: [null, Validators.required],
        });
    }
};
PasswordForgotFormFactory = __decorate([
    Injectable()
], PasswordForgotFormFactory);
export { PasswordForgotFormFactory };
