import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WasteFormFactory } from "../../../../../factories/waste";
import { BehaviorSubject } from "rxjs";
import { BrokerWasteApi } from "../../../services/waste-api";
import { EwcCodeService } from "../../../../../app/services/ewc-codes";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";
let EditWaste = class EditWaste {
    constructor(WasteApi, dialogRef, data) {
        this.WasteApi = WasteApi;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this._item = new BehaviorSubject({
            consignmentId: null,
            wasteId: null,
            description: null,
            components: null,
            physicalForm: null,
            hazardCodes: null,
            container: null,
            ewcCode: null,
            qty: null,
            physicalDestructionRequired: false,
            dataDestructionRequired: false,
        });
    }
    ngOnInit() {
        this.consignmentId = this.data.consignmentId;
        this.wasteId = this.data.wasteId ? this.data.wasteId : null;
        this.quoted = this.data.quoted ? true : false;
        this.form = WasteFormFactory.form();
        this.EwcCodeService = new EwcCodeService(this.form
            .get("ewcCode")
            .valueChanges.pipe(startWith(null))
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged()));
        this.filteredEwcCodes = this.EwcCodeService.filteredEwcCodes;
        this._item.subscribe((Waste) => {
            let form = Object.assign({}, Waste);
            this.form.setValue(form);
            Object.keys(form).forEach((name) => {
                if (this.form.controls[name]) {
                    this.form.controls[name].patchValue(form[name]);
                }
            });
        });
        if (this.data.status === "repeat") {
            this.form.enable();
            delete this.data.status;
            delete this.data.version;
            this._item.next(this.data);
            delete this.data.wasteId;
        }
        else {
            !!this.wasteId && this.set();
        }
    }
    onSubmit() {
        this.dialogRef.close(this.form.value);
    }
    set() {
        this.loading = true;
        this.form.disable();
        this.WasteApi.get(this.consignmentId, this.wasteId).then((data) => {
            this.loading = false;
            if (!data.components) {
                data.components = null;
            }
            if (!data.hazardCodes) {
                data.hazardCodes = null;
            }
            delete data.version;
            this.form.enable();
            this._item.next(data);
        });
    }
    displayEwcCode(EwcCode) {
        return !!EwcCode ? EwcCode.code + " (" + EwcCode.description + ")" : "";
    }
};
EditWaste = __decorate([
    Component({
        selector: "edit-waste",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [BrokerWasteApi,
        MatDialogRef, Object])
], EditWaste);
export { EditWaste };
