import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, } from "@angular/material/dialog";
import { Dashboard } from "../../../services/dashboard";
import { ViewConsignmentNote } from "../../consignment-notes/component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EditConsignment } from "../../../../broker/components/consignments/edit/component";
import { EditWaste } from "../../../../broker/components/waste/edit/component";
import { CarrierWasteApi } from "../../../services/waste-api";
import { CarrierConsignmentApi } from "../../../services/consignment-api";
let RepeatConsignment = class RepeatConsignment {
    constructor(Dashboard, dialog, dialogRef, WasteApi, CarrierConsignmentApi) {
        this.Dashboard = Dashboard;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.WasteApi = WasteApi;
        this.CarrierConsignmentApi = CarrierConsignmentApi;
        this.consignmentDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "broker",
            "description",
            "status",
            "view",
        ];
    }
    ngOnInit() {
        this.Dashboard._list.subscribe((list) => (this.consignmentDataSource.data = list["closed"]));
        this.consignmentDataSource.sort = this.sort;
        this.consignmentDataSource.paginator = this.paginator;
    }
    view(row) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = row.jobId;
        let dialog = this.dialog.open(ViewConsignmentNote, config);
        dialog.afterClosed().subscribe();
    }
    repeat(row) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = Object.assign(Object.assign({}, row), { isRepeat: true, ConsignmentApi: this.CarrierConsignmentApi });
        this.selectedRow = row;
        let dialogRef = this.dialog.open(EditConsignment, config);
        dialogRef
            .afterClosed()
            .subscribe((Consignment) => !!Consignment && this.save(Consignment));
    }
    save(Consignment) {
        this.Dashboard.saveConsignment(Consignment).then((Consignment) => this.listWaste(Consignment));
    }
    listWaste(Consignment) {
        return __awaiter(this, void 0, void 0, function* () {
            let config = new MatDialogConfig();
            this.loading = true;
            const response = yield this.WasteApi.list(this.selectedRow.consignmentId);
            this.loading = false;
            const waste = Object.assign(Object.assign({}, response[0]), { status: "repeat" });
            delete waste.consignmentId;
            waste.consignmentId = Consignment.consignmentId;
            if (!waste.components) {
                waste.components = null;
            }
            if (!waste.hazardCodes) {
                waste.hazardCodes = null;
            }
            config.data = waste;
            config.disableClose = true;
            let dialogRef = this.dialog.open(EditWaste, config);
            dialogRef.afterClosed().subscribe((Waste) => {
                let consignmentId = Consignment.consignmentId;
                this.WasteApi.save(consignmentId, Waste).then(() => this.dialogRef.close());
            });
        });
    }
    applyConsignmentFilter(event) {
        const searchValue = event.target.value;
        this.consignmentDataSource.filter = searchValue.trim().toLowerCase();
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], RepeatConsignment.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], RepeatConsignment.prototype, "paginator", void 0);
RepeatConsignment = __decorate([
    Component({
        selector: "repeat-consigment",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [Dashboard,
        MatDialog,
        MatDialogRef,
        CarrierWasteApi,
        CarrierConsignmentApi])
], RepeatConsignment);
export { RepeatConsignment };
