export const envParams = {
    cognito: {
        poolData: {
            UserPoolId: "eu-west-2_ZOfj5ShuH",
            ClientId: "4krneqkmg15i8ms140cnpeq95g",
        },
        region: "eu-west-2",
        identityPoolId: "eu-west-2:973e0788-3a8a-4ae6-aee3-d635e955938a",
    },
    driverUrl: "https://drivers.anywaste.com",
    endpoints: {
        admin: "https://zaxsgvclb1.execute-api.eu-west-2.amazonaws.com/production/",
        broker: "https://02u75y8cjl.execute-api.eu-west-2.amazonaws.com/production/",
        brokerConsignmentNotes: "https://cr8ghurqab.execute-api.eu-west-2.amazonaws.com/production/",
        brokerWasteTransferNotes: "https://v68v7bkyfg.execute-api.eu-west-2.amazonaws.com/production/",
        brokerDangerousGoodsNote: "https://1l93dqxfb3.execute-api.eu-west-2.amazonaws.com/production/",
        carrier: "https://su493oiype.execute-api.eu-west-2.amazonaws.com/production/",
        carrierConsignmentNotes: "https://ry2yojx12j.execute-api.eu-west-2.amazonaws.com/production/",
        carrierDangerousGoodsNote: "https://1jfgcqcxf6.execute-api.eu-west-2.amazonaws.com/production/",
        user: "https://8fu9z1nq2j.execute-api.eu-west-2.amazonaws.com/production/",
        carrierWasteTransferNotes: "https://k8fncrd959.execute-api.eu-west-2.amazonaws.com/production/"
    },
    companiesHouseApiKey: "680f6218-6a3b-4676-bb28-af7171cbceab",
    googleApiKey: "AIzaSyBxmL996Y4JyIGGqG00ty4oIHGGLgb4QYw",
};
