import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "../../../services/user-api";
import { PasswordResetFormFactory } from '../../../../../factories/password/reset';
let ResetPasswordModal = class ResetPasswordModal {
    constructor(UserService, dialogRef, data) {
        this.UserService = UserService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
    }
    ngOnInit() {
        this.form = PasswordResetFormFactory.form();
    }
    onSubmit() {
        let form = this.form.getRawValue();
        // start loading
        this.loading = true;
        this.error = null;
        this.UserService
            .resetPassword(form.code, form.newPassword.password)
            .then(confirmed => this.dialogRef.close(confirmed), error => this.handleError(error));
    }
    handleError(error) {
        // hide loading
        this.loading = false;
        // display error
        this.error = error;
    }
};
ResetPasswordModal = __decorate([
    Component({
        selector: 'user-password-new',
        template: require('./component.html'),
        styles: [require('./component.scss')]
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [UserService,
        MatDialogRef, Object])
], ResetPasswordModal);
export { ResetPasswordModal };
