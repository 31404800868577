// export default [
//   ...COUNTRIES_DB.filter(
//     (country) =>
//       country.name !== "United Kingdom of Great Britain and Northern Ireland"
//   ),
//   {
//     name: "England",
//     alpha2Code: "GB",
//     alpha3Code: "GBR",
//     numericCode: "826",
//     callingCode: "+44",
//   },
//   {
//     name: "Wales",
//     alpha2Code: "GB",
//     alpha3Code: "GBR",
//     numericCode: "826",
//     callingCode: "+44",
//   },
//   {
//     name: "Scotland",
//     alpha2Code: "GB",
//     alpha3Code: "GBR",
//     numericCode: "826",
//     callingCode: "+44",
//   },
//   {
//     name: "Northern Ireland",
//     alpha2Code: "GB",
//     alpha3Code: "GBR",
//     numericCode: "826",
//     callingCode: "+44",
//   },
// ].sort((a, b) => a.name.localeCompare(b.name));
export const predefinedCountries = [
    {
        name: "England",
        alpha2Code: "GB",
        alpha3Code: "GBR",
        numericCode: "826",
        callingCode: "+44",
    },
    {
        name: "Wales",
        alpha2Code: "GB",
        alpha3Code: "GBR",
        numericCode: "826",
        callingCode: "+44",
    },
    {
        name: "Scotland",
        alpha2Code: "GB",
        alpha3Code: "GBR",
        numericCode: "826",
        callingCode: "+44",
    },
    {
        name: "Northern Ireland",
        alpha2Code: "GB",
        alpha3Code: "GBR",
        numericCode: "826",
        callingCode: "+44",
    },
];
