export const sicCodes = [
    {
        code: 1110, description: "Growing of cereals (except rice), leguminous crops and oil seeds"
    },
    {
        code: 1120, description: "Growing of rice"
    },
    {
        code: 1130, description: "Growing of vegetables and melons, roots and tubers"
    },
    {
        code: 1140, description: "Growing of sugar cane"
    },
    {
        code: 1150, description: "Growing of tobacco"
    },
    {
        code: 1160, description: "Growing of fibre crops"
    },
    {
        code: 1190, description: "Growing of other non-perennial crops"
    },
    {
        code: 1210, description: "Growing of grapes"
    },
    {
        code: 1220, description: "Growing of tropical and subtropical fruits"
    },
    {
        code: 1230, description: "Growing of citrus fruits"
    },
    {
        code: 1240, description: "Growing of pome fruits and stone fruits"
    },
    {
        code: 1250, description: "Growing of other tree and bush fruits and nuts"
    },
    {
        code: 1260, description: "Growing of oleaginous fruits"
    },
    {
        code: 1270, description: "Growing of beverage crops"
    },
    {
        code: 1280, description: "Growing of spices, aromatic, drug and pharmaceutical crops"
    },
    {
        code: 1290, description: "Growing of other perennial crops"
    },
    {
        code: 1300, description: "Plant propagation"
    },
    {
        code: 1410, description: "Raising of dairy cattle"
    },
    {
        code: 1420, description: "Raising of other cattle and buffaloes"
    },
    {
        code: 1430, description: "Raising of horses and other equines"
    },
    {
        code: 1440, description: "Raising of camels and camelids"
    },
    {
        code: 1450, description: "Raising of sheep and  goats"
    },
    {
        code: 1460, description: "Raising of swine/pigs"
    },
    {
        code: 1470, description: "Raising of poultry"
    },
    {
        code: 1490, description: "Raising of other animals"
    },
    {
        code: 1500, description: "Mixed farming"
    },
    {
        code: 1610, description: "Support activities for crop production"
    },
    {
        code: 1621, description: "Farm animal boarding and care"
    },
    {
        code: 1629,
        description: "Support activities for animal production (other than farm animal boarding and care) n.e.c."
    },
    {
        code: 1630, description: "Post-harvest crop activities"
    },
    {
        code: 1640, description: "Seed processing for propagation"
    },
    {
        code: 1700, description: "Hunting, trapping and related service activities"
    },
    {
        code: 2100, description: "Silviculture and other forestry activities"
    },
    {
        code: 2200, description: "Logging"
    },
    {
        code: 2300, description: "Gathering of wild growing non-wood products"
    },
    {
        code: 2400, description: "Support services to forestry"
    },
    {
        code: 3110, description: "Marine fishing"
    },
    {
        code: 3120, description: "Freshwater fishing"
    },
    {
        code: 3210, description: "Marine aquaculture"
    },
    {
        code: 3220, description: "Freshwater aquaculture"
    },
    {
        code: 5101, description: "Deep coal mines"
    },
    {
        code: 5102, description: "Open cast coal working"
    },
    {
        code: 5200, description: "Mining of lignite"
    },
    {
        code: 6100, description: "Extraction of crude petroleum"
    },
    {
        code: 6200, description: "Extraction of natural gas"
    },
    {
        code: 7100, description: "Mining of iron ores"
    },
    {
        code: 7210, description: "Mining of uranium and thorium ores"
    },
    {
        code: 7290, description: "Mining of other non-ferrous metal ores"
    },
    {
        code: 8110, description: "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate"
    },
    {
        code: 8120, description: "Operation of gravel and sand pits; mining of clays and kaolin"
    },
    {
        code: 8910, description: "Mining of chemical and fertilizer minerals"
    },
    {
        code: 8920, description: "Extraction of peat"
    },
    {
        code: 8930, description: "Extraction of salt"
    },
    {
        code: 8990, description: "Other mining and quarrying n.e.c."
    },
    {
        code: 9100, description: "Support activities for petroleum and natural gas extraction"
    },
    {
        code: 9900, description: "Support activities for other mining and quarrying"
    },
    {
        code: 10110, description: "Processing and preserving of meat"
    },
    {
        code: 10120, description: "Processing and preserving of poultry meat"
    },
    {
        code: 10130, description: "Production of meat and poultry meat products"
    },
    {
        code: 10200, description: "Processing and preserving of fish, crustaceans and molluscs"
    },
    {
        code: 10310, description: "Processing and preserving of potatoes"
    },
    {
        code: 10320, description: "Manufacture of fruit and vegetable juice"
    },
    {
        code: 10390, description: "Other processing and preserving of fruit and vegetables"
    },
    {
        code: 10410, description: "Manufacture of oils and fats"
    },
    {
        code: 10420, description: "Manufacture of margarine and similar edible fats"
    },
    {
        code: 10511, description: "Liquid milk and cream production"
    },
    {
        code: 10512, description: "Butter and cheese production"
    },
    {
        code: 10519, description: "Manufacture of other milk products"
    },
    {
        code: 10520, description: "Manufacture of ice cream"
    },
    {
        code: 10611, description: "Grain milling"
    },
    {
        code: 10612, description: "Manufacture of breakfast cereals and cereals-based food"
    },
    {
        code: 10620, description: "Manufacture of starches and starch products"
    },
    {
        code: 10710, description: "Manufacture of bread; manufacture of fresh pastry goods and cakes"
    },
    {
        code: 10720,
        description: "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes"
    },
    {
        code: 10730, description: "Manufacture of macaroni, noodles, couscous and similar farinaceous products"
    },
    {
        code: 10810, description: "Manufacture of sugar"
    },
    {
        code: 10821, description: "Manufacture of cocoa and chocolate confectionery"
    },
    {
        code: 10822, description: "Manufacture of sugar confectionery"
    },
    {
        code: 10831, description: "Tea processing"
    },
    {
        code: 10832, description: "Production of coffee and coffee substitutes"
    },
    {
        code: 10840, description: "Manufacture of condiments and seasonings"
    },
    {
        code: 10850, description: "Manufacture of prepared meals and dishes"
    },
    {
        code: 10860, description: "Manufacture of homogenized food preparations and dietetic food"
    },
    {
        code: 10890, description: "Manufacture of other food products n.e.c."
    },
    {
        code: 10910, description: "Manufacture of prepared feeds for farm animals"
    },
    {
        code: 10920, description: "Manufacture of prepared pet foods"
    },
    {
        code: 11010, description: "Distilling, rectifying and blending of spirits"
    },
    {
        code: 11020, description: "Manufacture of wine from grape"
    },
    {
        code: 11030, description: "Manufacture of cider and other fruit wines"
    },
    {
        code: 11040, description: "Manufacture of other non-distilled fermented beverages"
    },
    {
        code: 11050, description: "Manufacture of beer"
    },
    {
        code: 11060, description: "Manufacture of malt"
    },
    {
        code: 11070,
        description: "Manufacture of soft drinks; production of mineral waters and other bottled waters"
    },
    {
        code: 12000, description: "Manufacture of tobacco products"
    },
    {
        code: 13100, description: "Preparation and spinning of textile fibres"
    },
    {
        code: 13200, description: "Weaving of textiles"
    },
    {
        code: 13300, description: "Finishing of textiles"
    },
    {
        code: 13910, description: "Manufacture of knitted and crocheted fabrics"
    },
    {
        code: 13921, description: "Manufacture of soft furnishings"
    },
    {
        code: 13922, description: "manufacture of canvas goods, sacks, etc."
    },
    {
        code: 13923, description: "manufacture of household textiles"
    },
    {
        code: 13931, description: "Manufacture of woven or tufted carpets and rugs"
    },
    {
        code: 13939, description: "Manufacture of other carpets and rugs"
    },
    {
        code: 13940, description: "Manufacture of cordage, rope, twine and netting"
    },
    {
        code: 13950, description: "Manufacture of non-wovens and articles made from non-wovens, except apparel"
    },
    {
        code: 13960, description: "Manufacture of other technical and industrial textiles"
    },
    {
        code: 13990, description: "Manufacture of other textiles n.e.c."
    },
    {
        code: 14110, description: "Manufacture of leather clothes"
    },
    {
        code: 14120, description: "Manufacture of workwear"
    },
    {
        code: 14131, description: "Manufacture of other men's outerwear"
    },
    {
        code: 14132, description: "Manufacture of other women's outerwear"
    },
    {
        code: 14141, description: "Manufacture of men's underwear"
    },
    {
        code: 14142, description: "Manufacture of women's underwear"
    },
    {
        code: 14190, description: "Manufacture of other wearing apparel and accessories n.e.c."
    },
    {
        code: 14200, description: "Manufacture of articles of fur"
    },
    {
        code: 14310, description: "Manufacture of knitted and crocheted hosiery"
    },
    {
        code: 14390, description: "Manufacture of other knitted and crocheted apparel"
    },
    {
        code: 15110, description: "Tanning and dressing of leather; dressing and dyeing of fur"
    },
    {
        code: 15120, description: "Manufacture of luggage, handbags and the like, saddlery and harness"
    },
    {
        code: 15200, description: "Manufacture of footwear"
    },
    {
        code: 16100, description: "Sawmilling and planing of wood"
    },
    {
        code: 16210, description: "Manufacture of veneer sheets and wood-based panels"
    },
    {
        code: 16220, description: "Manufacture of assembled parquet floors"
    },
    {
        code: 16230, description: "Manufacture of other builders' carpentry and joinery"
    },
    {
        code: 16240, description: "Manufacture of wooden containers"
    },
    {
        code: 16290,
        description: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials"
    },
    {
        code: 17110, description: "Manufacture of pulp"
    },
    {
        code: 17120, description: "Manufacture of paper and paperboard"
    },
    {
        code: 17211, description: "Manufacture of corrugated paper and paperboard, sacks and bags"
    },
    {
        code: 17219, description: "Manufacture of other paper and paperboard containers"
    },
    {
        code: 17220, description: "Manufacture of household and sanitary goods and of toilet requisites"
    },
    {
        code: 17230, description: "Manufacture of paper stationery"
    },
    {
        code: 17240, description: "Manufacture of wallpaper"
    },
    {
        code: 17290, description: "Manufacture of other articles of paper and paperboard n.e.c."
    },
    {
        code: 18110, description: "Printing of newspapers"
    },
    {
        code: 18121, description: "Manufacture of printed labels"
    },
    {
        code: 18129, description: "Printing n.e.c."
    },
    {
        code: 18130, description: "Pre-press and pre-media services"
    },
    {
        code: 18140, description: "Binding and related services"
    },
    {
        code: 18201, description: "Reproduction of sound recording"
    },
    {
        code: 18202, description: "Reproduction of video recording"
    },
    {
        code: 18203, description: "Reproduction of computer media"
    },
    {
        code: 19100, description: "Manufacture of coke oven products"
    },
    {
        code: 19201, description: "Mineral oil refining"
    },
    {
        code: 19209, description: "Other treatment of petroleum products (excluding petrochemicals manufacture)"
    },
    {
        code: 20110, description: "Manufacture of industrial gases"
    },
    {
        code: 20120, description: "Manufacture of dyes and pigments"
    },
    {
        code: 20130, description: "Manufacture of other inorganic basic chemicals"
    },
    {
        code: 20140, description: "Manufacture of other organic basic chemicals"
    },
    {
        code: 20150, description: "Manufacture of fertilizers and nitrogen compounds"
    },
    {
        code: 20160, description: "Manufacture of plastics in primary forms"
    },
    {
        code: 20170, description: "Manufacture of synthetic rubber in primary forms"
    },
    {
        code: 20200, description: "Manufacture of pesticides and other agrochemical products"
    },
    {
        code: 20301, description: "Manufacture of paints, varnishes and similar coatings, mastics and sealants"
    },
    {
        code: 20302, description: "Manufacture of printing ink"
    },
    {
        code: 20411, description: "Manufacture of soap and detergents"
    },
    {
        code: 20412, description: "Manufacture of cleaning and polishing preparations"
    },
    {
        code: 20420, description: "Manufacture of perfumes and toilet preparations"
    },
    {
        code: 20510, description: "Manufacture of explosives"
    },
    {
        code: 20520, description: "Manufacture of glues"
    },
    {
        code: 20530, description: "Manufacture of essential oils"
    },
    {
        code: 20590, description: "Manufacture of other chemical products n.e.c."
    },
    {
        code: 20600, description: "Manufacture of man-made fibres"
    },
    {
        code: 21100, description: "Manufacture of basic pharmaceutical products"
    },
    {
        code: 21200, description: "Manufacture of pharmaceutical preparations"
    },
    {
        code: 22110, description: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres"
    },
    {
        code: 22190, description: "Manufacture of other rubber products"
    },
    {
        code: 22210, description: "Manufacture of plastic plates, sheets, tubes and profiles"
    },
    {
        code: 22220, description: "Manufacture of plastic packing goods"
    },
    {
        code: 22230, description: "Manufacture of builders  ware of plastic"
    },
    {
        code: 22290, description: "Manufacture of other plastic products"
    },
    {
        code: 23110, description: "Manufacture of flat glass"
    },
    {
        code: 23120, description: "Shaping and processing of flat glass"
    },
    {
        code: 23130, description: "Manufacture of hollow glass"
    },
    {
        code: 23140, description: "Manufacture of glass fibres"
    },
    {
        code: 23190, description: "Manufacture and processing of other glass, including technical glassware"
    },
    {
        code: 23200, description: "Manufacture of refractory products"
    },
    {
        code: 23310, description: "Manufacture of ceramic tiles and flags"
    },
    {
        code: 23320, description: "Manufacture of bricks, tiles and construction products, in baked clay"
    },
    {
        code: 23410, description: "Manufacture of ceramic household and ornamental articles"
    },
    {
        code: 23420, description: "Manufacture of ceramic sanitary fixtures"
    },
    {
        code: 23430, description: "Manufacture of ceramic insulators and insulating fittings"
    },
    {
        code: 23440, description: "Manufacture of other technical ceramic products"
    },
    {
        code: 23490, description: "Manufacture of other ceramic products n.e.c."
    },
    {
        code: 23510, description: "Manufacture of cement"
    },
    {
        code: 23520, description: "Manufacture of lime and plaster"
    },
    {
        code: 23610, description: "Manufacture of concrete products for construction purposes"
    },
    {
        code: 23620, description: "Manufacture of plaster products for construction purposes"
    },
    {
        code: 23630, description: "Manufacture of ready-mixed concrete"
    },
    {
        code: 23640, description: "Manufacture of mortars"
    },
    {
        code: 23650, description: "Manufacture of fibre cement"
    },
    {
        code: 23690, description: "Manufacture of other articles of concrete, plaster and cement"
    },
    {
        code: 23700, description: "Cutting, shaping and finishing of stone"
    },
    {
        code: 23910, description: "Production of abrasive products"
    },
    {
        code: 23990, description: "Manufacture of other non-metallic mineral products n.e.c."
    },
    {
        code: 24100, description: "Manufacture of basic iron and steel and of ferro-alloys"
    },
    {
        code: 24200, description: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel"
    },
    {
        code: 24310, description: "Cold drawing of bars"
    },
    {
        code: 24320, description: "Cold rolling of narrow strip"
    },
    {
        code: 24330, description: "Cold forming or folding"
    },
    {
        code: 24340, description: "Cold drawing of wire"
    },
    {
        code: 24410, description: "Precious metals production"
    },
    {
        code: 24420, description: "Aluminium production"
    },
    {
        code: 24430, description: "Lead, zinc and tin production"
    },
    {
        code: 24440, description: "Copper production"
    },
    {
        code: 24450, description: "Other non-ferrous metal production"
    },
    {
        code: 24460, description: "Processing of nuclear fuel"
    },
    {
        code: 24510, description: "Casting of iron"
    },
    {
        code: 24520, description: "Casting of steel"
    },
    {
        code: 24530, description: "Casting of light metals"
    },
    {
        code: 24540, description: "Casting of other non-ferrous metals"
    },
    {
        code: 25110, description: "Manufacture of metal structures and parts of structures"
    },
    {
        code: 25120, description: "Manufacture of doors and windows of metal"
    },
    {
        code: 25210, description: "Manufacture of central heating radiators and boilers"
    },
    {
        code: 25290, description: "Manufacture of other tanks, reservoirs and containers of metal"
    },
    {
        code: 25300, description: "Manufacture of steam generators, except central heating hot water boilers"
    },
    {
        code: 25400, description: "Manufacture of weapons and ammunition"
    },
    {
        code: 25500, description: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy"
    },
    {
        code: 25610, description: "Treatment and coating of metals"
    },
    {
        code: 25620, description: "Machining"
    },
    {
        code: 25710, description: "Manufacture of cutlery"
    },
    {
        code: 25720, description: "Manufacture of locks and hinges"
    },
    {
        code: 25730, description: "Manufacture of tools"
    },
    {
        code: 25910, description: "Manufacture of steel drums and similar containers"
    },
    {
        code: 25920, description: "Manufacture of light metal packaging"
    },
    {
        code: 25930, description: "Manufacture of wire products, chain and springs"
    },
    {
        code: 25940, description: "Manufacture of fasteners and screw machine products"
    },
    {
        code: 25990, description: "Manufacture of other fabricated metal products n.e.c."
    },
    {
        code: 26110, description: "Manufacture of electronic components"
    },
    {
        code: 26120, description: "Manufacture of loaded electronic boards"
    },
    {
        code: 26200, description: "Manufacture of computers and peripheral equipment"
    },
    {
        code: 26301, description: "Manufacture of telegraph and telephone apparatus and equipment"
    },
    {
        code: 26309,
        description: "Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment"
    },
    {
        code: 26400, description: "Manufacture of consumer electronics"
    },
    {
        code: 26511,
        description: "Manufacture of electronic measuring, testing etc. equipment, not for industrial process control"
    },
    {
        code: 26512, description: "Manufacture of electronic industrial process control equipment"
    },
    {
        code: 26513,
        description: "Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control"
    },
    {
        code: 26514, description: "Manufacture of non-electronic industrial process control equipment"
    },
    {
        code: 26520, description: "Manufacture of watches and clocks"
    },
    {
        code: 26600, description: "Manufacture of irradiation, electromedical and electrotherapeutic equipment"
    },
    {
        code: 26701, description: "Manufacture of optical precision instruments"
    },
    {
        code: 26702, description: "Manufacture of photographic and cinematographic equipment"
    },
    {
        code: 26800, description: "Manufacture of magnetic and optical media"
    },
    {
        code: 27110, description: "Manufacture of electric motors, generators and transformers"
    },
    {
        code: 27120, description: "Manufacture of electricity distribution and control apparatus"
    },
    {
        code: 27200, description: "Manufacture of batteries and accumulators"
    },
    {
        code: 27310, description: "Manufacture of fibre optic cables"
    },
    {
        code: 27320, description: "Manufacture of other electronic and electric wires and cables"
    },
    {
        code: 27330, description: "Manufacture of wiring devices"
    },
    {
        code: 27400, description: "Manufacture of electric lighting equipment"
    },
    {
        code: 27510, description: "Manufacture of electric domestic appliances"
    },
    {
        code: 27520, description: "Manufacture of non-electric domestic appliances"
    },
    {
        code: 27900, description: "Manufacture of other electrical equipment"
    },
    {
        code: 28110, description: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines"
    },
    {
        code: 28120, description: "Manufacture of fluid power equipment"
    },
    {
        code: 28131, description: "Manufacture of pumps"
    },
    {
        code: 28132, description: "Manufacture of compressors"
    },
    {
        code: 28140, description: "Manufacture of taps and valves"
    },
    {
        code: 28150, description: "Manufacture of bearings, gears, gearing and driving elements"
    },
    {
        code: 28210, description: "Manufacture of ovens, furnaces and furnace burners"
    },
    {
        code: 28220, description: "Manufacture of lifting and handling equipment"
    },
    {
        code: 28230,
        description: "Manufacture of office machinery and equipment (except computers and peripheral equipment)"
    },
    {
        code: 28240, description: "Manufacture of power-driven hand tools"
    },
    {
        code: 28250, description: "Manufacture of non-domestic cooling and ventilation equipment"
    },
    {
        code: 28290, description: "Manufacture of other general-purpose machinery n.e.c."
    },
    {
        code: 28301, description: "Manufacture of agricultural tractors"
    },
    {
        code: 28302, description: "Manufacture of agricultural and forestry machinery other than tractors"
    },
    {
        code: 28410, description: "Manufacture of metal forming machinery"
    },
    {
        code: 28490, description: "Manufacture of other machine tools"
    },
    {
        code: 28910, description: "Manufacture of machinery for metallurgy"
    },
    {
        code: 28921, description: "Manufacture of machinery for mining"
    },
    {
        code: 28922, description: "Manufacture of earthmoving equipment"
    },
    {
        code: 28923, description: "Manufacture of equipment for concrete crushing and screening and roadworks"
    },
    {
        code: 28930, description: "Manufacture of machinery for food, beverage and tobacco processing"
    },
    {
        code: 28940, description: "Manufacture of machinery for textile, apparel and leather production"
    },
    {
        code: 28950, description: "Manufacture of machinery for paper and paperboard production"
    },
    {
        code: 28960, description: "Manufacture of plastics and rubber machinery"
    },
    {
        code: 28990, description: "Manufacture of other special-purpose machinery n.e.c."
    },
    {
        code: 29100, description: "Manufacture of motor vehicles"
    },
    {
        code: 29201, description: "Manufacture of bodies (coachwork) for motor vehicles (except caravans)"
    },
    {
        code: 29202, description: "Manufacture of trailers and semi-trailers"
    },
    {
        code: 29203, description: "Manufacture of caravans"
    },
    {
        code: 29310,
        description: "Manufacture of electrical and electronic equipment for motor vehicles and their engines"
    },
    {
        code: 29320, description: "Manufacture of other parts and accessories for motor vehicles"
    },
    {
        code: 30110, description: "Building of ships and floating structures"
    },
    {
        code: 30120, description: "Building of pleasure and sporting boats"
    },
    {
        code: 30200, description: "Manufacture of railway locomotives and rolling stock"
    },
    {
        code: 30300, description: "Manufacture of air and spacecraft and related machinery"
    },
    {
        code: 30400, description: "Manufacture of military fighting vehicles"
    },
    {
        code: 30910, description: "Manufacture of motorcycles"
    },
    {
        code: 30920, description: "Manufacture of bicycles and invalid carriages"
    },
    {
        code: 30990, description: "Manufacture of other carrier equipment n.e.c."
    },
    {
        code: 31010, description: "Manufacture of office and shop furniture"
    },
    {
        code: 31020, description: "Manufacture of kitchen furniture"
    },
    {
        code: 31030, description: "Manufacture of mattresses"
    },
    {
        code: 31090, description: "Manufacture of other furniture"
    },
    {
        code: 32110, description: "Striking of coins"
    },
    {
        code: 32120, description: "Manufacture of jewellery and related articles"
    },
    {
        code: 32130, description: "Manufacture of imitation jewellery and related articles"
    },
    {
        code: 32200, description: "Manufacture of musical instruments"
    },
    {
        code: 32300, description: "Manufacture of sports goods"
    },
    {
        code: 32401, description: "Manufacture of professional and arcade games and toys"
    },
    {
        code: 32409, description: "Manufacture of other games and toys, n.e.c."
    },
    {
        code: 32500, description: "Manufacture of medical and dental instruments and supplies"
    },
    {
        code: 32910, description: "Manufacture of brooms and brushes"
    },
    {
        code: 32990, description: "Other manufacturing n.e.c."
    },
    {
        code: 33110, description: "Repair of fabricated metal products"
    },
    {
        code: 33120, description: "Repair of machinery"
    },
    {
        code: 33130, description: "Repair of electronic and optical equipment"
    },
    {
        code: 33140, description: "Repair of electrical equipment"
    },
    {
        code: 33150, description: "Repair and maintenance of ships and boats"
    },
    {
        code: 33160, description: "Repair and maintenance of aircraft and spacecraft"
    },
    {
        code: 33170, description: "Repair and maintenance of other carrier equipment n.e.c."
    },
    {
        code: 33190, description: "Repair of other equipment"
    },
    {
        code: 33200, description: "Installation of industrial machinery and equipment"
    },
    {
        code: 35110, description: "Production of electricity"
    },
    {
        code: 35120, description: "Transmission of electricity"
    },
    {
        code: 35130, description: "Distribution of electricity"
    },
    {
        code: 35140, description: "Trade of electricity"
    },
    {
        code: 35210, description: "Manufacture of gas"
    },
    {
        code: 35220, description: "Distribution of gaseous fuels through mains"
    },
    {
        code: 35230, description: "Trade of gas through mains"
    },
    {
        code: 35300, description: "Steam and air conditioning supply"
    },
    {
        code: 36000, description: "Water collection, treatment and supply"
    },
    {
        code: 37000, description: "Sewerage"
    },
    {
        code: 38110, description: "Collection of non-hazardous waste"
    },
    {
        code: 38120, description: "Collection of hazardous waste"
    },
    {
        code: 38210, description: "Treatment and disposal of non-hazardous waste"
    },
    {
        code: 38220, description: "Treatment and disposal of hazardous waste"
    },
    {
        code: 38310, description: "Dismantling of wrecks"
    },
    {
        code: 38320, description: "Recovery of sorted materials"
    },
    {
        code: 39000, description: "Remediation activities and other waste management services"
    },
    {
        code: 41100, description: "Development of building projects"
    },
    {
        code: 41201, description: "Construction of commercial buildings"
    },
    {
        code: 41202, description: "Construction of domestic buildings"
    },
    {
        code: 42110, description: "Construction of roads and motorways"
    },
    {
        code: 42120, description: "Construction of railways and underground railways"
    },
    {
        code: 42130, description: "Construction of bridges and tunnels"
    },
    {
        code: 42210, description: "Construction of utility projects for fluids"
    },
    {
        code: 42220, description: "Construction of utility projects for electricity and telecommunications"
    },
    {
        code: 42910, description: "Construction of water projects"
    },
    {
        code: 42990, description: "Construction of other civil engineering projects n.e.c."
    },
    {
        code: 43110, description: "Demolition"
    },
    {
        code: 43120, description: "Site preparation"
    },
    {
        code: 43130, description: "Test drilling and boring"
    },
    {
        code: 43210, description: "Electrical installation"
    },
    {
        code: 43220, description: "Plumbing, heat and air-conditioning installation"
    },
    {
        code: 43290, description: "Other construction installation"
    },
    {
        code: 43310, description: "Plastering"
    },
    {
        code: 43320, description: "Joinery installation"
    },
    {
        code: 43330, description: "Floor and wall covering"
    },
    {
        code: 43341, description: "Painting"
    },
    {
        code: 43342, description: "Glazing"
    },
    {
        code: 43390, description: "Other building completion and finishing"
    },
    {
        code: 43910, description: "Roofing activities"
    },
    {
        code: 43991, description: "Scaffold erection"
    },
    {
        code: 43999, description: "Other specialised construction activities n.e.c."
    },
    {
        code: 45111, description: "Sale of new cars and light motor vehicles"
    },
    {
        code: 45112, description: "Sale of used cars and light motor vehicles"
    },
    {
        code: 45190, description: "Sale of other motor vehicles"
    },
    {
        code: 45200, description: "Maintenance and repair of motor vehicles"
    },
    {
        code: 45310, description: "Wholesale trade of motor vehicle parts and accessories"
    },
    {
        code: 45320, description: "Retail trade of motor vehicle parts and accessories"
    },
    {
        code: 45400, description: "Sale, maintenance and repair of motorcycles and related parts and accessories"
    },
    {
        code: 46110,
        description: "Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods"
    },
    {
        code: 46120, description: "Agents involved in the sale of fuels, ores, metals and industrial chemicals"
    },
    {
        code: 46130, description: "Agents involved in the sale of timber and building materials"
    },
    {
        code: 46140,
        description: "Agents involved in the sale of machinery, industrial equipment, ships and aircraft"
    },
    {
        code: 46150,
        description: "Agents involved in the sale of furniture, household goods, hardware and ironmongery"
    },
    {
        code: 46160,
        description: "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods"
    },
    {
        code: 46170, description: "Agents involved in the sale of food, beverages and tobacco"
    },
    {
        code: 46180, description: "Agents specialized in the sale of other particular products"
    },
    {
        code: 46190, description: "Agents involved in the sale of a variety of goods"
    },
    {
        code: 46210, description: "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds"
    },
    {
        code: 46220, description: "Wholesale of flowers and plants"
    },
    {
        code: 46230, description: "Wholesale of live animals"
    },
    {
        code: 46240, description: "Wholesale of hides, skins and leather"
    },
    {
        code: 46310, description: "Wholesale of fruit and vegetables"
    },
    {
        code: 46320, description: "Wholesale of meat and meat products"
    },
    {
        code: 46330, description: "Wholesale of dairy products, eggs and edible oils and fats"
    },
    {
        code: 46341, description: "Wholesale of fruit and vegetable juices, mineral water and soft drinks"
    },
    {
        code: 46342, description: "Wholesale of wine, beer, spirits and other alcoholic beverages"
    },
    {
        code: 46350, description: "Wholesale of tobacco products"
    },
    {
        code: 46360, description: "Wholesale of sugar and chocolate and sugar confectionery"
    },
    {
        code: 46370, description: "Wholesale of coffee, tea, cocoa and spices"
    },
    {
        code: 46380, description: "Wholesale of other food, including fish, crustaceans and molluscs"
    },
    {
        code: 46390, description: "Non-specialised wholesale of food, beverages and tobacco"
    },
    {
        code: 46410, description: "Wholesale of textiles"
    },
    {
        code: 46420, description: "Wholesale of clothing and footwear"
    },
    {
        code: 46431,
        description: "Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played"
    },
    {
        code: 46439,
        description: "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)"
    },
    {
        code: 46440, description: "Wholesale of china and glassware and cleaning materials"
    },
    {
        code: 46450, description: "Wholesale of perfume and cosmetics"
    },
    {
        code: 46460, description: "Wholesale of pharmaceutical goods"
    },
    {
        code: 46470, description: "Wholesale of furniture, carpets and lighting equipment"
    },
    {
        code: 46480, description: "Wholesale of watches and jewellery"
    },
    {
        code: 46491, description: "Wholesale of musical instruments"
    },
    {
        code: 46499, description: "Wholesale of household goods (other than musical instruments) n.e.c."
    },
    {
        code: 46510, description: "Wholesale of computers, computer peripheral equipment and software"
    },
    {
        code: 46520, description: "Wholesale of electronic and telecommunications equipment and parts"
    },
    {
        code: 46610, description: "Wholesale of agricultural machinery, equipment and supplies"
    },
    {
        code: 46620, description: "Wholesale of machine tools"
    },
    {
        code: 46630, description: "Wholesale of mining, construction and civil engineering machinery"
    },
    {
        code: 46640,
        description: "Wholesale of machinery for the textile industry and of sewing and knitting machines"
    },
    {
        code: 46650, description: "Wholesale of office furniture"
    },
    {
        code: 46660, description: "Wholesale of other office machinery and equipment"
    },
    {
        code: 46690, description: "Wholesale of other machinery and equipment"
    },
    {
        code: 46711, description: "Wholesale of petroleum and petroleum products"
    },
    {
        code: 46719, description: "Wholesale of other fuels and related products"
    },
    {
        code: 46720, description: "Wholesale of metals and metal ores"
    },
    {
        code: 46730, description: "Wholesale of wood, construction materials and sanitary equipment"
    },
    {
        code: 46740, description: "Wholesale of hardware, plumbing and heating equipment and supplies"
    },
    {
        code: 46750, description: "Wholesale of chemical products"
    },
    {
        code: 46760, description: "Wholesale of other intermediate products"
    },
    {
        code: 46770, description: "Wholesale of waste and scrap"
    },
    {
        code: 46900, description: "Non-specialised wholesale trade"
    },
    {
        code: 47110,
        description: "Retail sale in non-specialised stores with food, beverages or tobacco predominating"
    },
    {
        code: 47190, description: "Other retail sale in non-specialised stores"
    },
    {
        code: 47210, description: "Retail sale of fruit and vegetables in specialised stores"
    },
    {
        code: 47220, description: "Retail sale of meat and meat products in specialised stores"
    },
    {
        code: 47230, description: "Retail sale of fish, crustaceans and molluscs in specialised stores"
    },
    {
        code: 47240,
        description: "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores"
    },
    {
        code: 47250, description: "Retail sale of beverages in specialised stores"
    },
    {
        code: 47260, description: "Retail sale of tobacco products in specialised stores"
    },
    {
        code: 47290, description: "Other retail sale of food in specialised stores"
    },
    {
        code: 47300, description: "Retail sale of automotive fuel in specialised stores"
    },
    {
        code: 47410, description: "Retail sale of computers, peripheral units and software in specialised stores"
    },
    {
        code: 47421, description: "Retail sale of mobile telephones"
    },
    {
        code: 47429, description: "Retail sale of telecommunications equipment other than mobile telephones"
    },
    {
        code: 47430, description: "Retail sale of audio and video equipment in specialised stores"
    },
    {
        code: 47510, description: "Retail sale of textiles in specialised stores"
    },
    {
        code: 47520, description: "Retail sale of hardware, paints and glass in specialised stores"
    },
    {
        code: 47530, description: "Retail sale of carpets, rugs, wall and floor coverings in specialised stores"
    },
    {
        code: 47540, description: "Retail sale of electrical household appliances in specialised stores"
    },
    {
        code: 47591, description: "Retail sale of musical instruments and scores"
    },
    {
        code: 47599,
        description: "Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store"
    },
    {
        code: 47610, description: "Retail sale of books in specialised stores"
    },
    {
        code: 47620, description: "Retail sale of newspapers and stationery in specialised stores"
    },
    {
        code: 47630, description: "Retail sale of music and video recordings in specialised stores"
    },
    {
        code: 47640, description: "Retail sale of sports goods, fishing gear, camping goods, boats and bicycles"
    },
    {
        code: 47650, description: "Retail sale of games and toys in specialised stores"
    },
    {
        code: 47710, description: "Retail sale of clothing in specialised stores"
    },
    {
        code: 47721, description: "Retail sale of footwear in specialised stores"
    },
    {
        code: 47722, description: "Retail sale of leather goods in specialised stores"
    },
    {
        code: 47730, description: "Dispensing chemist in specialised stores"
    },
    {
        code: 47741, description: "Retail sale of hearing aids"
    },
    {
        code: 47749,
        description: "Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c."
    },
    {
        code: 47750, description: "Retail sale of cosmetic and toilet articles in specialised stores"
    },
    {
        code: 47760,
        description: "Retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores"
    },
    {
        code: 47770, description: "Retail sale of watches and jewellery in specialised stores"
    },
    {
        code: 47781, description: "Retail sale in commercial art galleries"
    },
    {
        code: 47782, description: "Retail sale by opticians"
    },
    {
        code: 47789,
        description: "Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)"
    },
    {
        code: 47791, description: "Retail sale of antiques including antique books in stores"
    },
    {
        code: 47799, description: "Retail sale of other second-hand goods in stores (not incl. antiques)"
    },
    {
        code: 47810, description: "Retail sale via stalls and markets of food, beverages and tobacco products"
    },
    {
        code: 47820, description: "Retail sale via stalls and markets of textiles, clothing and footwear"
    },
    {
        code: 47890, description: "Retail sale via stalls and markets of other goods"
    },
    {
        code: 47910, description: "Retail sale via mail order houses or via Internet"
    },
    {
        code: 47990, description: "Other retail sale not in stores, stalls or markets"
    },
    {
        code: 49100, description: "Passenger rail carrier, interurban"
    },
    {
        code: 49200, description: "Freight rail carrier"
    },
    {
        code: 49311,
        description: "Urban and suburban passenger railway transportation by underground, metro and similar systems"
    },
    {
        code: 49319,
        description: "Other urban, suburban or metropolitan passenger land carrier (not underground, metro or similar)"
    },
    {
        code: 49320, description: "Taxi operation"
    },
    {
        code: 49390, description: "Other passenger land carrier"
    },
    {
        code: 49410, description: "Freight carrier by road"
    },
    {
        code: 49420, description: "Removal services"
    },
    {
        code: 49500, description: "Transport via pipeline"
    },
    {
        code: 50100, description: "Sea and coastal passenger water carrier"
    },
    {
        code: 50200, description: "Sea and coastal freight water carrier"
    },
    {
        code: 50300, description: "Inland passenger water carrier"
    },
    {
        code: 50400, description: "Inland freight water carrier"
    },
    {
        code: 51101, description: "Scheduled passenger air carrier"
    },
    {
        code: 51102, description: "Non-scheduled passenger air carrier"
    },
    {
        code: 51210, description: "Freight air carrier"
    },
    {
        code: 51220, description: "Space carrier"
    },
    {
        code: 52101, description: "Operation of warehousing and storage facilities for water carrier activities"
    },
    {
        code: 52102, description: "Operation of warehousing and storage facilities for air carrier activities"
    },
    {
        code: 52103, description: "Operation of warehousing and storage facilities for land carrier activities"
    },
    {
        code: 52211, description: "Operation of rail freight terminals"
    },
    {
        code: 52212, description: "Operation of rail passenger facilities at railway stations"
    },
    {
        code: 52213, description: "Operation of bus and coach passenger facilities at bus and coach stations"
    },
    {
        code: 52219, description: "Other service activities incidental to land transportation, n.e.c."
    },
    {
        code: 52220, description: "Service activities incidental to water transportation"
    },
    {
        code: 52230, description: "Service activities incidental to air transportation"
    },
    {
        code: 52241, description: "Cargo handling for water carrier activities"
    },
    {
        code: 52242, description: "Cargo handling for air carrier activities"
    },
    {
        code: 52243, description: "Cargo handling for land carrier activities"
    },
    {
        code: 52290, description: "Other transportation support activities"
    },
    {
        code: 53100, description: "Postal activities under universal service obligation"
    },
    {
        code: 53201, description: "Licensed carriers"
    },
    {
        code: 53202, description: "Unlicensed carrier"
    },
    {
        code: 55100, description: "Hotels and similar accommodation"
    },
    {
        code: 55201, description: "Holiday centres and villages"
    },
    {
        code: 55202, description: "Youth hostels"
    },
    {
        code: 55209, description: "Other holiday and other collective accommodation"
    },
    {
        code: 55300, description: "Recreational vehicle parks, trailer parks and camping grounds"
    },
    {
        code: 55900, description: "Other accommodation"
    },
    {
        code: 56101, description: "Licensed restaurants"
    },
    {
        code: 56102, description: "Unlicensed restaurants and cafes"
    },
    {
        code: 56103, description: "Take-away food shops and mobile food stands"
    },
    {
        code: 56210, description: "Event catering activities"
    },
    {
        code: 56290, description: "Other food services"
    },
    {
        code: 56301, description: "Licensed clubs"
    },
    {
        code: 56302, description: "Public houses and bars"
    },
    {
        code: 58110, description: "Book publishing"
    },
    {
        code: 58120, description: "Publishing of directories and mailing lists"
    },
    {
        code: 58130, description: "Publishing of newspapers"
    },
    {
        code: 58141, description: "Publishing of learned journals"
    },
    {
        code: 58142, description: "Publishing of  consumer and business journals and periodicals"
    },
    {
        code: 58190, description: "Other publishing activities"
    },
    {
        code: 58210, description: "Publishing of computer games"
    },
    {
        code: 58290, description: "Other software publishing"
    },
    {
        code: 59111, description: "Motion picture production activities"
    },
    {
        code: 59112, description: "Video production activities"
    },
    {
        code: 59113, description: "Television programme production activities"
    },
    {
        code: 59120, description: "Motion picture, video and television programme post-production activities"
    },
    {
        code: 59131, description: "Motion picture distribution activities"
    },
    {
        code: 59132, description: "Video distribution activities"
    },
    {
        code: 59133, description: "Television programme distribution activities"
    },
    {
        code: 59140, description: "Motion picture projection activities"
    },
    {
        code: 59200, description: "Sound recording and music publishing activities"
    },
    {
        code: 60100, description: "Radio broadcasting"
    },
    {
        code: 60200, description: "Television programming and broadcasting activities"
    },
    {
        code: 61100, description: "Wired telecommunications activities"
    },
    {
        code: 61200, description: "Wireless telecommunications activities"
    },
    {
        code: 61300, description: "Satellite telecommunications activities"
    },
    {
        code: 61900, description: "Other telecommunications activities"
    },
    {
        code: 62011, description: "Ready-made interactive leisure and entertainment software development"
    },
    {
        code: 62012, description: "Business and domestic software development"
    },
    {
        code: 62020, description: "Information technology consultancy activities"
    },
    {
        code: 62030, description: "Computer facilities management activities"
    },
    {
        code: 62090, description: "Other information technology service activities"
    },
    {
        code: 63110, description: "Data processing, hosting and related activities"
    },
    {
        code: 63120, description: "Web portals"
    },
    {
        code: 63910, description: "News agency activities"
    },
    {
        code: 63990, description: "Other information service activities n.e.c."
    },
    {
        code: 64110, description: "Central banking"
    },
    {
        code: 64191, description: "Banks"
    },
    {
        code: 64192, description: "Building societies"
    },
    {
        code: 64201, description: "Activities of agricultural holding companies"
    },
    {
        code: 64202, description: "Activities of production holding companies"
    },
    {
        code: 64203, description: "Activities of construction holding companies"
    },
    {
        code: 64204, description: "Activities of distribution holding companies"
    },
    {
        code: 64205, description: "Activities of financial services holding companies"
    },
    {
        code: 64209, description: "Activities of other holding companies n.e.c."
    },
    {
        code: 64301, description: "Activities of investment trusts"
    },
    {
        code: 64302, description: "Activities of unit trusts"
    },
    {
        code: 64303, description: "Activities of venture and development capital companies"
    },
    {
        code: 64304, description: "Activities of open-ended investment companies"
    },
    {
        code: 64305, description: "Activities of property unit trusts"
    },
    {
        code: 64306, description: "Activities of real estate investment trusts"
    },
    {
        code: 64910, description: "Financial leasing"
    },
    {
        code: 64921,
        description: "Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors"
    },
    {
        code: 64922, description: "Activities of mortgage finance companies"
    },
    {
        code: 64929, description: "Other credit granting n.e.c."
    },
    {
        code: 64991, description: "Security dealing on own account"
    },
    {
        code: 64992, description: "Factoring"
    },
    {
        code: 64999, description: "Financial intermediation not elsewhere classified"
    },
    {
        code: 65110, description: "Life insurance"
    },
    {
        code: 65120, description: "Non-life insurance"
    },
    {
        code: 65201, description: "Life reinsurance"
    },
    {
        code: 65202, description: "Non-life reinsurance"
    },
    {
        code: 65300, description: "Pension funding"
    },
    {
        code: 66110, description: "Administration of financial markets"
    },
    {
        code: 66120, description: "Security and commodity contracts dealing activities"
    },
    {
        code: 66190, description: "Activities auxiliary to financial intermediation n.e.c."
    },
    {
        code: 66210, description: "Risk and damage evaluation"
    },
    {
        code: 66220, description: "Activities of insurance agents and brokers"
    },
    {
        code: 66290, description: "Other activities auxiliary to insurance and pension funding"
    },
    {
        code: 66300, description: "Fund management activities"
    },
    {
        code: 68100, description: "Buying and selling of own real estate"
    },
    {
        code: 68201, description: "Renting and operating of Housing Association real estate"
    },
    {
        code: 68202, description: "Letting and operating of conference and exhibition centres"
    },
    {
        code: 68209, description: "Other letting and operating of own or leased real estate"
    },
    {
        code: 68310, description: "Real estate agencies"
    },
    {
        code: 68320, description: "Management of real estate on a fee or contract basis"
    },
    {
        code: 69101, description: "Barristers at law"
    },
    {
        code: 69102, description: "Solicitors"
    },
    {
        code: 69109, description: "Activities of patent and copyright agents; other legal activities n.e.c."
    },
    {
        code: 69201, description: "Accounting and auditing activities"
    },
    {
        code: 69202, description: "Bookkeeping activities"
    },
    {
        code: 69203, description: "Tax consultancy"
    },
    {
        code: 70100, description: "Activities of head offices"
    },
    {
        code: 70210, description: "Public relations and communications activities"
    },
    {
        code: 70221, description: "Financial management"
    },
    {
        code: 70229, description: "Management consultancy activities other than financial management"
    },
    {
        code: 71111, description: "Architectural activities"
    },
    {
        code: 71112, description: "Urban planning and landscape architectural activities"
    },
    {
        code: 71121, description: "Engineering design activities for industrial process and production"
    },
    {
        code: 71122, description: "Engineering related scientific and technical consulting activities"
    },
    {
        code: 71129, description: "Other engineering activities"
    },
    {
        code: 71200, description: "Technical testing and analysis"
    },
    {
        code: 72110, description: "Research and experimental development on biotechnology"
    },
    {
        code: 72190, description: "Other research and experimental development on natural sciences and engineering"
    },
    {
        code: 72200, description: "Research and experimental development on social sciences and humanities"
    },
    {
        code: 73110, description: "Advertising agencies"
    },
    {
        code: 73120, description: "Media representation services"
    },
    {
        code: 73200, description: "Market research and public opinion polling"
    },
    {
        code: 74100, description: "specialised design activities"
    },
    {
        code: 74201, description: "Portrait photographic activities"
    },
    {
        code: 74202, description: "Other specialist photography"
    },
    {
        code: 74203, description: "Film processing"
    },
    {
        code: 74209, description: "Photographic activities not elsewhere classified"
    },
    {
        code: 74300, description: "Translation and interpretation activities"
    },
    {
        code: 74901, description: "Environmental consulting activities"
    },
    {
        code: 74902, description: "Quantity surveying activities"
    },
    {
        code: 74909, description: "Other professional, scientific and technical activities n.e.c."
    },
    {
        code: 74990, description: "Non-trading company"
    },
    {
        code: 75000, description: "Veterinary activities"
    },
    {
        code: 77110, description: "Renting and leasing of cars and light motor vehicles"
    },
    {
        code: 77120, description: "Renting and leasing of trucks and other heavy vehicles"
    },
    {
        code: 77210, description: "Renting and leasing of recreational and sports goods"
    },
    {
        code: 77220, description: "Renting of video tapes and disks"
    },
    {
        code: 77291, description: "Renting and leasing of media entertainment equipment"
    },
    {
        code: 77299, description: "Renting and leasing of other personal and household goods"
    },
    {
        code: 77310, description: "Renting and leasing of agricultural machinery and equipment"
    },
    {
        code: 77320,
        description: "Renting and leasing of construction and civil engineering machinery and equipment"
    },
    {
        code: 77330, description: "Renting and leasing of office machinery and equipment (including computers)"
    },
    {
        code: 77341, description: "Renting and leasing of passenger water carrier equipment"
    },
    {
        code: 77342, description: "Renting and leasing of freight water carrier equipment"
    },
    {
        code: 77351, description: "Renting and leasing of air passenger carrier equipment"
    },
    {
        code: 77352, description: "Renting and leasing of freight air carrier equipment"
    },
    {
        code: 77390, description: "Renting and leasing of other machinery, equipment and tangible goods n.e.c."
    },
    {
        code: 77400, description: "Leasing of intellectual property and similar products, except copyright works"
    },
    {
        code: 78101, description: "Motion picture, television and other theatrical casting activities"
    },
    {
        code: 78109, description: "Other activities of employment placement agencies"
    },
    {
        code: 78200, description: "Temporary employment agency activities"
    },
    {
        code: 78300, description: "Human resources provision and management of human resources functions"
    },
    {
        code: 79110, description: "Travel agency activities"
    },
    {
        code: 79120, description: "Tour operator activities"
    },
    {
        code: 79901, description: "Activities of tourist guides"
    },
    {
        code: 79909, description: "Other reservation service activities n.e.c."
    },
    {
        code: 80100, description: "Private security activities"
    },
    {
        code: 80200, description: "Security systems service activities"
    },
    {
        code: 80300, description: "Investigation activities"
    },
    {
        code: 81100, description: "Combined facilities support activities"
    },
    {
        code: 81210, description: "General cleaning of buildings"
    },
    {
        code: 81221, description: "Window cleaning services"
    },
    {
        code: 81222, description: "Specialised cleaning services"
    },
    {
        code: 81223, description: "Furnace and chimney cleaning services"
    },
    {
        code: 81229, description: "Other building and industrial cleaning activities"
    },
    {
        code: 81291, description: "Disinfecting and exterminating services"
    },
    {
        code: 81299, description: "Other cleaning services"
    },
    {
        code: 81300, description: "Landscape service activities"
    },
    {
        code: 82110, description: "Combined office administrative service activities"
    },
    {
        code: 82190,
        description: "Photocopying, document preparation and other specialised office support activities"
    },
    {
        code: 82200, description: "Activities of call centres"
    },
    {
        code: 82301, description: "Activities of exhibition and fair organisers"
    },
    {
        code: 82302, description: "Activities of conference organisers"
    },
    {
        code: 82911, description: "Activities of collection agencies"
    },
    {
        code: 82912, description: "Activities of credit bureaus"
    },
    {
        code: 82920, description: "Packaging activities"
    },
    {
        code: 82990, description: "Other business support service activities n.e.c."
    },
    {
        code: 84110, description: "General public administration activities"
    },
    {
        code: 84120,
        description: "Regulation of health care, education, cultural and other social services, not incl. social security"
    },
    {
        code: 84130, description: "Regulation of and contribution to more efficient operation of businesses"
    },
    {
        code: 84210, description: "Foreign affairs"
    },
    {
        code: 84220, description: "Defence activities"
    },
    {
        code: 84230, description: "Justice and judicial activities"
    },
    {
        code: 84240, description: "Public order and safety activities"
    },
    {
        code: 84250, description: "Fire service activities"
    },
    {
        code: 84300, description: "Compulsory social security activities"
    },
    {
        code: 85100, description: "Pre-primary education"
    },
    {
        code: 85200, description: "Primary education"
    },
    {
        code: 85310, description: "General secondary education"
    },
    {
        code: 85320, description: "Technical and vocational secondary education"
    },
    {
        code: 85410, description: "Post-secondary non-tertiary education"
    },
    {
        code: 85421, description: "First-degree level higher education"
    },
    {
        code: 85422, description: "Post-graduate level higher education"
    },
    {
        code: 85510, description: "Sports and recreation education"
    },
    {
        code: 85520, description: "Cultural education"
    },
    {
        code: 85530, description: "Driving school activities"
    },
    {
        code: 85590, description: "Other education n.e.c."
    },
    {
        code: 85600, description: "Educational support services"
    },
    {
        code: 86101, description: "Hospital activities"
    },
    {
        code: 86102, description: "Medical nursing home activities"
    },
    {
        code: 86210, description: "General medical practice activities"
    },
    {
        code: 86220, description: "Specialists medical practice activities"
    },
    {
        code: 86230, description: "Dental practice activities"
    },
    {
        code: 86900, description: "Other human health activities"
    },
    {
        code: 87100, description: "Residential nursing care facilities"
    },
    {
        code: 87200,
        description: "Residential care activities for learning difficulties, mental health and substance abuse"
    },
    {
        code: 87300, description: "Residential care activities for the elderly and disabled"
    },
    {
        code: 87900, description: "Other residential care activities n.e.c."
    },
    {
        code: 88100, description: "Social work activities without accommodation for the elderly and disabled"
    },
    {
        code: 88910, description: "Child day-care activities"
    },
    {
        code: 88990, description: "Other social work activities without accommodation n.e.c."
    },
    {
        code: 90010, description: "Performing arts"
    },
    {
        code: 90020, description: "Support activities to performing arts"
    },
    {
        code: 90030, description: "Artistic creation"
    },
    {
        code: 90040, description: "Operation of arts facilities"
    },
    {
        code: 91011, description: "Library activities"
    },
    {
        code: 91012, description: "Archives activities"
    },
    {
        code: 91020, description: "Museums activities"
    },
    {
        code: 91030, description: "Operation of historical sites and buildings and similar visitor attractions"
    },
    {
        code: 91040, description: "Botanical and zoological gardens and nature reserves activities"
    },
    {
        code: 92000, description: "Gambling and betting activities"
    },
    {
        code: 93110, description: "Operation of sports facilities"
    },
    {
        code: 93120, description: "Activities of sport clubs"
    },
    {
        code: 93130, description: "Fitness facilities"
    },
    {
        code: 93191, description: "Activities of racehorse owners"
    },
    {
        code: 93199, description: "Other sports activities"
    },
    {
        code: 93210, description: "Activities of amusement parks and theme parks"
    },
    {
        code: 93290, description: "Other amusement and recreation activities n.e.c."
    },
    {
        code: 94110, description: "Activities of business and employers membership organizations"
    },
    {
        code: 94120, description: "Activities of professional membership organizations"
    },
    {
        code: 94200, description: "Activities of trade unions"
    },
    {
        code: 94910, description: "Activities of religious organizations"
    },
    {
        code: 94920, description: "Activities of political organizations"
    },
    {
        code: 94990, description: "Activities of other membership organizations n.e.c."
    },
    {
        code: 95110, description: "Repair of computers and peripheral equipment"
    },
    {
        code: 95120, description: "Repair of communication equipment"
    },
    {
        code: 95210, description: "Repair of consumer electronics"
    },
    {
        code: 95220, description: "Repair of household appliances and home and garden equipment"
    },
    {
        code: 95230, description: "Repair of footwear and leather goods"
    },
    {
        code: 95240, description: "Repair of furniture and home furnishings"
    },
    {
        code: 95250, description: "Repair of watches, clocks and jewellery"
    },
    {
        code: 95290, description: "Repair of personal and household goods n.e.c."
    },
    {
        code: 96010, description: "Washing and (dry-)cleaning of textile and fur products"
    },
    {
        code: 96020, description: "Hairdressing and other beauty treatment"
    },
    {
        code: 96030, description: "Funeral and related activities"
    },
    {
        code: 96040, description: "Physical well-being activities"
    },
    {
        code: 96090, description: "Other service activities n.e.c."
    },
    {
        code: 97000, description: "Activities of households as employers of domestic personnel"
    },
    {
        code: 98000, description: "Residents property management"
    },
    {
        code: 98100, description: "Undifferentiated goods-producing activities of private households for own use"
    },
    {
        code: 98200, description: "Undifferentiated service-producing activities of private households for own use"
    },
    {
        code: 99000, description: "Activities of extraterritorial organizations and bodies"
    },
    {
        code: 99999, description: "Dormant Company"
    },
];
