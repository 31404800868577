import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
let Login = class Login {
    constructor(router) {
        this.router = router;
    }
    handleLogin(loggedIn) {
        //method to handle after the login functionality
        this.router.navigate(["/"]);
    }
};
Login = __decorate([
    Component({
        selector: "user-login",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [Router])
], Login);
export default Login;
