import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { VehicleApi } from "../../../services/vehicle-api";
import { VehicleFormFactory } from "../../../../../factories/vehicle";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let EditVehicle = class EditVehicle {
    constructor(VehicleApi, dialogRef, data) {
        this.VehicleApi = VehicleApi;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this._item = new BehaviorSubject({
            organisationId: null,
            vehicleId: null,
            vehicleRegistration: null,
            pinCode: null,
            notes: null,
        });
        this.form = VehicleFormFactory.form();
    }
    ngOnInit() {
        this.vehicleId = this.data;
        this._item.subscribe((item) => this.form.setValue(item));
        !!this.vehicleId && this.set();
    }
    onSubmit() {
        this.dialogRef.close(this.form.value);
    }
    set() {
        this.loading = true;
        this.form.disable();
        this.VehicleApi.get(this.vehicleId.vehicleId).then((data) => {
            this.loading = false;
            this.form.enable();
            this._item.next(data);
        });
    }
};
EditVehicle = __decorate([
    Component({
        selector: "edit-vehicle",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [VehicleApi,
        MatDialogRef, Object])
], EditVehicle);
export { EditVehicle };
