import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { WasteApi } from '../../../app/services/waste-api';
let CarrierWasteApi = class CarrierWasteApi extends WasteApi {
    getReadEndpoint(consignmentId, wasteId) {
        return environment.endpoint.carrier.consignmentWaste(consignmentId, wasteId);
    }
    getWriteEndpoint(consignmentId, wasteId) {
        return environment.endpoint.broker.consignmentWaste(consignmentId, wasteId);
    }
};
CarrierWasteApi = __decorate([
    Injectable()
], CarrierWasteApi);
export { CarrierWasteApi };
