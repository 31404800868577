import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject, Input } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SiteRegistrationService } from "../../../../../../../app/services/v2/site-registration.service";
import { EnvironmentAgencyService } from "../../../../../../../app/services/v2/environment-agency.service";
import { predefinedCountries } from "../../company-info/countries";
import { envParams } from "../../../../../../../app/environment/envParams";
import { AddressFormFactory } from "../../../../../../../factories/address";
let NewSiteModalComponent = class NewSiteModalComponent {
    constructor(dialogRef, environmentApi, fb, siteRegistrationApi, data) {
        this.dialogRef = dialogRef;
        this.environmentApi = environmentApi;
        this.fb = fb;
        this.siteRegistrationApi = siteRegistrationApi;
        this.data = data;
        this.countryList = predefinedCountries;
        this.isEdit = false;
        this.siteRegistrationForm = this.data.form;
    }
    ngOnInit() {
        const { facility } = this.data;
        if (window.location.pathname === "/manage-waste-facilities") {
            this.isEdit = true;
        }
        this.companyType = this.data.companyType;
        this.siteRegForm = this.siteRegistrationForm.get("address");
        this.countryName =
            this.countryName === undefined ? "England" : this.countryName;
        this.title = facility
            ? "Edit Waste/Recycling Site"
            : "Add New Waste/Recycling Site";
        !!facility && this.loadFormData(facility);
        (this.siteRegistrationForm.controls.address).controls.postcode.addValidators(AddressFormFactory.validatePostCode);
    }
    addSite() {
        const formData = this.siteRegistrationForm.getRawValue();
        this.siteRegistrationApi
            .edit(Object.assign({}, formData))
            .then(() => this.dialogRef.close());
    }
    loadFormData(facility) {
        const { address, openingHoursForm, wasteFacilityId } = facility;
        this.siteRegistrationForm
            .get("wasteFacilityId")
            .patchValue(wasteFacilityId);
        this.siteRegistrationForm.get("address").patchValue(address);
        this.siteRegistrationForm
            .get("openingHoursForm")
            .patchValue(openingHoursForm);
        let contacts = facility.primaryContactForm;
        this.siteRegistrationForm.get("primaryContactForm").clear();
        contacts &&
            contacts.forEach((contact) => {
                const primaryContactForm = this.createContactForm();
                if (!contact.primary_contact_lastname) {
                    const names = contact.primary_contact_name.split(" ");
                    if (names.length > 1) {
                        contact.primary_contact_lastname = names.pop();
                        contact.primary_contact_name = names.join(" ");
                    }
                }
                primaryContactForm.patchValue(contact);
                this.siteRegistrationForm.get("primaryContactForm").push(primaryContactForm);
            });
    }
    createContactForm() {
        return this.fb.group({
            primary_contact_name: [null, [Validators.required]],
            primary_contact_lastname: [null, [Validators.required]],
            primary_contact_email: [null, [Validators.required]],
            primary_contact_no: [null, null],
        });
    }
    autopopulateAddress() {
        let postcode = this.data.address.match(/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/gm);
        if (postcode) {
            postcode = postcode[0];
            let address = this.data.address.replace(postcode, "").trim();
            const lastCharacter = address.substr(address.length - 1);
            if (lastCharacter === ",") {
                address = address.slice(0, -1);
            }
            this.getCoordinates(address)
                .then((data) => {
                const latitude = data.results[0].geometry.location.lat;
                const longitude = data.results[0].geometry.location.lng;
                this.siteRegForm.patchValue({
                    address: address,
                    postcode: postcode,
                    latitude,
                    longitude,
                });
            })
                .catch(() => {
                this.siteRegForm.patchValue({
                    address: address,
                    postcode: postcode,
                });
            });
        }
        else {
            this.getCoordinates(this.data.address)
                .then((data) => {
                const latitude = data.results[0].geometry.location.lat;
                const longitude = data.results[0].geometry.location.lng;
                this.siteRegForm.patchValue({
                    address: this.data.address,
                    latitude,
                    longitude,
                });
            })
                .catch(() => {
                this.siteRegForm.patchValue({ address: this.data.address });
            });
        }
    }
    getCoordinates(address) {
        return fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" +
            address +
            "&key=" +
            envParams.googleApiKey).then((response) => response.json());
    }
    onChange(event) {
        this.verificationType = event.value;
    }
    onCountrySelected(country) {
        this.countryName = country.name;
        const fields = ["validPermits", "permits", "validExemptions", "exemptions"];
        for (const field of fields) {
            this.siteRegistrationForm.controls["permitForm"]["controls"][field].patchValue("");
        }
        this.companyType === "recyclers"
            ? this.validatePermits()
            : this.invalidatePermits();
        this.updatePermitValidity();
    }
    validatePermits() {
        this.siteRegistrationForm.controls["permitForm"]["controls"]["exemptions"].addValidators(Validators.required);
        this.siteRegistrationForm.controls["permitForm"]["controls"]["permits"].addValidators(Validators.required);
    }
    invalidatePermits() {
        this.siteRegistrationForm.controls["permitForm"]["controls"]["exemptions"].clearValidators();
        this.siteRegistrationForm.controls["permitForm"]["controls"]["permits"].clearValidators();
        this.updatePermitValidity();
    }
    updatePermitValidity() {
        this.siteRegistrationForm.controls["permitForm"]["controls"]["exemptions"].updateValueAndValidity();
        this.siteRegistrationForm.controls["permitForm"]["controls"]["permits"].updateValueAndValidity();
    }
    getAddress(address) {
        this.siteRegistrationForm.get("address").patchValue(address);
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], NewSiteModalComponent.prototype, "formGroupName", void 0);
NewSiteModalComponent = __decorate([
    Component({
        selector: "app-new-site-modal",
        template: require('./new-site-modal.component.html'),
        styles: [require('./new-site-modal.component.scss')],
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef,
        EnvironmentAgencyService,
        FormBuilder,
        SiteRegistrationService, Object])
], NewSiteModalComponent);
export { NewSiteModalComponent };
