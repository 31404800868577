var WasteFormFactory_1;
import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let WasteFormFactory = WasteFormFactory_1 = class WasteFormFactory {
    static form(Waste) {
        let _FormBuilder = new FormBuilder;
        let FormGroup = _FormBuilder.group({
            consignmentId: [null],
            wasteId: [null],
            qty: [null, [Validators.required]],
            ewcCode: [null, [Validators.required, WasteFormFactory_1.ewcCodeValid]],
            description: [null, [Validators.required]],
            components: [null],
            physicalForm: [null, [Validators.required]],
            hazardCodes: [null],
            container: [null, [Validators.required]],
            physicalDestructionRequired: [null],
            dataDestructionRequired: [null],
        });
        !!Waste && FormGroup.setValue(Waste);
        return FormGroup;
    }
    static ewcCodeValid(control) {
        let valid = (control.value instanceof Object);
        valid && (valid = !!control.value.code && !!control.value.description);
        return valid ? null : {
            validCode: {
                valid: false,
            },
        };
    }
};
WasteFormFactory = WasteFormFactory_1 = __decorate([
    Injectable()
], WasteFormFactory);
export { WasteFormFactory };
