import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { BrokerWasteTransferNoteApi } from "../../services/waste-transfer-note-api";
let ViewWasteTransferNote = class ViewWasteTransferNote {
    constructor(dialog, dialogRef, Api, Job) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.Api = Api;
        this.Job = Job;
    }
    ngOnInit() {
        this.Api._loading.subscribe((loading) => (this.loading = loading));
        this.Api._wasteTransferNote.subscribe((WasteTransferNote) => (this.WasteTransferNote = WasteTransferNote));
        this.Api.getWasteTransferNote(this.Job.jobId);
    }
};
ViewWasteTransferNote = __decorate([
    Component({
        selector: "view-waste-transfer-note",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        BrokerWasteTransferNoteApi, Object])
], ViewWasteTransferNote);
export { ViewWasteTransferNote };
