import { __decorate, __metadata } from "tslib";
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PasswordChangeFormFactory } from '../../../../../factories/password/change';
let ChangePasswordModal = class ChangePasswordModal {
    constructor(dialogRef) {
        this.dialogRef = dialogRef;
    }
    ngOnInit() {
        this.form = PasswordChangeFormFactory.form();
    }
    onSubmit() {
        this.dialogRef.close(this.form.getRawValue());
    }
    close() {
        this.dialogRef.close({ closed: true });
    }
};
ChangePasswordModal = __decorate([
    Component({
        selector: 'user-password-change',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [MatDialogRef])
], ChangePasswordModal);
export { ChangePasswordModal };
