import { __awaiter, __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { ConsignmentFormFactory } from "../../../../../factories/consignment";
import { BehaviorSubject } from "rxjs";
import { sicCodes } from "../../../../../app/constant/sic-codes";
import { UserService } from "../../../../user/services/user-api";
import { BrokerConsignmentApi } from "../../../services/consignment-api";
import { debounceTime, distinctUntilChanged, map, startWith, } from "rxjs/operators";
import "rxjs";
import { WasteFacilityApi } from "../../../../carrier/services/waste-facility-api";
import { AddressFormFactory } from "../../../../../factories/address";
let EditConsignment = class EditConsignment {
    constructor(UserService, ConsignmentApi, WasteFacilitiesApi, dialog, dialogRef, data) {
        this.UserService = UserService;
        this.ConsignmentApi = ConsignmentApi;
        this.WasteFacilitiesApi = WasteFacilitiesApi;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.quoted = false;
        this._wasteFacilities = new BehaviorSubject([]);
        this.SicCodes = sicCodes;
        this.isValidwasteRemovedFromAddress = true;
        this._item = new BehaviorSubject({
            consignmentId: null,
            brokerId: null,
            consignmentNoteCode: null,
            description: null,
            wasteRemovedFromAddress: null,
            wasteProducerAddress: null,
            wasteDescriptionProcess: null,
            wasteDescriptionSic2007: null,
            municipalSource: null,
            conignmentNoteNumber: null,
        });
    }
    ngOnInit() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            this.consignmentId =
                ((_a = this.data) === null || _a === void 0 ? void 0 : _a.status) === "completed" ? this.data.consignmentId : this.data;
            this.form = ConsignmentFormFactory.form();
            this._wasteFacilities.subscribe((items) => (this.wasteFacilities = items));
            this.loadWasteFacilities();
            this._item.subscribe((Consignment) => {
                let form = Object.assign({}, Consignment);
                this.removedFromAndProducerAddressTheSame =
                    !!form["wasteProducerAddress"] &&
                        form.wasteProducerAddress.wasteFacilityId ==
                            form.wasteRemovedFromAddress.wasteFacilityId;
                form["removedFromAndProducerAddressTheSame"] =
                    this.removedFromAndProducerAddressTheSame;
                // this.form.setValue(form);
                Object.keys(form).forEach((name) => {
                    if (this.form.controls[name]) {
                        this.form.controls[name].patchValue(form[name]);
                    }
                });
            });
            !!this.consignmentId && this.set();
            this.filteredSICCodes = this.form
                .get("wasteDescriptionSic2007")
                .valueChanges.pipe(startWith(null))
                .pipe(debounceTime(400), distinctUntilChanged())
                .pipe(map((SicCode) => this.filterSICCodes(SicCode)));
        });
    }
    set() {
        var _a, _b;
        this.loading = true;
        this.form.disable();
        const api = ((_a = this.data) === null || _a === void 0 ? void 0 : _a.ConsignmentApi) ? (_b = this.data) === null || _b === void 0 ? void 0 : _b.ConsignmentApi : this.ConsignmentApi;
        api.get(this.consignmentId).then((Consignment) => {
            var _a;
            if (!this.data.isRepeat) {
                this.quoted =
                    !!Consignment.quoteProvideTimestamp &&
                        !this.UserService.isInGroup("carrier");
            }
            if (((_a = this.data) === null || _a === void 0 ? void 0 : _a.status) === "completed") {
                delete Consignment.collectedTimestamp;
                delete Consignment.collectionDate;
                delete Consignment.collectionId;
                delete Consignment.completedTimestamp;
                delete Consignment.consignmentId;
                delete Consignment.dropOffDate;
                delete Consignment.dropOffId;
                delete Consignment.droppedOffTimestamp;
                delete Consignment.jobAllocateTimestamp;
                delete Consignment.jobId;
            }
            delete Consignment.Quotes;
            delete Consignment.quoteRequestTimestamp;
            delete Consignment.quoteProvideTimestamp;
            delete Consignment.jobAcceptTimestamp;
            Consignment.wasteRemovedFromAddress = this.addressCheck(Consignment.wasteRemovedFromAddress);
            Consignment.wasteProducerAddress = this.addressCheck(Consignment.wasteProducerAddress);
            this.loading = false;
            this.form.enable();
            this._item.next(Consignment);
        });
    }
    addressCheck(address) {
        // If no longitude or latitude reset address
        if (!address.latitude || !address.longitude) {
            address = Object.assign(Object.assign({}, address), { latitude: null, longitude: null });
            address.address = null;
            address.postcode = null;
        }
        return address;
    }
    copyRemovedFromToProducerAddress() {
        let wasteRemovedFromAddress = this.form.get("wasteRemovedFromAddress").value;
        let wasteProducerAddressForm = this.form.get("wasteProducerAddress");
        wasteProducerAddressForm.setValue(wasteRemovedFromAddress);
    }
    checkboxState(checked) {
        this.removedFromAndProducerAddressTheSame = checked;
        if (!this.removedFromAndProducerAddressTheSame) {
            let wasteProducerAddressForm = this.form.get("wasteProducerAddress");
            const values = wasteProducerAddressForm.value;
            values.wasteFacilityId = "";
            wasteProducerAddressForm.setValue(values);
        }
        else {
            this.copyRemovedFromToProducerAddress();
        }
    }
    selectWasteFacility() {
        let wasteRemovedFromAddress = this.form.get("wasteRemovedFromAddress");
        const wasteRemovedFromAddressValue = wasteRemovedFromAddress.value;
        if (!wasteRemovedFromAddressValue.latitude ||
            !wasteRemovedFromAddressValue.longitude ||
            AddressFormFactory.validatePostCode(wasteRemovedFromAddressValue.postcode)) {
            this.removedFromAndProducerAddressTheSame = false;
            wasteRemovedFromAddress.patchValue(null);
            this.isValidwasteRemovedFromAddress = false;
        }
        else {
            this.isValidwasteRemovedFromAddress = true;
            this.removedFromAndProducerAddressTheSame &&
                this.copyRemovedFromToProducerAddress();
        }
    }
    onSubmit() {
        let formValue = this.form.value;
        delete formValue.removedFromAndProducerAddressTheSame;
        let Consignment = Object.assign(this._item.getValue(), formValue);
        this.dialogRef.close(Consignment);
    }
    filterSICCodes(val) {
        try {
            return val
                ? this.SicCodes.filter((SicCode) => {
                    return (new RegExp(val, "gi").test(String(SicCode.code)) ||
                        new RegExp(val, "gi").test(SicCode.description));
                })
                : [];
        }
        catch (e) {
            return [];
        }
    }
    displaySICCodes(SicCode) {
        return !!SicCode ? SicCode.code + " (" + SicCode.description + ")" : "";
    }
    loadWasteFacilities() {
        this.WasteFacilitiesApi.list().then((data) => {
            this._wasteFacilities.next(data.map((item) => {
                const address = item.address;
                return {
                    wasteFacilityId: item.wasteFacilityId || null,
                    name: address.name,
                    address: address.address,
                    email: address.site_email,
                    latitude: address.latitude,
                    longitude: address.longitude,
                    mobile: address.site_phone_number,
                    postcode: address.postcode,
                };
            }));
        });
    }
    compareItems(i1, i2) {
        return i1 && i2 && i1.wasteFacilityId === i2.wasteFacilityId;
    }
    getAddress(address) {
        const wasteProducerAddressForm = this.form.get("wasteProducerAddress");
        const formValue = this.form.value;
        const name = formValue.wasteProducerAddress.name;
        wasteProducerAddressForm.setValue(Object.assign(Object.assign({}, address), { name }));
    }
};
EditConsignment = __decorate([
    Component({
        selector: "edit-consignment-modal",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(5, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [UserService,
        BrokerConsignmentApi,
        WasteFacilityApi,
        MatDialog,
        MatDialogRef, Object])
], EditConsignment);
export { EditConsignment };
