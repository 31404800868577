import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
let ReportsApi = class ReportsApi {
    constructor(Api) {
        this.Api = Api;
    }
    all(filters) {
        let endpoint = this.getEndpoint() + "/ea-returns-comparison";
        return this.Api.request(endpoint, "POST", filters);
    }
    hazardousMaterials(filters) {
        let endpoint = this.getEndpoint() + "/hazardous-materials";
        return this.Api.request(endpoint, "POST", filters);
    }
    getEndpoint() {
        return environment.endpoint.admin.reports();
    }
};
ReportsApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], ReportsApi);
export { ReportsApi };
