import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroupDirective, } from "@angular/forms";
let OpeningHoursFormGroup = class OpeningHoursFormGroup {
    constructor(formBuilder, rootFormGroup) {
        this.formBuilder = formBuilder;
        this.rootFormGroup = rootFormGroup;
    }
    ngOnInit() {
        this.openingHoursForm = this.rootFormGroup.control.get(this.formGroupName);
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], OpeningHoursFormGroup.prototype, "formGroupName", void 0);
OpeningHoursFormGroup = __decorate([
    Component({
        selector: "app-hours-form",
        template: require('./opening-hours.component.html'),
        styles: [require('./opening-hours.component.scss')],
    }),
    __metadata("design:paramtypes", [FormBuilder,
        FormGroupDirective])
], OpeningHoursFormGroup);
export { OpeningHoursFormGroup };
