import { __decorate, __metadata, __rest } from "tslib";
import { Component } from "@angular/core";
import { WasteFacilityApi } from "../../../../carrier/services/waste-facility-api";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, Validators } from '@angular/forms';
import moment from "moment-timezone";
import { ReportsApi } from "../../../services/reports-api";
import { saveAs } from 'file-saver';
let EAReturnsComparisonReport = class EAReturnsComparisonReport {
    constructor(WasteFacilitiesApi, ReportsApi) {
        this.WasteFacilitiesApi = WasteFacilitiesApi;
        this.ReportsApi = ReportsApi;
        this._wasteFacilities = new BehaviorSubject([]);
        this.loading = false;
        this.eaReturnsComparisonReportColumnMap = [
            { name: "type", title: "" },
            { name: "name", title: "Origin/Destionation" },
            { name: "ewcCode", title: "EWC Code" },
            { name: "disposalAndRecovery", title: "D + R" },
            { name: "municipalSource", title: "Municipal Source" },
            { name: "isDegradable", title: "Degradable" },
            { name: "physicalForm", title: "State" },
            { name: "tons", title: "Tons" },
        ];
        this.hazardMaterialsReportColumnMap = [
            { name: "type", title: "" },
            { name: "name", title: "Site" },
            { name: "consignmentNoteCode", title: "CNN" },
            { name: "multipleCollections", title: "Single or Multiple" },
            { name: "roundNumber", title: "Round No" },
            { name: "collectionNumber", title: "Collection No" },
            { name: "dropOffDate", title: "Date Received" },
            { name: "postcode", title: "Creation Postcode" },
            { name: "ewcCode", title: "EWC" },
            { name: "hazardCodes", title: "Hazardous" },
            { name: "physicalForm", title: "State" },
            { name: "kilos", title: "Kilos" },
            { name: "disposalAndRecovery", title: "R + D" },
        ];
    }
    ngOnInit() {
        this.todayDate = moment().startOf("day").toDate();
        this.startDate = moment().add(-1, "months").startOf("day").toDate();
        let _FormBuilder = new FormBuilder;
        this.form = _FormBuilder.group({
            wasteFacilityId: [null, [Validators.required]],
            startDate: [null],
            endDate: [null],
            reportType: [null, [Validators.required]]
        });
        this.form.valueChanges.subscribe(() => this.setStartDate());
        this.form.patchValue({
            startDate: moment(this.startDate).startOf("day").toDate(),
            endDate: moment(this.todayDate).startOf("day").toDate(),
        });
        this._wasteFacilities.subscribe((items) => (this.wasteFacilities = items));
        this.loadWasteFacilities();
    }
    generateReport() {
        this.loading = true;
        const _a = this.form.getRawValue(), { reportType } = _a, filters = __rest(_a, ["reportType"]);
        if (reportType === "all") {
            this.ReportsApi.all(filters)
                .then((data) => {
                this.downloadFile(data, this.eaReturnsComparisonReportColumnMap, `EAReturnsComparison_${new Date().getTime()}.csv`);
            });
        }
        else {
            this.ReportsApi.hazardousMaterials(filters)
                .then((data) => {
                this.downloadFile(data, this.hazardMaterialsReportColumnMap, `HazardousMaterials_${new Date().getTime()}.csv`);
            });
        }
    }
    downloadFile(data, columns, fileName) {
        if (data.length > 0) {
            const replacer = (key, value) => value === null ? '' : value;
            const header = columns.map(c => c.title);
            let csv = data.map(row => columns.map(col => JSON.stringify(row[col.name], replacer)).join(','));
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');
            var blob = new Blob([csvArray], { type: 'text/csv' });
            saveAs(blob, fileName);
        }
        else {
            alert("There is no data, please change your filters and try again.");
        }
        this.loading = false;
    }
    setStartDate() {
        this.startDate = moment(this.form.controls["startDate"].value)
            .startOf("day")
            .toDate();
    }
    loadWasteFacilities() {
        this.WasteFacilitiesApi.list().then((data) => {
            this._wasteFacilities.next(data.map((item) => {
                const address = item.address;
                return {
                    wasteFacilityId: item.wasteFacilityId || null,
                    name: address.name
                };
            }));
        });
    }
};
EAReturnsComparisonReport = __decorate([
    Component({
        selector: "ea-returns-comparison-report",
        template: require('./component.html'),
        styles: [require('./component.scss')]
    }),
    __metadata("design:paramtypes", [WasteFacilityApi,
        ReportsApi])
], EAReturnsComparisonReport);
export { EAReturnsComparisonReport };
