import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { ConsignmentNoteApi } from '../../../app/services/consignment-note-api';
let CarrierConsignmentNoteApi = class CarrierConsignmentNoteApi extends ConsignmentNoteApi {
    getEndpoint(jobId, section, field) {
        return environment.endpoint.carrier.consignmentNotes(jobId, section, field);
    }
};
CarrierConsignmentNoteApi = __decorate([
    Injectable()
], CarrierConsignmentNoteApi);
export { CarrierConsignmentNoteApi };
