import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CarrierConsignmentNoteApi } from "../../../../services/consignment-note-api";
import { EditText } from "../../../../../directives/consignment-note/edit-text-modal/component";
import { ConfirmEdit } from "../../../../../directives/consignment-note/confirm-edit-modal/component";
import { EditCompanyAddress } from "../../../../../directives/consignment-note/edit-company-address-modal/component";
let ConsignmentNote = class ConsignmentNote {
    constructor(ConsignmentNoteApi, dialog) {
        this.ConsignmentNoteApi = ConsignmentNoteApi;
        this.dialog = dialog;
    }
    updateTextString(update, fieldName, oldString, title, placeholder) {
        !placeholder && (placeholder = title);
        Promise.resolve()
            .then(() => this.getNewText(title, placeholder, oldString, update))
            .then(() => this.getConfirmation(update))
            .then(() => this.update(update, fieldName))
            .catch(() => this.handleNotUpdated(placeholder + " not updated."));
    }
    getNewText(title, placeholder, oldText, update) {
        return new Promise((resolve, reject) => {
            let config = new MatDialogConfig();
            config.disableClose = true;
            config.data = {
                title: title,
                placeholder: placeholder,
                text: oldText,
            };
            this.dialog
                .open(EditText, config)
                .afterClosed()
                .subscribe((newText) => {
                update.update = newText;
                if (!newText) {
                    return reject();
                }
                resolve();
            });
        });
    }
    update(update, field) {
        return this.ConsignmentNoteApi.update(update, this.section, field);
    }
    getConfirmation(update) {
        return new Promise((resolve, reject) => {
            let config = new MatDialogConfig();
            config.disableClose = true;
            this.dialog
                .open(ConfirmEdit, config)
                .afterClosed()
                .subscribe((reason) => {
                update.reason = reason;
                if (!reason) {
                    return reject();
                }
                resolve();
            });
        });
    }
    getNewAddress(oldAddress, update) {
        return new Promise((resolve, reject) => {
            let config = new MatDialogConfig();
            config.disableClose = true;
            config.data = oldAddress;
            this.dialog
                .open(EditCompanyAddress, config)
                .afterClosed()
                .subscribe((newAddress) => {
                update.update = newAddress;
                if (!newAddress) {
                    return reject();
                }
                resolve();
            });
        });
    }
    handleNotUpdated(message) {
        alert(message);
    }
};
ConsignmentNote = __decorate([
    Component({}),
    __metadata("design:paramtypes", [CarrierConsignmentNoteApi,
        MatDialog])
], ConsignmentNote);
export { ConsignmentNote };
