import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { CarrierConsignmentNoteApi } from "../../services/consignment-note-api";
let ViewConsignmentNote = class ViewConsignmentNote {
    constructor(dialog, dialogRef, Api, Job) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.Api = Api;
        this.Job = Job;
    }
    ngOnInit() {
        this.Api._loading.subscribe((loading) => (this.loading = loading));
        this.Api._consignmentNote.subscribe((ConsignmentNote) => {
            this.ConsignmentNote = ConsignmentNote;
        });
        this.Api.getConsignmentNote(this.Job.jobId);
    }
};
ViewConsignmentNote = __decorate([
    Component({
        selector: "view-consignment",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        CarrierConsignmentNoteApi, Object])
], ViewConsignmentNote);
export { ViewConsignmentNote };
