import { __decorate, __metadata, __param } from "tslib";
import moment from "moment-timezone";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { ProvideQuoteFactory } from "../../../../../factories/provide-quote";
import { CarrierQuoteApi } from "../../../services/quote-api";
import { CarrierWasteApi } from "../../../services/waste-api";
import { disposalAndRecoveryList } from "../../../../../app/constant/disposal-recovery-codes";
let ProvideQuote = class ProvideQuote {
    constructor(dialog, dialogRef, QuoteApi, WasteApi, Quote) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.QuoteApi = QuoteApi;
        this.WasteApi = WasteApi;
        this.Quote = Quote;
        this.loading = false;
        this.dAndRList = disposalAndRecoveryList;
    }
    // Use price in case a quote has been reset
    // this can then be reviewed and amended
    ngOnInit() {
        this.form = ProvideQuoteFactory.form();
        this.startDate = moment().startOf("day").toDate();
        this.QuoteApi.getConsignment(this.Quote.quoteId, this.Quote.consignmentId)
            .then(consignment => {
            this.municipalSource = consignment.municipalSource;
            this.wastes = consignment.Waste;
        });
        if (!this.Quote.provideTimestamp) {
            this.form.controls["collectionDate"].setValue(this.startDate);
        }
        else {
            this.form.setValue({
                price: this.Quote.price,
                collectionDate: moment(this.Quote.collectionDate)
                    .startOf("day")
                    .toDate(),
                carrierNotes: this.Quote.carrierNotes,
            });
        }
    }
    onSubmit() {
        this.loading = true;
        let quote = this.form.getRawValue();
        quote.consignmentId = this.Quote.consignmentId;
        quote.quoteId = this.Quote.quoteId;
        quote.wastes = this.wastes.map(waste => { return { wasteId: waste.wasteId, physicalForm: waste.physicalForm }; });
        this.QuoteApi.provideQuote(quote).then(() => {
            this.loading = false;
            this.dialogRef.close(true);
        });
    }
    changeWasteForm(event, waste) {
        this.wastes.forEach(item => {
            if (item.wasteId === waste.wasteId) {
                item.physicalForm = event.value;
            }
        });
    }
};
ProvideQuote = __decorate([
    Component({
        selector: "provide-quote",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        CarrierQuoteApi,
        CarrierWasteApi, Object])
], ProvideQuote);
export { ProvideQuote };
