import { __decorate, __metadata } from "tslib";
import moment from "moment-timezone";
import { Injectable } from "@angular/core";
import { Api } from "./api";
let QuoteApi = class QuoteApi {
    constructor(Api) {
        this.Api = Api;
    }
    list(quoteStatus) {
        let endpoint = this.getEndpoint(quoteStatus);
        return this.Api.request(endpoint);
    }
    get(quoteId) {
        let endpoint = this.getEndpoint(quoteId);
        return this.Api.request(endpoint);
    }
    getConsignment(quoteId, consignmentId) {
        let endpoint = this.getQuoteConsignmentEndpoint(quoteId, consignmentId);
        return this.Api.request(endpoint);
    }
    remove(quoteId) {
        let endpoint = this.getEndpoint(quoteId);
        return this.Api.request(endpoint, "DELETE");
    }
    requestQuote(consignmentId, body) {
        let endpoint = this.getConsignmentQuoteEndpoint(consignmentId);
        return this.Api.request(endpoint, "POST", body);
    }
    provideQuote(ProvideQuote) {
        let body = Object.assign(ProvideQuote);
        body.collectionDate = moment(body.collectionDate).format("YYYY-MM-DD");
        let endpoint = this.getEndpoint(ProvideQuote.quoteId) + "/provide";
        return this.Api.request(endpoint, "POST", body);
    }
    acceptQuote(Quote) {
        let endpoint = this.getConsignmentQuoteEndpoint(Quote.consignmentId, Quote.quoteId, "accept");
        return this.Api.request(endpoint, "POST");
    }
    saveWasteImage(putUrl, blob) {
        return this.Api.s3PutObject(putUrl, blob);
    }
};
QuoteApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], QuoteApi);
export { QuoteApi };
