export const disposalAndRecoveryList = [
    'D11 Deposit into or onto land',
    'D2 Land treatment',
    'D3 Deep injection',
    'D4 Surface impoundment',
    'D5 Specially engineered landfill',
    'D6 Release into a water body except seas / oceans',
    'D7 Release into seas / oceans',
    'D8 Biological treatment not specified elsewhere',
    'D9 Physico-chemical treatment not specified elsewhere',
    'D10 Incineration on land',
    'D12 Permanent storage',
    'D13 Blending or mixing prior to D1 to D12',
    'D14 Repackaging prior to D1 to D13',
    'D15 Temporary storage pending D1 to D14',
    'R1 Use principally as a fuel',
    'R2 Solvent reclamation / regeneration by distillation',
    'R3 Recycling / reclamation of organic substances',
    'R4 Recycling / reclamation - metals',
    'R5 Recycling / reclamation - other inorganic materials',
    'R6 Regeneration of acids or bases',
    'R7 Recovery of components used for pollution abatement',
    'R8 Recovery of components from catalysts',
    'R9 Oil re-refining or other reuses of oil',
    'R10 Land treatment resulting in benefit',
    'R11 Use of wastes obtained from any operation R1 to R10',
    'R12 Exchange of wastes for R1 to R11',
    'R13 Storage of wastes pending any of the operations R1 to R12'
];
