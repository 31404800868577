import { envParams } from "../environment/envParams";
export const environment = {
    cognito: envParams.cognito,
    driverUrl: envParams.driverUrl,
    endpoint: {
        admin: {
            organisations: (organisationId) => environment.endpoint.base.organisations("admin", organisationId),
            reports: (month) => environment.endpoint.base.reports("admin", month),
            userOrganisations: (userId) => environment.endpoint.base.userOrganisations("admin", userId),
            // New endpoints
            updateOrganisation: () => envParams.endpoints["admin"] + "update-organisation",
            preferredRecyclers: (organisationId) => envParams.endpoints["admin"] +
                "preferred-recyclers" +
                (!!organisationId ? "/" + organisationId : ""),
            inviteRecyclers: () => envParams.endpoints["admin"] + "invite-recyclers",
            subscription: (subscriptionId) => envParams.endpoints["admin"] +
                "subscription" +
                (!!subscriptionId ? "/" + subscriptionId : ""),
            paymentManagement: () => envParams.endpoints["admin"] + "payment-management",
            invoice: () => envParams.endpoints["admin"] + "invoices",
            companiesHouse: (companyNumber) => environment.endpoint.base.companiesHouse("admin", companyNumber),
            environmentAgency: (permitType, permitNumber) => environment.endpoint.base.environmentAgency("admin", permitType, permitNumber),
            createCheckoutSession: () => {
                return environment.endpoint.base.checkout("admin");
            },
            getCheckoutPlans: () => {
                return environment.endpoint.base.plans("admin");
            },
            getPlanPrices: () => {
                return environment.endpoint.base.prices("admin");
            },
            createOrganisation: () => {
                return environment.endpoint.base.organisations("admin");
            },
            createWasteFacility: (wasteFacilityId) => {
                return environment.endpoint.base.wasteFacility("admin", wasteFacilityId);
            },
            checkCompanyNumber: (companyNumber) => {
                return environment.endpoint.base.companyNumber("admin", companyNumber);
            },
        },
        broker: {
            carriers: (carrierId) => environment.endpoint.base.carriers("broker", carrierId),
            consignments: (consignmentId, action) => environment.endpoint.base.consignments("broker", consignmentId, action),
            consignmentNotes: (jobId) => environment.endpoint.base.consignmentNotes("broker", jobId),
            consignmentQuotes: (consignmentId, quoteId, action) => environment.endpoint.base.consignmentQuotes("broker", consignmentId, quoteId, action),
            consignmentWaste: (consignmentId, wasteId) => environment.endpoint.base.consignmentWaste("broker", consignmentId, wasteId),
            jobs: (jobId, action) => environment.endpoint.base.jobs("broker", jobId, action),
            quotes: (status) => environment.endpoint.base.quotes("broker", status),
            quoteConsignment: (quoteId, consignmentId) => environment.endpoint.base.quoteConsignment("broker", quoteId, consignmentId),
            vehicles: (vehicleId) => environment.endpoint.base.vehicles("broker", vehicleId),
            wasteFacilities: (wasteFacilityId) => environment.endpoint.base.wasteFacilities("broker", wasteFacilityId),
            wasteTransferNotes: (jobId) => environment.endpoint.base.wasteTransferNotes("brokerWasteTransferNotes", jobId),
            dangerousGoodsNote: (jobId) => environment.endpoint.base.dangerousGoodsNote("brokerDangerousGoodsNote", jobId),
        },
        carrier: {
            carriers: (carrierId) => environment.endpoint.base.carriers("carrier", carrierId),
            consignments: (consignmentId, action) => environment.endpoint.base.consignments("carrier", consignmentId, action),
            inviteWasteProducers: () => envParams.endpoints["carrier"] + "invite-waste-producer",
            consignmentNotes: (jobId, section, field) => environment.endpoint.base.consignmentNotes("carrier", jobId, section, field),
            consignmentQuotes: (consignmentId, quoteId, action) => environment.endpoint.base.consignmentQuotes("carrier", consignmentId, quoteId, action),
            consignmentWaste: (consignmentId, wasteId) => environment.endpoint.base.consignmentWaste("carrier", consignmentId, wasteId),
            jobs: (jobId, action) => environment.endpoint.base.jobs("carrier", jobId, action),
            quotes: (status) => environment.endpoint.base.quotes("carrier", status),
            quoteConsignment: (quoteId, consignmentId) => environment.endpoint.base.quoteConsignment("carrier", quoteId, consignmentId),
            vehicles: (vehicleId) => environment.endpoint.base.vehicles("carrier", vehicleId),
            wasteFacilities: (wasteFacilityId) => environment.endpoint.base.wasteFacilities("carrier", wasteFacilityId),
            wasteTransferNotes: (jobId) => environment.endpoint.base.wasteTransferNotes("carrierWasteTransferNotes", jobId),
            dangerousGoodsNote: (jobId) => environment.endpoint.base.dangerousGoodsNote("carrierDangerousGoodsNote", jobId),
        },
        user: {
            user: () => envParams.endpoints["user"] + "user/me",
            organisations: (organisationId) => environment.endpoint.base.organisations("user", "me"),
            getWasteFacility: (organisationId) => {
                return environment.endpoint.base.wasteFacility("user", organisationId);
            },
        },
        base: {
            organisations: (role, organisationId) => {
                let _endpoint = envParams.endpoints[role] + "organisations";
                !!organisationId && (_endpoint += "/" + organisationId);
                return _endpoint;
            },
            userOrganisations: (role, userId) => {
                let _endpoint = envParams.endpoints[role] + "user-organisations";
                !!userId && (_endpoint += "/" + userId);
                return _endpoint;
            },
            companiesHouse: (role, companyNumber) => {
                let _endpoint = envParams.endpoints[role] + "companies-house";
                !!companyNumber && (_endpoint += "/" + companyNumber);
                return _endpoint;
            },
            environmentAgency: (role, permitType, permitNumber) => {
                let _endpoint = `${envParams.endpoints[role]}${permitType}`;
                !!permitNumber && (_endpoint += `/${permitNumber}`);
                return _endpoint;
            },
            reports: (role, month) => {
                let _endpoint = envParams.endpoints[role] + "reports";
                !!month && (_endpoint += "/" + month);
                return _endpoint;
            },
            carriers: (role, carrierId) => {
                let _endpoint = envParams.endpoints[role] + "carriers";
                !!carrierId && (_endpoint += "/" + carrierId);
                return _endpoint;
            },
            consignments: (role, consignmentId, action) => {
                let _endpoint = envParams.endpoints[role] + "consignments";
                !!consignmentId && (_endpoint += "/" + consignmentId);
                !!action && (_endpoint += "/" + action);
                return _endpoint;
            },
            consignmentNotes: (role, jobId, section, field) => {
                let _endpoint = envParams.endpoints[role + "ConsignmentNotes"] + "consignment-notes";
                !!jobId && (_endpoint += "/" + jobId);
                !!section && (_endpoint += "/" + section);
                !!field && (_endpoint += "/" + field);
                return _endpoint;
            },
            consignmentQuotes: (role, consignmentId, quoteId, action) => {
                let _endpoint = environment.endpoint.base.consignments(role, consignmentId) +
                    "/quotes";
                !!quoteId && (_endpoint += "/" + quoteId);
                !!action && (_endpoint += "/" + action);
                return _endpoint;
            },
            consignmentWaste: (role, consignmentId, wasteId) => {
                let _endpoint = environment.endpoint.base.consignments(role, consignmentId) +
                    "/waste";
                !!wasteId && (_endpoint += "/" + wasteId);
                return _endpoint;
            },
            jobs: (role, jobId, action) => {
                let _endpoint = envParams.endpoints[role] + "jobs";
                !!jobId && (_endpoint += "/" + jobId);
                !!action && (_endpoint += "/" + action);
                return _endpoint;
            },
            quotes: (role, status) => {
                let _endpoint = envParams.endpoints[role] + "quotes";
                !!status && (_endpoint += "/" + status);
                return _endpoint;
            },
            quoteConsignment: (role, quoteId, consignmentId) => {
                return (environment.endpoint.base.quotes(role, quoteId) +
                    "/consignments" +
                    "/" +
                    consignmentId);
            },
            vehicles: (role, vehicleId) => {
                let _endpoint = envParams.endpoints[role] + "vehicles";
                !!vehicleId && (_endpoint += "/" + vehicleId);
                return _endpoint;
            },
            wasteFacilities: (role, wasteFacilityId) => {
                let _endpoint = envParams.endpoints[role] + "waste-facilities";
                !!wasteFacilityId && (_endpoint += "/" + wasteFacilityId);
                return _endpoint;
            },
            wasteFacility: (role, wasteFacilityId) => {
                let _endpoint = envParams.endpoints[role] + "waste-facility";
                !!wasteFacilityId && (_endpoint += "/" + wasteFacilityId);
                return _endpoint;
            },
            companyNumber: (role, companyNumber) => {
                let _endpoint = envParams.endpoints[role] + "check-org/" + companyNumber;
                return _endpoint;
            },
            checkout: (role) => {
                let _endpoint = envParams.endpoints[role] + "create-checkout-session";
                return _endpoint;
            },
            plans: (role) => {
                let _endpoint = envParams.endpoints[role] + "get-checkout-products";
                return _endpoint;
            },
            prices: (role) => {
                let _endpoint = envParams.endpoints[role] + "get-checkout-prices";
                return _endpoint;
            },
            wasteTransferNotes: (role, jobId) => {
                let _endpoint = envParams.endpoints[role] + "waste-transfer-notes/" + jobId;
                return _endpoint;
            },
            dangerousGoodsNote: (role, jobId) => {
                let _endpoint = `${envParams.endpoints[role]}dangerous-goods-note/${jobId}`;
                return _endpoint;
            },
        },
    },
    googleApiKey: envParams.googleApiKey,
};
