import { __awaiter, __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { AuthService } from "../../modules/user/services/auth";
import { HttpClient } from "@angular/common/http";
import { environment } from "../constant/environment";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
// import {LocalStorageService} from 'angular-2-local-storage';
import "rxjs";
const UNORTHORIZED = 401;
const FORBIDDEN = 403;
const ERROR = 500;
let Api = class Api {
    constructor(
    // private LocalStorageService: LocalStorageService,
    AuthService, Http) {
        this.AuthService = AuthService;
        this.Http = Http;
    }
    request(endpoint, method = "GET", params) {
        return this.AuthService.getAuthToken().then(() => __awaiter(this, void 0, void 0, function* () {
            const options = yield this.httpOptions(method, Object.assign({}, params));
            const url = endpoint;
            return yield this.Http.request(method, url, options).toPromise();
        }));
    }
    httpOptions(method, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const optionKey = method === "GET" ? "params" : "body";
            const jwtToken = yield this.AuthService.getAuthToken();
            const options = Object.keys(params).reduce((response, key) => {
                if (params[key] !== null) {
                    response[key] = params[key];
                }
                return response;
            }, {});
            return {
                headers: { Authorization: `Bearer ${jwtToken}` },
                [optionKey]: options,
            };
        });
    }
    s3PutObject(endpoint, putObject) {
        return new Promise((resolve, reject) => {
            try {
                this.Http.put(endpoint, putObject)
                    .pipe(map((Response) => {
                    if (Response.status >= 200 && Response.status < 300) {
                        // If everything went fine, return the response
                        return true;
                    }
                    reject("An error occurred.");
                }))
                    .pipe(catchError((Response) => {
                    let message = "An error occurred";
                    if (UNORTHORIZED == Response.status) {
                        message = "Unauthorised: Invalid user credentials.";
                    }
                    if (FORBIDDEN == Response.status) {
                        message = "Forbidden: You do not have sufficient privileges.";
                    }
                    if (ERROR == Response.status) {
                        message = new Error(Response.statusText).toString();
                    }
                    return throwError(() => message);
                }))
                    .subscribe(() => resolve(), (error) => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    getOrganisation() {
        return new Promise((resolve, reject) => {
            try {
                let endpoint = environment.endpoint.user.organisations("me");
                this.request(endpoint).then((Organisation) => resolve(Organisation), (error) => reject(new Error(error)));
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
};
Api = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [AuthService,
        HttpClient])
], Api);
export { Api };
