var ConsignmentFormFactory_1;
import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AddressFormFactory } from './address';
let ConsignmentFormFactory = ConsignmentFormFactory_1 = class ConsignmentFormFactory {
    static form(Consignment) {
        let _FormBuilder = new FormBuilder;
        let FormGroup = _FormBuilder.group({
            consignmentId: [null],
            brokerId: [null],
            consignmentNoteCode: [
                null,
                [Validators.required, Validators.pattern(/^.{10,}$/)],
            ],
            description: [
                null,
                [Validators.required, Validators.pattern(/^.{0,60}$/)],
            ],
            wasteRemovedFromAddress: [
                null,
                [Validators.required, AddressFormFactory.validateAddress],
            ],
            wasteProducerAddress: _FormBuilder.group({
                wasteFacilityId: [null],
                name: [null, [Validators.required]],
                address: [null, [Validators.required]],
                postcode: [
                    null,
                    [Validators.required, AddressFormFactory.validatePostCode],
                ],
                latitude: [null, [Validators.required]],
                longitude: [null, [Validators.required]],
                email: [null],
                mobile: [null],
            }),
            removedFromAndProducerAddressTheSame: [null],
            wasteDescriptionProcess: [null, [Validators.required]],
            wasteDescriptionSic2007: [
                {},
                [Validators.required, ConsignmentFormFactory_1.sicCodeValid],
            ],
            municipalSource: [null, null],
            consignmentNoteNumber: [null, null],
        });
        !!Consignment && Object.keys(Consignment).forEach(name => {
            if (FormGroup.controls[name]) {
                FormGroup.controls[name].patchValue(Consignment[name]);
            }
        });
        return FormGroup;
    }
    static sicCodeValid(control) {
        let valid = (control.value instanceof Object);
        !!valid && (valid = !!control.value.code && !!control.value.description);
        return valid ? null : {
            validCode: {
                valid: false,
            },
        };
    }
};
ConsignmentFormFactory = ConsignmentFormFactory_1 = __decorate([
    Injectable()
], ConsignmentFormFactory);
export { ConsignmentFormFactory };
