import { __decorate, __metadata } from "tslib";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../constant/environment";
let CompaniesHouseService = class CompaniesHouseService {
    constructor(Http) {
        this.Http = Http;
    }
    getCompanyInfo(companyNumber) {
        let endpoint = this.getEndpoint(companyNumber);
        return this.Http.get(endpoint).toPromise();
    }
    getEndpoint(companyNumber) {
        return environment.endpoint.admin.companiesHouse(companyNumber);
    }
};
CompaniesHouseService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient])
], CompaniesHouseService);
export { CompaniesHouseService };
