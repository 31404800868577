import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../services/user-api';
import { AuthService } from "../../services/auth";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangePasswordModal } from '../password/change/component';
import { InviteWasteProducers } from "../../../carrier/components/consignments/list/invite-waste-producers/component";
import { EditUserModal } from "../../../admin/components/manage-users/edit-user/component";
let UserMenu = class UserMenu {
    constructor(dialog, UserService, AuthService) {
        this.dialog = dialog;
        this.UserService = UserService;
        this.AuthService = AuthService;
        this.close = new EventEmitter();
    }
    profile() {
        return __awaiter(this, void 0, void 0, function* () {
            let config = new MatDialogConfig;
            const user = yield this.UserService.getUserDetail();
            config.disableClose = true;
            config.data = { user, editProfile: true };
            this.dialog
                .open(EditUserModal, config)
                .afterClosed();
        });
    }
    loggedIn() {
        return this.UserService.isLoggedIn();
    }
    logout() {
        this.UserService.isLoggedIn() && this.UserService.logout();
    }
    changePassword() {
        let config = new MatDialogConfig;
        config.disableClose = true;
        config.data = true;
        this.dialog
            .open(ChangePasswordModal, config)
            .afterClosed()
            .subscribe((ChangePassword) => {
            if (!ChangePassword.closed) {
                this.UserService
                    .changePassword(ChangePassword)
                    .then(result => {
                    alert('Password has been changed Successfully. Please click OK and continue.');
                    this.logout();
                }, error => alert('Could not change password.'));
            }
        });
    }
    openInviteWasteProducersDialog() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        this.dialog.open(InviteWasteProducers, config);
    }
};
__decorate([
    Output(),
    __metadata("design:type", Object)
], UserMenu.prototype, "close", void 0);
UserMenu = __decorate([
    Component({
        selector: 'user-menu',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [MatDialog,
        UserService,
        AuthService])
], UserMenu);
export { UserMenu };
