import { __decorate, __metadata } from "tslib";
import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PreferredRecyclersApi } from "../../services/preferred-recyclers-api";
import { UserService } from "../../../user/services/user-api";
import { InviteRecyclers } from "./invite-recyclers/component";
let PreferredRecyclers = class PreferredRecyclers {
    constructor(preferredRecyclersApi, userService, dialog) {
        this.preferredRecyclersApi = preferredRecyclersApi;
        this.userService = userService;
        this.dialog = dialog;
        this.dataSource = new MatTableDataSource([]);
        this._organisations = new BehaviorSubject([]);
        this.loading = true;
        this.displayedColumns = ["name", "companyType", "edit"];
    }
    ngOnInit() {
        this.organisation = this.userService.getOrganisation();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this._organisations.subscribe((items) => (this.dataSource.data = items));
        this.load();
    }
    load() {
        this.loading = true;
        this.preferredRecyclersApi
            .list(this.organisation.organisationId)
            .then((organisations) => {
            this.loading = false;
            this._organisations.next(organisations);
        });
    }
    applyFilter(event) {
        const searchValue = event.target.value;
        this.dataSource.filter = searchValue.trim().toLowerCase();
    }
    setAsPreferred(organisationId) {
        this.preferredRecyclersApi
            .insert({
            carrierId: organisationId,
            brokerId: this.organisation.organisationId,
            name: this.organisation.name,
        })
            .then(() => {
            this.load();
        });
    }
    removeFromPreferred(organisationId) {
        this.preferredRecyclersApi
            .delete({
            carrierId: organisationId,
            brokerId: this.organisation.organisationId,
        })
            .then(() => {
            this.load();
        });
    }
    openDialog() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        this.dialog.open(InviteRecyclers, config);
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], PreferredRecyclers.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], PreferredRecyclers.prototype, "paginator", void 0);
PreferredRecyclers = __decorate([
    Component({
        selector: "preferred-recyclers",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [PreferredRecyclersApi,
        UserService,
        MatDialog])
], PreferredRecyclers);
export { PreferredRecyclers };
