var PasswordNewFormFactory_1;
import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let PasswordNewFormFactory = PasswordNewFormFactory_1 = class PasswordNewFormFactory {
    static form() {
        return (new FormBuilder).group({
            password: [null, Validators.required],
            confirmPassword: [null, Validators.required],
        }, { validator: PasswordNewFormFactory_1.passwordsMatch });
    }
    static passwordsMatch(group) {
        let match = (group.controls['password'].value == group.controls['confirmPassword'].value);
        return (match) ? null : { areEqual: 'Passwords do not match' };
    }
};
PasswordNewFormFactory = PasswordNewFormFactory_1 = __decorate([
    Injectable()
], PasswordNewFormFactory);
export { PasswordNewFormFactory };
