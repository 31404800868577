import { __decorate, __metadata } from "tslib";
import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
let LoginModal = class LoginModal {
    constructor(dialogRef) {
        this.dialogRef = dialogRef;
    }
    handleLogin(loggedIn) {
        this.dialogRef.close(loggedIn);
    }
};
LoginModal = __decorate([
    Component({
        selector: 'user-login-modal',
        template: require('./component.html'),
        styles: [require('./component.scss')]
    }),
    __metadata("design:paramtypes", [MatDialogRef])
], LoginModal);
export { LoginModal };
