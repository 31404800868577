import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressFormFactory } from '../../../../factories/address';
let EditCompanyAddress = class EditCompanyAddress {
    constructor(dialogRef, address) {
        this.dialogRef = dialogRef;
        this.address = address;
        this.form = AddressFormFactory.form();
    }
    ngOnInit() {
        !!this.address && this.reset();
    }
    onSubmit() {
        let data = this.form.dirty ? this.form.value : null;
        this.dialogRef.close(data);
    }
    reset() {
        this.form.reset();
        !!this.address && Object.keys(this.address).map(key => {
            this.form.get(key).setValue(this.address[key]);
        });
    }
};
EditCompanyAddress = __decorate([
    Component({
        selector: 'edit-notification-details-address',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], EditCompanyAddress);
export { EditCompanyAddress };
