import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Router, } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UserService } from "./user-api";
import { LoginModal } from "../components/login/modal/component";
import { SiteRegistrationService } from "../../../app/services/v2/site-registration.service";
let AuthGuard = class AuthGuard {
    constructor(Router, UserService, dialog, siteRegistrationApi) {
        this.Router = Router;
        this.UserService = UserService;
        this.dialog = dialog;
        this.siteRegistrationApi = siteRegistrationApi;
    }
    canActivate(route, state) {
        return new Observable((observer) => {
            this.UserService.isLoggedIn()
                ? this.checkRegistrationIsCompleted(observer)
                : this.UserService.logInFromStorage()
                    .then(() => {
                    this.checkRegistrationIsCompleted(observer);
                })
                    .then(() => observer.next(true), () => this.loginModal(observer));
        });
    }
    checkRegistrationIsCompleted(observer) {
        const organisation = this.UserService.getOrganisation();
        if (organisation === null) {
            this.Router.navigate(["complete-registration"]);
            observer.next(true);
        }
        else {
            this.siteRegistrationApi
                .getWasteFacility(organisation.organisationId)
                .then((wasteFacilities) => {
                if (!wasteFacilities || wasteFacilities.length === 0
                    || (!organisation.subscriptionId && organisation.companyType !== "producer")) {
                    this.Router.navigate(["complete-registration"]);
                }
            })
                .then(() => observer.next(true));
        }
    }
    loginModal(observer) {
        // if not show login box
        let dialogRef = this.dialog.open(LoginModal);
        dialogRef.afterClosed().subscribe((loggedIn) => {
            !loggedIn && this.Router.navigate(["/"]);
            observer.next(loggedIn);
        });
    }
};
AuthGuard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Router,
        UserService,
        MatDialog,
        SiteRegistrationService])
], AuthGuard);
export { AuthGuard };
