import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { Loader } from "@googlemaps/js-api-loader";
import { envParams } from "../../../app/environment/envParams";
let AddressByLoader = class AddressByLoader {
    constructor(rootFormGroup) {
        this.rootFormGroup = rootFormGroup;
        this.formName = "";
        this.setAddress = new EventEmitter();
    }
    ngOnInit() {
        this.formGroup = this.formName ?
            this.rootFormGroup.control.get(this.formName) :
            this.rootFormGroup.control;
    }
    ngAfterContentInit() {
        this.initAutocomplete();
    }
    initAutocomplete() {
        this.loader = new Loader({
            apiKey: envParams.googleApiKey,
            version: "weekly",
            libraries: ["places"],
        });
        this.loader.load().then((google) => __awaiter(this, void 0, void 0, function* () {
            this.autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement, {
                componentRestrictions: { 'country': [] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });
            this.autocomplete.addListener("place_changed", () => {
                this.place = this.autocomplete.getPlace();
                const postcode = this.getAddressComponent("postal_code");
                const number = this.getAddressComponent("street_number");
                const route = this.getAddressComponent("route");
                const sublocality = this.getAddressComponent("postal_town");
                const administrativeAreaLevel2 = this.getAddressComponent("administrative_area_level_2");
                const administrativeAreaLevel1 = this.getAddressComponent("administrative_area_level_1");
                const country = this.getAddressComponent("country");
                const locality = this.getAddressComponent("locality");
                const selectedAddress = `${number} ${route} ${sublocality} ${locality} ${administrativeAreaLevel2} ${administrativeAreaLevel1} ${country}`;
                var addressData = {
                    address: selectedAddress,
                    postcode: postcode,
                    latitude: this.place.geometry.location.lat(),
                    longitude: this.place.geometry.location.lng()
                };
                this.setAddress.emit(addressData);
            });
        }));
    }
    getAddressComponent(feild) {
        var _a;
        return (((_a = this.place.address_components.find((item) => {
            return item.types.includes(feild);
        })) === null || _a === void 0 ? void 0 : _a.long_name) || "");
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], AddressByLoader.prototype, "formName", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], AddressByLoader.prototype, "setAddress", void 0);
__decorate([
    ViewChild("address"),
    __metadata("design:type", Object)
], AddressByLoader.prototype, "address", void 0);
AddressByLoader = __decorate([
    Component({
        selector: "address-by-loader",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [FormGroupDirective])
], AddressByLoader);
export { AddressByLoader };
