import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let VehicleFormFactory = class VehicleFormFactory {
    static form(Waste) {
        let _FormBuilder = new FormBuilder;
        let FormGroup = _FormBuilder.group({
            organisationId: [null],
            vehicleId: [null],
            vehicleRegistration: [null, [Validators.required]],
            pinCode: [null, [Validators.required]],
            notes: [null]
        });
        !!Waste && FormGroup.setValue(Waste);
        return FormGroup;
    }
};
VehicleFormFactory = __decorate([
    Injectable()
], VehicleFormFactory);
export { VehicleFormFactory };
