import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteNotification = class ConsignmentNoteNotification extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "notificationDetails";
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.NotificationDetails = !!consignmentNote
                ? consignmentNote.notificationDetails
                : null;
        });
    }
};
ConsignmentNoteNotification = __decorate([
    Component({
        selector: "consignment-note-notification",
        template: require('../../../../directives/view-consignment-note/notification.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    })
], ConsignmentNoteNotification);
export { ConsignmentNoteNotification };
