import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NewSiteModalComponent } from "../register-site/new-site-modal/new-site-modal.component";
import { FormGroupDirective } from "@angular/forms";
import { SiteRegistrationService } from "../../../../../../app/services/v2/site-registration.service";
import { BehaviorSubject } from "rxjs";
import { UserService } from "../../../../services/user-api";
let SiteInfo = class SiteInfo {
    constructor(dialog, siteRegistrationApi, UserService, rootFormGroup) {
        this.dialog = dialog;
        this.siteRegistrationApi = siteRegistrationApi;
        this.UserService = UserService;
        this.rootFormGroup = rootFormGroup;
        this.wasteFacilities = new BehaviorSubject([]);
        this.wasteFacility = [];
    }
    ngOnInit() {
        this.subscribe = this.wasteFacilities.subscribe((items) => (this.wasteFacility = items));
        this.form = this.rootFormGroup.control.get(this.formGroupName);
        this.UserService.setOrganisation().then(({ organisationId, registeredOfficeAddress }) => {
            this.organisationId = organisationId;
            this.organisationAddress = registeredOfficeAddress;
            this.loadSites();
        });
    }
    addSite() {
        let config = new MatDialogConfig();
        config.data = {
            address: this.organisationAddress,
            companyType: this.companyType,
            form: this.form,
        };
        config.disableClose = true;
        let dialog = this.dialog.open(NewSiteModalComponent, config);
        dialog.afterClosed().subscribe(() => {
            this.loadSites();
        });
    }
    loadSites() {
        this.siteRegistrationApi
            .getWasteFacility(this.organisationId)
            .then((wasteFacility) => {
            this.wasteFacilities.next(wasteFacility);
        });
    }
    remove(wasteFacilityId) {
        this.siteRegistrationApi
            .deleteWasteFacility(wasteFacilityId)
            .then(() => this.loadSites());
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SiteInfo.prototype, "formGroupName", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SiteInfo.prototype, "companyType", void 0);
SiteInfo = __decorate([
    Component({
        selector: "app-site-list",
        template: require('./site-list.component.html'),
        styles: [require('./site-list.component.scss')],
    }),
    __metadata("design:paramtypes", [MatDialog,
        SiteRegistrationService,
        UserService,
        FormGroupDirective])
], SiteInfo);
export { SiteInfo };
