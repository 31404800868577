import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
let InvoiceApi = class InvoiceApi {
    constructor(Api) {
        this.Api = Api;
        this.endpoint = environment.endpoint.admin.invoice();
    }
    get() {
        return this.Api.request(this.endpoint, "GET");
    }
};
InvoiceApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], InvoiceApi);
export { InvoiceApi };
