import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let ConsigneeSummary = class ConsigneeSummary {
    constructor() {
    }
    ngOnInit() {
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ConsigneeSummary.prototype, "DropOff", void 0);
ConsigneeSummary = __decorate([
    Component({
        selector: 'consignee-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [])
], ConsigneeSummary);
export { ConsigneeSummary };
