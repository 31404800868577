import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from "../../../app/constant/environment";
// import {RequestOptions} from "@angular/http";
import { Api } from "../../../app/services/api";
let BrokerCarrierApi = class BrokerCarrierApi {
    constructor(Api) {
        this.Api = Api;
    }
    list() {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, 'GET');
    }
    getEndpoint() {
        return environment.endpoint.broker.carriers();
    }
};
BrokerCarrierApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], BrokerCarrierApi);
export { BrokerCarrierApi };
