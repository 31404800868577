import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Api } from './api';
let ReportApi = class ReportApi {
    constructor(Api) {
        this.Api = Api;
    }
    list(month) {
        let endpoint = this.getEndpoint(month);
        return this.Api.request(endpoint);
    }
    get(organisationId) {
        let endpoint = this.getEndpoint(organisationId);
        return this.Api.request(endpoint, "GET");
    }
    save(Organisation) {
        return !!Organisation.organisationId ? this.put(Organisation.organisationId, Organisation) : this.post(Organisation);
    }
    remove(organisationId) {
        let endpoint = this.getEndpoint(organisationId);
        return this.Api.request(endpoint, "DELETE");
    }
    post(Organisation) {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, "POST", Organisation);
    }
    put(organisationId, Organisation) {
        let endpoint = this.getEndpoint(organisationId);
        return this.Api.request(endpoint, "PUT", Organisation);
    }
};
ReportApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], ReportApi);
export { ReportApi };
