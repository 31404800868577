import { __decorate, __metadata } from "tslib";
import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Dashboard } from "../../../services/dashboard";
import { EditConsignment } from "../../../../broker/components/consignments/edit/component";
import { AllocateJob } from "../../jobs/allocate/component";
import { CarrierListWaste } from "../../waste/list/component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { RepeatConsignment } from "../repeat/component";
import { InviteWasteProducers } from "./invite-waste-producers/component";
let ListCarrierConsignments = class ListCarrierConsignments {
    constructor(Dashboard, dialog) {
        this.Dashboard = Dashboard;
        this.dialog = dialog;
        this.loading = false;
        this.carrierListDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "code",
            "collectFrom",
            "description",
            "add",
        ];
    }
    ngOnInit() {
        this.setDataSourceAttributes();
        this.Dashboard._list.subscribe((list) => (this.carrierListDataSource.data = list.consignments));
        this.Dashboard._loading.subscribe((loading) => (this.loading = loading));
        this.Dashboard.load();
    }
    repeat(consignmentId) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = consignmentId;
        let dialogRef = this.dialog.open(RepeatConsignment, config);
        dialogRef
            .afterClosed()
            .subscribe((Consignment) => {
            !!Consignment && this.save(Consignment);
            this.Dashboard.load();
        });
    }
    edit(consignmentId) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = consignmentId;
        let dialogRef = this.dialog.open(EditConsignment, config);
        dialogRef
            .afterClosed()
            .subscribe((Consignment) => !!Consignment && this.save(Consignment));
    }
    remove(consignmentId) {
        confirm("Are you sure you want to delete Consignment?") &&
            this.Dashboard.removeConsignment(consignmentId).then(() => this.refresh());
    }
    allocate(Consignment) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = Consignment;
        let dialog = this.dialog.open(AllocateJob, config);
        dialog.afterClosed().subscribe(() => this.refresh());
    }
    listWaste(Consignment) {
        let config = new MatDialogConfig();
        config.data = Consignment;
        config.disableClose = true;
        let dialogRef = this.dialog.open(CarrierListWaste, config);
        dialogRef.afterClosed().subscribe(() => this.refresh());
    }
    refresh() {
        this.Dashboard.load();
    }
    applyCarrierListFilter(event) {
        const searchValue = event.target.value;
        this.carrierListDataSource.filter = searchValue.trim().toLowerCase();
    }
    save(Consignment) {
        this.Dashboard.saveConsignment(Consignment).then((Consignment) => this.listWaste(Consignment));
    }
    setDataSourceAttributes() {
        const { filterPredicate } = this.carrierListDataSource;
        this.carrierListDataSource.filterPredicate = (carrierList, filter) => {
            const searchableData = Object.assign(Object.assign({}, carrierList), carrierList.wasteRemovedFromAddress);
            return filterPredicate(searchableData, filter);
        };
        this.carrierListDataSource.sort = this.sort;
        this.carrierListDataSource.paginator = this.paginator;
    }
    openInviteWasteProducersDialog() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        this.dialog.open(InviteWasteProducers, config);
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], ListCarrierConsignments.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], ListCarrierConsignments.prototype, "paginator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListCarrierConsignments.prototype, "title", void 0);
ListCarrierConsignments = __decorate([
    Component({
        selector: "list-carrier-consignments",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [Dashboard, MatDialog])
], ListCarrierConsignments);
export { ListCarrierConsignments };
