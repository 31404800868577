import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let CollectionSummary = class CollectionSummary {
    constructor() {
    }
    ngOnInit() {
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], CollectionSummary.prototype, "Collection", void 0);
CollectionSummary = __decorate([
    Component({
        selector: 'collection-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [])
], CollectionSummary);
export { CollectionSummary };
