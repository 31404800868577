import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
import { BehaviorSubject } from "rxjs";
let WasteFacilityApi = class WasteFacilityApi {
    constructor(Api) {
        this.Api = Api;
        this.wasteFacilities = new BehaviorSubject([]);
        this.wasteFacility$ = this.wasteFacilities.asObservable();
        this.loading = new BehaviorSubject(true);
    }
    initialise() {
        this.loadWasteFacilities();
    }
    loadWasteFacilities() {
        this.list().then((wasteFacility) => {
            this.wasteFacilities.next(wasteFacility);
            this.loading.next(false);
        });
    }
    list() {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint);
    }
    get(wasteFacilityId) {
        let endpoint = this.getEndpoint(wasteFacilityId);
        return this.Api.request(endpoint);
    }
    remove(wasteFacilityId) {
        let endpoint = this.getEndpoint(wasteFacilityId);
        return this.Api.request(endpoint, "DELETE");
    }
    save(WasteFacility) {
        return !!WasteFacility.wasteFacilityId
            ? this.put(WasteFacility)
            : this.post(WasteFacility);
    }
    put(WasteFacility) {
        let endpoint = this.getEndpoint(WasteFacility.wasteFacilityId);
        return this.Api.request(endpoint, "PUT", WasteFacility);
    }
    post(WasteFacility) {
        let endpoint = this.getEndpoint(WasteFacility.wasteFacilityId);
        return this.Api.request(endpoint, "POST", WasteFacility);
    }
    getEndpoint(wasteFacilityId) {
        return environment.endpoint.carrier.wasteFacilities(wasteFacilityId);
    }
};
WasteFacilityApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], WasteFacilityApi);
export { WasteFacilityApi };
