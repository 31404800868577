import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { InviteWasteProducersApi } from "../../../../services/invite-waste-producers-api";
let InviteWasteProducers = class InviteWasteProducers {
    constructor(InviteWasteProducersApi, dialogRef) {
        this.InviteWasteProducersApi = InviteWasteProducersApi;
        this.dialogRef = dialogRef;
    }
    ngOnInit() {
        let formBuilder = new FormBuilder();
        this.form = formBuilder.group({ email: [null, [Validators.required]] });
    }
    onSubmit() {
        this.loading = true;
        this.InviteWasteProducersApi.inviteWasteProducers(this.form.getRawValue())
            .then(() => {
            this.dialogRef.close();
        }).catch(() => {
            this.dialogRef.close();
        });
    }
};
InviteWasteProducers = __decorate([
    Component({
        selector: "invite-recyclers",
        template: require('./component.html'),
        styles: [require('./component.scss')]
    }),
    __metadata("design:paramtypes", [InviteWasteProducersApi,
        MatDialogRef])
], InviteWasteProducers);
export { InviteWasteProducers };
