import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewWaste } from '../../../../../app/components/waste/view/component';
import { BrokerWasteApi } from '../../../services/waste-api';
let BrokerViewWaste = class BrokerViewWaste extends ViewWaste {
    constructor(WasteApi, dialogRef, data) {
        super(WasteApi, dialogRef, data);
        this.WasteApi = WasteApi;
        this.dialogRef = dialogRef;
        this.data = data;
    }
};
BrokerViewWaste = __decorate([
    Component({
        selector: 'broker-view-waste',
        template: require('../../../../../app/components/waste/view/component.html'),
        styles: [require('../../../../../app/components/waste/view/component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [BrokerWasteApi,
        MatDialogRef, Object])
], BrokerViewWaste);
export { BrokerViewWaste };
