import { __decorate, __metadata } from "tslib";
import { Component, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EditVehicle } from "../edit/component";
import { VehicleApi } from "../../../services/vehicle-api";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
let ListVehicles = class ListVehicles {
    constructor(VehicleApi, dialog) {
        this.VehicleApi = VehicleApi;
        this.dialog = dialog;
        this.loading = true;
        this.vehicleDataSource = new MatTableDataSource([]);
        this.displayedColumns = ["registration", "notes", "add"];
        this._vehicles = new BehaviorSubject([]);
    }
    ngOnInit() {
        this._vehicles.subscribe((items) => (this.vehicleDataSource.data = items));
        this.vehicleDataSource.sort = this.sort;
        this.vehicleDataSource.paginator = this.paginator;
        this.load();
    }
    load() {
        this.loading = true;
        this.VehicleApi.list().then((items) => {
            this.loading = false;
            this._vehicles.next(items);
        });
    }
    edit(vehicleId) {
        let config = new MatDialogConfig();
        config.data = vehicleId;
        config.disableClose = true;
        let dialogRef = this.dialog.open(EditVehicle, config);
        dialogRef.afterClosed().subscribe((result) => {
            !!result &&
                this.VehicleApi.save(result).then((Vehicle) => this.load());
        });
    }
    remove(vehicleId) {
        this.VehicleApi.remove(vehicleId).then(() => this.load());
    }
    applyVehicleFilter(event) {
        const searchValue = event.target.value;
        this.vehicleDataSource.filter = searchValue.trim().toLowerCase();
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], ListVehicles.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], ListVehicles.prototype, "paginator", void 0);
ListVehicles = __decorate([
    Component({
        selector: "list-vehicles",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [VehicleApi, MatDialog])
], ListVehicles);
export { ListVehicles };
