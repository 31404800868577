var AddressFormFactory_1;
import { __decorate } from "tslib";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
let AddressFormFactory = AddressFormFactory_1 = class AddressFormFactory {
    static form(Address) {
        let _FormBuilder = new FormBuilder();
        let FormGroup = _FormBuilder.group({
            name: [null, [Validators.required]],
            address: [null, [Validators.required]],
            postcode: [
                null,
                [Validators.required, AddressFormFactory_1.validatePostCode],
            ],
            latitude: [null, [Validators.required]],
            longitude: [null, [Validators.required]],
            telephone: [null],
            email: [null],
            mobile: [null],
            fax: [null],
        });
        !!Address && FormGroup.setValue(Address);
        return FormGroup;
    }
    static validatePostCode(control) {
        let postcode = /(GIR 0AA)|((([A-Za-z][\d]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][\d]{1,2})|(([A-Za-z][\d][A-Za-h])|([A-Za-z][A-Ha-hJ-Yj-y][\d]?[A-Za-z])))) ?(\w*)[\d][A-Za-z]{2})$/;
        const value = control.value || control;
        let valid = postcode.test(value);
        return valid
            ? null
            : {
                validPostCode: {
                    valid: false,
                },
            };
    }
    static validateAddress(control) {
        var _a, _b, _c;
        const address = ((_b = (_a = control.value) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address)
            ? (_c = control.value) === null || _c === void 0 ? void 0 : _c.address
            : control.value;
        let valid = (address === null || address === void 0 ? void 0 : address.name) &&
            (address === null || address === void 0 ? void 0 : address.address) &&
            (address === null || address === void 0 ? void 0 : address.postcode) &&
            (address === null || address === void 0 ? void 0 : address.latitude) &&
            (address === null || address === void 0 ? void 0 : address.longitude);
        return valid
            ? null
            : {
                validAddress: {
                    valid: false,
                },
            };
    }
};
AddressFormFactory = AddressFormFactory_1 = __decorate([
    Injectable()
], AddressFormFactory);
export { AddressFormFactory };
