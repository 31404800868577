import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
let SubscriptionApi = class SubscriptionApi {
    constructor(Api) {
        this.Api = Api;
    }
    retrive(subscriptionId) {
        let endpoint = environment.endpoint.admin.subscription(subscriptionId);
        return this.Api.request(endpoint, "GET");
    }
    update(params) {
        let endpoint = environment.endpoint.admin.subscription();
        return this.Api.request(endpoint, "PUT", params);
    }
    create(params) {
        let endpoint = environment.endpoint.admin.subscription();
        return this.Api.request(endpoint, "POST", params);
    }
};
SubscriptionApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], SubscriptionApi);
export { SubscriptionApi };
