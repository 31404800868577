export const Regions = [
    "East Midlands",
    "East of England",
    "North East",
    "North West",
    "Northern Ireland",
    "Outside UK",
    "Scotland",
    "South East",
    "London",
    "South West",
    "Wales",
    "West Midlands",
    "Yorks & Humber",
];
export const countyCouncils = [
    "Bedfordshire",
    "Buckinghamshire",
    "Cambridgeshire",
    "Central London",
    "Cheshire",
    "Cornwall",
    "Cumbria",
    "Derbyshire",
    "Devon",
    "Dorset",
    "East Sussex",
    "Essex",
    "Gloucestershire",
    "Greater London",
    "Hampshire",
    "Hertfordshire",
    "Kent",
    "Lancashire",
    "Leicestershire",
    "Lincolnshire",
    "Merseyside",
    "Norfolk",
    "North Yorkshire",
    "Northamptonshire",
    "Northumberland",
    "Nottinghamshire",
    "Oxfordshire",
    "Shropshire",
    "Somerset",
    "South London",
    "South Yorkshire",
    "Staffordshire",
    "Suffolk",
    "Surrey",
    "Warwickshire",
    "West Sussex",
    "Wiltshire",
    "Worcestershire",
];
export const districtCouncils = [
    "Outside UK",
    "Aberdeen City",
    "Aberdeenshire",
    "Adur",
    "Allerdale",
    "Amber Valley",
    "Angus",
    "Antrim",
    "Ards",
    "Argyll and Bute UA Islands",
    "Argyll and Islands LEC Mainland",
    "Armagh",
    "Arun",
    "Ashfield",
    "Ashford",
    "Aylesbury Vale",
    "Ayrshire and Arran",
    "Babergh",
    "Badenoch and Strathspey",
    "Ballymena",
    "Ballymoney",
    "Banbridge",
    "Barking and Dagenham",
    "Barnet",
    "Barnsley",
    "Barrow-in-Furness",
    "Basildon",
    "Basingstoke and Deane",
    "Bassetlaw",
    "Bath and North East Somerset",
    "Bedford",
    "Belfast",
    "Berwick-upon-Tweed",
    "Bexley",
    "Birmingham",
    "Blaby",
    "Blackburn with Darwen",
    "Blackpool",
    "Blaenau Gwent",
    "Bolsover",
    "Bolton",
    "Boston",
    "Bournemouth",
    "Bracknell Forest",
    "Bradford",
    "Braintree",
    "Breckland",
    "Brent",
    "Brentwood",
    "Bridgend",
    "Brighton and Hove",
    "Bristol, City of",
    "Broadland",
    "Bromley",
    "Bromsgrove",
    "Broxbourne",
    "Broxtowe",
    "Buckinghamshire",
    "Burnley",
    "Bury",
    "Caerphilly",
    "Caithness and Sutherland",
    "Calderdale",
    "Cambridge",
    "Cambridgeshire",
    "Camden",
    "Cannock Chase",
    "Canterbury",
    "Cardiff",
    "Carlisle",
    "Carmarthenshire",
    "Carrickfergus",
    "Castle Point",
    "Castlereagh",
    "Central Bedfordshire",
    "Ceredigion",
    "Charnwood",
    "Chelmsford",
    "Cheltenham",
    "Cherwell",
    "Cheshire East",
    "Cheshire West and Chester",
    "Chesterfield",
    "Chichester",
    "Chiltern",
    "Chorley",
    "Christchurch",
    "City of London",
    "Clackmannanshire",
    "Colchester",
    "Coleraine",
    "Conwy",
    "Cookstown",
    "Copeland",
    "Corby",
    "Cornwall",
    "Cotswold",
    "County Durham",
    "Coventry",
    "Craigavon",
    "Craven",
    "Crawley",
    "Croydon",
    "Cumbria",
    "Dacorum",
    "Darlington",
    "Dartford",
    "Daventry",
    "Denbighshire",
    "Derby",
    "Derbyshire",
    "Derbyshire Dales",
    "Derry",
    "Devon",
    "Doncaster",
    "Dorset",
    "Dover",
    "Down",
    "Dudley",
    "Dumfries and Galloway",
    "Dundee City",
    "Dungannon",
    "Ealing",
    "Easington",
    "East Ayrshire",
    "East Cambridgeshire",
    "East Devon",
    "East Dorset",
    "East Dunbartonshire",
    "East Hampshire",
    "East Hertfordshire",
    "East Lindsey",
    "East Lothian",
    "East Midlands",
    "East Northamptonshire",
    "East of England",
    "East Renfrewshire",
    "East Riding of Yorkshire",
    "East Staffordshire",
    "East Sussex",
    "Eastbourne",
    "Eastleigh",
    "Eden",
    "Edinburgh, City of",
    "Eilean Siar (Western Isles)",
    "Elmbridge",
    "Enfield",
    "Epping Forest",
    "Epsom and Ewell",
    "Erewash",
    "Essex",
    "Exeter",
    "Falkirk",
    "Fareham",
    "Fenland",
    "Fermanagh",
    "Fife",
    "Flintshire",
    "Forest Heath",
    "Forest of Dean",
    "Fylde",
    "Gateshead",
    "Gedling",
    "Glasgow City",
    "Gloucester",
    "Gloucestershire",
    "Gosport",
    "Gravesham",
    "Great Yarmouth",
    "Greater London",
    "Greenwich",
    "Guildford",
    "Gwynedd",
    "Hackney",
    "Halton",
    "Hambleton",
    "Hammersmith and Fulham",
    "Hampshire",
    "Harborough",
    "Haringey",
    "Harlow",
    "Harrogate",
    "Harrow",
    "Hart",
    "Hartlepool",
    "Hastings",
    "Havant",
    "Havering",
    "Helensburgh and Lomond",
    "Herefordshire, The County of",
    "Hertfordshire",
    "Hertsmere",
    "High Peak",
    "Highland",
    "Hillingdon",
    "Hinckley and Bosworth",
    "Horsham",
    "Hounslow",
    "Huntingdonshire",
    "Hyndburn",
    "Inverclyde",
    "Inverness and Nairn",
    "Ipswich",
    "Isle of Anglesey",
    "Isle of Wight",
    "Isles of Scilly",
    "Islington",
    "Kensington and Chelsea",
    "Kent",
    "Kettering",
    "King's Lynn and West Norfolk",
    "Kingston upon Hull, City of",
    "Kingston upon Thames",
    "Kirklees",
    "Knowsley",
    "Lambeth",
    "Lancashire",
    "Lancaster",
    "Larne",
    "Leeds",
    "Leicester",
    "Leicestershire",
    "Lewes",
    "Lewisham",
    "Lichfield",
    "Limavady",
    "Lincoln",
    "Lincolnshire",
    "Lisburn",
    "Liverpool",
    "Lochaber",
    "London",
    "Luton",
    "Magherafelt",
    "Maidstone",
    "Maldon",
    "Malvern Hills",
    "Manchester",
    "Mansfield",
    "Medway",
    "Melton",
    "Mendip",
    "Merseyside",
    "Merthyr Tydfil",
    "Merton",
    "Mid Devon",
    "Mid Suffolk",
    "Mid Sussex",
    "Middlesbrough",
    "Midlothian",
    "Milton Keynes",
    "Mole Valley",
    "Monmouthshire",
    "Moray",
    "Moyle",
    "Neath Port Talbot",
    "New Forest",
    "Newark and Sherwood",
    "Newcastle upon Tyne",
    "Newcastle-under-Lyme",
    "Newham",
    "Newport",
    "Newry and Mourne",
    "Newtownabbey",
    "Norfolk",
    "North Ayrshire Mainland",
    "North Devon",
    "North Dorset",
    "North Down",
    "North East",
    "North East Derbyshire",
    "North East Lincolnshire",
    "North Hertfordshire",
    "North Kesteven",
    "North Lanarkshire",
    "North Lincolnshire",
    "North Norfolk",
    "North Somerset",
    "North Tyneside",
    "North Warwickshire",
    "North West",
    "North West Leicestershire",
    "North Yorkshire",
    "Northampton",
    "Northamptonshire",
    "Northern Ireland",
    "Northern Ireland",
    "Northumberland",
    "Northumberland",
    "Norwich",
    "Nottingham",
    "Nottinghamshire",
    "Nuneaton and Bedworth",
    "Oadby and Wigston",
    "Oldham",
    "Omagh",
    "Orkney Islands",
    "Oxford",
    "Oxfordshire",
    "Pembrokeshire",
    "Pendle",
    "Perth and Kinross",
    "Peterborough",
    "Plymouth",
    "Poole",
    "Portsmouth",
    "Powys",
    "Preston",
    "Purbeck",
    "Reading",
    "Redbridge",
    "Redcar and Cleveland",
    "Redditch",
    "Reigate and Banstead",
    "Renfrewshire",
    "Rhondda, Cynon, Taff",
    "Ribble Valley",
    "Richmond upon Thames",
    "Richmondshire",
    "Rochdale",
    "Rochford",
    "Ross and Cromarty",
    "Rossendale",
    "Rother",
    "Rotherham",
    "Rugby",
    "Runnymede",
    "Rushcliffe",
    "Rushmoor",
    "Rutland",
    "Ryedale",
    "Salford",
    "Sandwell",
    "Scarborough",
    "Scotland",
    "Scottish Borders",
    "Sedgemoor",
    "Sefton",
    "Selby",
    "Sevenoaks",
    "Sheffield",
    "Shepway",
    "Shetland Islands",
    "Shropshire",
    "Skye and Lochalsh",
    "Slough",
    "Solihull",
    "Somerset",
    "South Ayrshire",
    "South Buckinghamshire",
    "South Cambridgeshire",
    "South Derbyshire",
    "South East",
    "South Gloucestershire",
    "South Hams",
    "South Holland",
    "South Kesteven",
    "South Lakeland",
    "South Lanarkshire",
    "South Norfolk",
    "South Northamptonshire",
    "South Oxfordshire",
    "South Ribble",
    "South Somerset",
    "South Staffordshire",
    "South Tyneside",
    "South West",
    "South Yorkshire",
    "Southampton",
    "Southend-on-Sea",
    "Southwark",
    "Spelthorne",
    "St Albans",
    "St Edmundsbury",
    "St. Helens",
    "Stafford",
    "Staffordshire",
    "Staffordshire Moorlands",
    "Stevenage",
    "Stirling",
    "Stockport",
    "Stockton-on-Tees",
    "Stoke-on-Trent",
    "Strabane",
    "Stratford-on-Avon",
    "Stroud",
    "Suffolk",
    "Suffolk Coastal",
    "Sunderland",
    "Surrey",
    "Surrey Heath",
    "Sutton",
    "Swale",
    "Swansea",
    "Swindon",
    "Tameside",
    "Tamworth",
    "Tandridge",
    "Taunton Deane",
    "Teignbridge",
    "Telford and Wrekin",
    "Tendring",
    "Test Valley",
    "Tewkesbury",
    "Thanet",
    "Three Rivers",
    "Thurrock",
    "Tonbridge and Malling",
    "Torbay",
    "Torfaen",
    "Torridge",
    "Tower Hamlets",
    "Trafford",
    "Tunbridge Wells",
    "Uttlesford",
    "Vale of Glamorgan",
    "Vale of White Horse",
    "Wakefield",
    "Wales",
    "Walsall",
    "Waltham Forest",
    "Wandsworth",
    "Warrington",
    "Warwick",
    "Warwickshire",
    "Watford",
    "Waveney",
    "Waverley",
    "Wealden",
    "Wellingborough",
    "Welwyn Hatfield",
    "West Berkshire",
    "West Devon",
    "West Dorset",
    "West Dunbartonshire",
    "West Lancashire",
    "West Lindsey",
    "West Lothian",
    "West Midlands",
    "West Oxfordshire",
    "West Somerset",
    "West Yorkshire",
    "West Sussex",
    "Western Isles ",
    "Westminster",
    "Weymouth and Portland",
    "Wigan",
    "Wiltshire",
    "Winchester",
    "Windsor and Maidenhead",
    "Wirral",
    "Woking",
    "Wokingham",
    "Wolverhampton",
    "Worcester",
    "Worcestershire",
    "Worthing",
    "Wrexham",
    "Wychavon",
    "Wycombe",
    "Wyre",
    "Wyre Forest",
    "York",
];
