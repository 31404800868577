import { BehaviorSubject } from 'rxjs';
import { EwcCodeList } from '../constant/ewc-codes';
export class EwcCodeService {
    constructor(inputText) {
        this.inputText = inputText;
        this.EwcCodeList = EwcCodeList;
        this.filteredEwcCodes = new BehaviorSubject([]);
        this.inputText.subscribe((value) => this.filteredEwcCodes.next(this.filterEwcCodes(value)));
    }
    filterEwcCodes(value) {
        return !!value ? this.EwcCodeList.filter((EwcCode) => {
            let codeMatch = new RegExp(value, 'gi').test(String(EwcCode.code));
            let descriptionMatch = new RegExp(value, 'gi').test(EwcCode.description);
            return codeMatch || descriptionMatch;
        }) : [];
    }
}
