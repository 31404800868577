import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let RequestQuoteFactory = class RequestQuoteFactory {
    static form(RequestQuote) {
        let _FormBuilder = new FormBuilder();
        let FormGroup = _FormBuilder.group({
            'brokerName': [null, [Validators.required]],
            'Carrier': [null, [Validators.required]],
            'description': [null, [Validators.required]],
            'brokerNotes': [null],
            'wasteImage': [null, [Validators.required]],
        });
        !!RequestQuote && FormGroup.setValue(RequestQuote);
        return FormGroup;
    }
};
RequestQuoteFactory = __decorate([
    Injectable()
], RequestQuoteFactory);
export { RequestQuoteFactory };
