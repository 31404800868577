import { __decorate, __metadata } from "tslib";
import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NewSiteModalComponent } from "../../../../user/v2/components/company-registration/register-site/new-site-modal/new-site-modal.component";
import { WasteFacilityApi } from "../../../../carrier/services/waste-facility-api";
import { FormBuilder, Validators } from "@angular/forms";
let ManageWasteFacilitiesList = class ManageWasteFacilitiesList {
    constructor(WasteFacilitiesApi, dialog, fb) {
        this.WasteFacilitiesApi = WasteFacilitiesApi;
        this.dialog = dialog;
        this.fb = fb;
        this.wasteFacilitiesDataSource = new MatTableDataSource([]);
        this.displayedColumns = ["siteName", "add"];
        this.loading = true;
        this.subscriptions = [];
    }
    ngOnInit() {
        this.wasteFacilitiesDataSource.sort = this.sort;
        this.wasteFacilitiesDataSource.paginator = this.paginator;
        this.initialise();
    }
    initialise() {
        this.loading = true;
        this.WasteFacilitiesApi.loading.subscribe((data) => {
            this.loading = data;
        });
        this.subscriptions.push(this.WasteFacilitiesApi.wasteFacility$.subscribe((wasteFacility) => {
            this.wasteFacilitiesDataSource.data = wasteFacility;
        }));
        this.WasteFacilitiesApi.initialise();
    }
    applyWasteFacilitiesFilter(event) {
        const searchValue = event.target.value;
        this.wasteFacilitiesDataSource.filter = searchValue.trim().toLowerCase();
    }
    edit(facility) {
        const form = this.fb.group({
            wasteFacilityId: [null, null],
            address: this.fb.group({
                name: [null, [Validators.required]],
                site_id: [null, [Validators.required, Validators.pattern(/^.{6}$/)]],
                address: [null, [Validators.required]],
                postcode: [null, [Validators.required]],
                latitude: [null, [Validators.required]],
                longitude: [null, [Validators.required]],
                country: [null, null],
                site_verified: [null, null],
                site_phone_number: [null, null],
                site_email: [null, null],
            }),
            permitForm: this.fb.group({
                permits: [null, null],
                exemptions: [null, null],
                validPermits: [null, null],
                validExemptions: [null, null],
            }),
            primaryContactForm: this.fb.array([
                this.fb.group({
                    primary_contact_name: [null, [Validators.required]],
                    primary_contact_lastname: [null, [Validators.required]],
                    primary_contact_email: [null, [Validators.required]],
                    primary_contact_no: [null, null],
                }),
            ]),
            openingHoursForm: this.fb.group({
                opening_mon: [null, null],
                opening_tues: [null, null],
                opening_wed: [null, null],
                opening_thurs: [null, null],
                opening_fri: [null, null],
                opening_sat: [null, null],
                opening_sun: [null, null],
                closing_mon: [null, null],
                closing_tues: [null, null],
                closing_wed: [null, null],
                closing_thurs: [null, null],
                closing_fri: [null, null],
                closing_sat: [null, null],
                closing_sun: [null, null],
            }),
        });
        const dialogRef = this.dialog.open(NewSiteModalComponent, {
            data: { facility, form },
        });
        dialogRef.afterClosed().subscribe(() => {
            this.initialise();
        });
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], ManageWasteFacilitiesList.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], ManageWasteFacilitiesList.prototype, "paginator", void 0);
ManageWasteFacilitiesList = __decorate([
    Component({
        selector: "manage-waste-facilities",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [WasteFacilityApi,
        MatDialog,
        FormBuilder])
], ManageWasteFacilitiesList);
export { ManageWasteFacilitiesList };
