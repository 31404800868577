import { __decorate, __metadata } from "tslib";
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from '../../../services/user-api';
import { PasswordForgotFormFactory } from '../../../../../factories/password/forgot';
import { ResetPasswordModal } from '../reset/component';
let ForgotPasswordModal = class ForgotPasswordModal {
    constructor(UserService, dialog, router) {
        this.UserService = UserService;
        this.dialog = dialog;
        this.router = router;
    }
    ngOnInit() {
        this.form = PasswordForgotFormFactory.form();
    }
    onSubmit() {
        this.loading = true;
        this.UserService
            .forgotPassword(this.form.getRawValue().email)
            .then(result => this.setNewPassword(), error => this.handleError(error));
    }
    backToLogin() {
        this.router.navigate(['/login']);
    }
    setNewPassword() {
        let config = new MatDialogConfig;
        config.disableClose = true;
        this.dialog.open(ResetPasswordModal, config).afterClosed()
            .subscribe(success => this.backToLogin(), error => this.handleError(error));
    }
    handleError(error) {
        this.loading = false;
        this.error = error;
        alert(error);
    }
};
ForgotPasswordModal = __decorate([
    Component({
        selector: 'user-password-forgot',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [UserService,
        MatDialog,
        Router])
], ForgotPasswordModal);
export { ForgotPasswordModal };
