import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteDescription = class ConsignmentNoteDescription extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "descriptionOfTheWaste";
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.DescriptionOfTheWaste = !!consignmentNote
                ? consignmentNote.descriptionOfTheWaste
                : null;
        });
    }
};
ConsignmentNoteDescription = __decorate([
    Component({
        selector: "consignment-note-description",
        template: require('../../../../directives/view-consignment-note/description.html'),
        styles: [require('../../../../directives/view-consignment-note/description.scss')],
    })
], ConsignmentNoteDescription);
export { ConsignmentNoteDescription };
