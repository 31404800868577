import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { CarrierJobApi } from '../../../services/job-api';
let CloseJob = class CloseJob {
    constructor(FormBuilder, dialog, dialogRef, JobApi, Consignment) {
        this.FormBuilder = FormBuilder;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.JobApi = JobApi;
        this.Consignment = Consignment;
    }
    ngOnInit() {
        this.collected = !!this.Consignment.collectedTimestamp || !!this.Consignment.collectionDeclinedTimestamp;
        this.droppedOff = !!this.Consignment.droppedOffTimestamp || !!this.Consignment.dropOffDeclinedTimestamp;
        this.form = this.FormBuilder.group({
            evidenceFile: [null, [Validators.required]],
            notes: [null, [Validators.required]],
        });
        !this.collected && this.form.addControl('Collection', this.FormBuilder.group({
            collectionId: [this.Consignment.collectionId],
            Signatures: this.FormBuilder.group({
                Carrier: this.getSignatureForm(this.Consignment.collectionDate),
                Consignor: this.getSignatureForm(this.Consignment.collectionDate),
            }),
        }));
        !this.droppedOff && this.form.addControl('DropOff', this.FormBuilder.group({
            dropOffId: [this.Consignment.dropOffId],
            Signatures: this.FormBuilder.group({
                Consignee: this.getSignatureForm(this.Consignment.dropOffDate),
            }),
        }));
        this.today = moment().startOf('day').toDate();
    }
    closeJob() {
        let update = this.form.getRawValue();
        const evidenceFile = update.evidenceFile;
        update.evidenceFile = evidenceFile.type;
        Object.keys(update).map(key => {
            let data = update[key]; // Collection or DropOff
            data.hasOwnProperty('Signatures') && Object.keys(data['Signatures']).map(signatureId => {
                let signature = data['Signatures'][signatureId]; // Carrier, Consignor, Consignee
                signature.hasOwnProperty('name') && (data['Signatures'][signatureId] = {
                    name: signature.name,
                    date: this.dateTimeToObject(signature.date, signature.time),
                });
            });
        });
        this.JobApi
            .closeJob(this.Consignment.jobId, update)
            .then((Job) => this.JobApi.saveEvidenceFile(Job.evidenceFilePutUrl, evidenceFile))
            .then(() => this.dialogRef.close(true))
            .catch(() => this.dialogRef.close(null));
    }
    dateTimeToObject(date, time) {
        const dateTimeString = moment(date).format('YYYY-MM-DD ' + time);
        return moment(dateTimeString).toDate();
    }
    getSignatureForm(date) {
        return this.FormBuilder.group({
            name: [null, [Validators.required]],
            date: [moment(date).startOf('day').toDate(), [Validators.required]],
            time: ['12:00', [Validators.required, Validators.pattern(/^(?:2[0-3]|[01][0-9]):(?:[0-5][0-9])$/)]],
        });
    }
};
CloseJob = __decorate([
    Component({
        selector: 'close-job',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [FormBuilder,
        MatDialog,
        MatDialogRef,
        CarrierJobApi, Object])
], CloseJob);
export { CloseJob };
