import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { JobApi } from '../../../app/services/job-api';
let CarrierJobApi = class CarrierJobApi extends JobApi {
    getEndpoint(jobId, action) {
        return environment.endpoint.carrier.jobs(jobId, action);
    }
    getConsignmentEndpoint(consignmentId, action) {
        return environment.endpoint.carrier.consignments(consignmentId, action);
    }
};
CarrierJobApi = __decorate([
    Injectable()
], CarrierJobApi);
export { CarrierJobApi };
