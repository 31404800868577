import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { ConsignmentApi } from '../../../app/services/consignment-api';
import { environment } from '../../../app/constant/environment';
let BrokerConsignmentApi = class BrokerConsignmentApi extends ConsignmentApi {
    getEndpoint(consignmentId) {
        return environment.endpoint.broker.consignments(consignmentId);
    }
};
BrokerConsignmentApi = __decorate([
    Injectable()
], BrokerConsignmentApi);
export { BrokerConsignmentApi };
