import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let CarrierSummary = class CarrierSummary {
    constructor() {
    }
    ngOnInit() {
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], CarrierSummary.prototype, "Collection", void 0);
CarrierSummary = __decorate([
    Component({
        selector: 'carrier-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [])
], CarrierSummary);
export { CarrierSummary };
