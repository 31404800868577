import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../../../app/constant/environment";
import { Api } from "../../../app/services/api";
let InviteWasteProducersApi = class InviteWasteProducersApi {
    constructor(Api) {
        this.Api = Api;
    }
    inviteWasteProducers(email) {
        let endpoint = environment.endpoint.carrier.inviteWasteProducers();
        return this.Api.request(endpoint, "POST", email);
    }
};
InviteWasteProducersApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], InviteWasteProducersApi);
export { InviteWasteProducersApi };
