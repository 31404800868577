import { __decorate } from "tslib";
import { Component } from '@angular/core';
let Welcome = class Welcome {
};
Welcome = __decorate([
    Component({
        selector: 'guest-welcome',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], Welcome);
export { Welcome };
