import { __decorate, __metadata } from "tslib";
import { Component, ViewChild, Input } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../../services/user-api";
import { SiteRegistrationService } from "../../../../../app/services/v2/site-registration.service";
let CompanyRegistration = class CompanyRegistration {
    constructor(fb, UserService, siteRegistrationApi) {
        this.fb = fb;
        this.UserService = UserService;
        this.siteRegistrationApi = siteRegistrationApi;
        const organisation = this.UserService.getOrganisation();
        if (!organisation) {
            this.selectedIndex = 0;
            this.companyFormStatus = true;
        }
        else {
            this.siteRegistrationApi
                .getWasteFacility(organisation === null || organisation === void 0 ? void 0 : organisation.organisationId)
                .then((wasteFacilities) => {
                if (wasteFacilities.length === 0) {
                    this.selectedIndex = 1;
                    this.companyFormStatus = true;
                }
                else if (!organisation.subscriptionId) {
                    this.selectedIndex = 2;
                    this.companyFormStatus = true;
                    this.siteFormStatus = true;
                }
            });
        }
    }
    ngOnInit() {
        this.companyRegistrationForm = this.fb.group({
            companyDetails: this.fb.group({
                organisationId: [null, null],
                userId: [null, null],
                companyType: [null, [Validators.required]],
                name: [null, [Validators.required]],
                country: [
                    {
                        name: "England",
                        alpha2Code: "GB",
                        alpha3Code: "GBR",
                        numericCode: "826",
                        callingCode: "+44",
                    },
                    [Validators.required],
                ],
                phone: [null, [Validators.required]],
                companyNumber: [null, [Validators.required]],
                registeredOfficeAddress: [null, [Validators.required]],
                collectionArea: [null, [Validators.required]],
                sicCode: [null, [Validators.required]],
                industry: [null, [Validators.required]],
                billingAddress: [null, [Validators.required]],
                vatNumber: [null, null]
            }),
            siteRegistrationForm: this.fb.group({
                wasteFacilityId: [null, null],
                address: this.fb.group({
                    name: [null, [Validators.required]],
                    site_id: [null, [Validators.required, Validators.pattern(/^.{6}$/)]],
                    address: [null, [Validators.required]],
                    postcode: [null, [Validators.required]],
                    latitude: [null, [Validators.required]],
                    longitude: [null, [Validators.required]],
                    country: [null, null],
                    site_verified: [null, null],
                    site_phone_number: [null, null],
                    site_email: [null, null],
                }),
                permitForm: this.fb.group({
                    permits: [null, null],
                    exemptions: [null, null],
                    validPermits: [null, null],
                    validExemptions: [null, null],
                }),
                primaryContactForm: this.fb.array([
                    this.fb.group({
                        primary_contact_name: [null, [Validators.required]],
                        primary_contact_lastname: [null, [Validators.required]],
                        primary_contact_email: [null, [Validators.required]],
                        primary_contact_no: [null, null],
                    }),
                ]),
                openingHoursForm: this.fb.group({
                    opening_mon: [null, null],
                    opening_tues: [null, null],
                    opening_wed: [null, null],
                    opening_thurs: [null, null],
                    opening_fri: [null, null],
                    opening_sat: [null, null],
                    opening_sun: [null, null],
                    closing_mon: [null, null],
                    closing_tues: [null, null],
                    closing_wed: [null, null],
                    closing_thurs: [null, null],
                    closing_fri: [null, null],
                    closing_sat: [null, null],
                    closing_sun: [null, null],
                }),
            }),
        });
    }
    getCompanyType() {
        const organisation = this.UserService.getOrganisation();
        return organisation === null || organisation === void 0 ? void 0 : organisation.companyType;
    }
    onGetCompanyFormStatus(status) {
        this.companyFormStatus = status === "VALID";
    }
    onGetCompanyType({ companyType }) {
        this.companyType = companyType;
    }
    onGetSiteFormStatus(status) {
        this.siteFormStatus = status === "VALID";
    }
};
__decorate([
    ViewChild("stepper", { static: true }),
    __metadata("design:type", Object)
], CompanyRegistration.prototype, "stepper", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], CompanyRegistration.prototype, "companyFormStatus", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CompanyRegistration.prototype, "companyType", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], CompanyRegistration.prototype, "siteFormStatus", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], CompanyRegistration.prototype, "next", void 0);
CompanyRegistration = __decorate([
    Component({
        selector: "company-registration",
        template: require('./company-registration.component.html'),
        styles: [require('./company-registration.component.scss')],
    }),
    __metadata("design:paramtypes", [FormBuilder,
        UserService,
        SiteRegistrationService])
], CompanyRegistration);
export { CompanyRegistration };
