import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
import { BehaviorSubject } from "rxjs";
let UserOrganisationsApi = class UserOrganisationsApi {
    constructor(Api) {
        this.Api = Api;
        this.users = new BehaviorSubject({ userLimit: { haveLimit: false, message: "" }, Items: [] });
        this.loading = new BehaviorSubject(true);
        this.users$ = this.users.asObservable();
    }
    initialise() {
        this.loadUsers();
    }
    loadUsers() {
        this.list()
            .then((items) => {
            this.users.next(items);
            this.loading.next(false);
        });
    }
    list() {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, "GET");
    }
    edit(user, userId) {
        let endpoint = this.getEndpoint();
        const method = !!userId ? "PATCH" : "POST";
        return this.Api.request(endpoint, method, user);
    }
    getEndpoint(userId) {
        return environment.endpoint.admin.userOrganisations(userId);
    }
};
UserOrganisationsApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], UserOrganisationsApi);
export { UserOrganisationsApi };
