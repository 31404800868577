import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AddressFormFactory } from "./address";
let WasteFacilityFormFactory = class WasteFacilityFormFactory {
    static form(WasteFacility) {
        let _FormBuilder = new FormBuilder;
        let FormGroup = _FormBuilder.group({
            organisationId: [null],
            wasteFacilityId: [null],
            address: [null, [Validators.required, AddressFormFactory.validateAddress]],
            wastePermitNumber: [null, Validators.required],
            additionalLicences: [null],
            notes: [null],
        });
        !!WasteFacility && FormGroup.setValue(WasteFacility);
        return FormGroup;
    }
};
WasteFacilityFormFactory = __decorate([
    Injectable()
], WasteFacilityFormFactory);
export { WasteFacilityFormFactory };
