import { __decorate } from "tslib";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnyWasteMaterialModule } from "../material.module";
import { CarrierDashboard } from "./components/dashboard/component";
import { Api } from "../../app/services/api";
import { CarrierQuoteApi } from "./services/quote-api";
import { CarrierJobApi } from "./services/job-api";
import { QuoteList } from "./components/quotes/list/component";
import { CarrierConsignmentApi } from "./services/consignment-api";
import { CarrierWasteApi } from "./services/waste-api";
import { ProvideQuote } from "./components/quotes/provide/component";
import { DirectivesModule } from "../directives/module";
import { AllocateJob } from "./components/jobs/allocate/component";
import { WasteFacilityApi } from "./services/waste-facility-api";
import { UserOrganisationsApi } from "./services/organisaton-users-api";
import { VehicleApi } from "./services/vehicle-api";
import { EditVehicle } from "./components/vehicles/edit/component";
import { EditWasteFacility } from "./components/waste-facility/edit/component";
import { ListVehicles } from "./components/vehicles/list/component";
import { RepeatConsignment } from "./components/consignments/repeat/component";
import { routing } from "../../app/routing.module";
import { JobList } from "./components/jobs/list/component";
import { CarrierListWaste } from "./components/waste/list/component";
import { ViewQuote } from "./components/quotes/view/component";
import { Dashboard } from "./services/dashboard";
import { JobSummary } from "../directives/job/summary/component";
import { ViewJob } from "./components/jobs/view/component";
import { ListCarrierConsignments } from "./components/consignments/list/component";
import { CarrierConsignmentItem } from "./components/consignments/list/item/component";
import { InviteWasteProducers } from "./components/consignments/list/invite-waste-producers/component";
import { CarrierViewWaste } from "./components/waste/view/component";
import { CloseJob } from "./components/jobs/close/component";
import { CarrierConsignmentNoteApi } from "./services/consignment-note-api";
import { ViewConsignmentNote } from "./components/consignment-notes/component";
import { ConsignmentNoteDescription } from "./components/consignment-notes/view/description";
import { ConsignmentNoteNotification } from "./components/consignment-notes/view/notification";
import { Company } from "./components/consignment-notes/view/company-address";
import { ConsignmentNoteConsignor } from "./components/consignment-notes/view/consignor";
import { ConsignmentNoteDeclined } from "./components/consignment-notes/view/declined";
import { ConsignmentNoteCertificate } from "./components/consignment-notes/view/certificate";
import { ConsignmentNoteCarrier } from "./components/consignment-notes/view/carrier";
import { ConsignmentNoteConsignee } from "./components/consignment-notes/view/consignee";
import { ConsignmentNoteEditWaste } from "./components/consignment-notes/view/edit-waste-modal/component";
import { ConsignmentNoteWastePhotos } from "./components/consignment-notes/view/waste-photos";
import { ConsignmentNoteQuote } from "./components/consignment-notes/view/quote";
import { ViewWasteTransferNote } from "./components/waste-transfer-notes/component";
import { CarrierWasteTransferNoteApi } from "./services/waste-transfer-note-api";
import { WasteTransferNoteDescription } from "./components/waste-transfer-notes/waste-transfer-notes-view/description";
import { WasteTransferNoteTransferor } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferor";
import { WasteTransferNoteTransferee } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferee";
import { WasteTransferNoteTransfer } from "./components/waste-transfer-notes/waste-transfer-notes-view/component";
import { WasteTransferNoteTransfereeSignature } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferee-signature";
import { WasteTransferNoteTransferorSignature } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferor-signature";
import { InviteWasteProducersApi } from "./services/invite-waste-producers-api";
import { WasteTransferNoteWastePhotos } from "./components/waste-transfer-notes/waste-transfer-notes-view/waste-photos";
import { DangerousGoodsNoteDescription } from "./components/waste-transfer-notes/waste-transfer-notes-view/dangerous-goods-note";
import { ConsignmentDangerousGoodsNoteDescription } from "./components/consignment-notes/view/dangerous-goods-note";
let CarrierModule = class CarrierModule {
};
CarrierModule = __decorate([
    NgModule({
        imports: [CommonModule, routing, AnyWasteMaterialModule, DirectivesModule],
        declarations: [
            CarrierDashboard,
            QuoteList,
            ViewQuote,
            ProvideQuote,
            AllocateJob,
            ListCarrierConsignments,
            CarrierConsignmentItem,
            InviteWasteProducers,
            JobList,
            ViewJob,
            ViewConsignmentNote,
            JobSummary,
            CarrierListWaste,
            CarrierViewWaste,
            ListVehicles,
            RepeatConsignment,
            EditVehicle,
            EditWasteFacility,
            CloseJob,
            ConsignmentNoteCarrier,
            ConsignmentNoteCertificate,
            ConsignmentNoteConsignee,
            ConsignmentNoteNotification,
            ConsignmentNoteDescription,
            ConsignmentNoteConsignor,
            ConsignmentNoteDeclined,
            ConsignmentNoteWastePhotos,
            ConsignmentNoteQuote,
            ConsignmentDangerousGoodsNoteDescription,
            Company,
            ConsignmentNoteEditWaste,
            ViewWasteTransferNote,
            WasteTransferNoteDescription,
            WasteTransferNoteTransferor,
            WasteTransferNoteTransferee,
            WasteTransferNoteTransfer,
            WasteTransferNoteTransfereeSignature,
            WasteTransferNoteTransferorSignature,
            WasteTransferNoteWastePhotos,
            DangerousGoodsNoteDescription,
        ],
        entryComponents: [
            ViewQuote,
            ViewJob,
            ViewConsignmentNote,
            ProvideQuote,
            AllocateJob,
            CarrierListWaste,
            CarrierViewWaste,
            EditVehicle,
            EditWasteFacility,
            CloseJob,
            ConsignmentNoteNotification,
            ConsignmentNoteDescription,
            ConsignmentNoteConsignor,
            Company,
            ConsignmentNoteEditWaste,
        ],
        providers: [
            Api,
            Dashboard,
            CarrierQuoteApi,
            CarrierJobApi,
            CarrierConsignmentApi,
            CarrierConsignmentNoteApi,
            CarrierWasteApi,
            WasteFacilityApi,
            UserOrganisationsApi,
            VehicleApi,
            CarrierWasteTransferNoteApi,
            InviteWasteProducersApi,
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA],
        exports: [CarrierDashboard],
    })
], CarrierModule);
export { CarrierModule };
