import { __decorate, __metadata } from "tslib";
import { Component, Output, EventEmitter, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { InvoiceApi } from "../../services/invoice-api";
import { UserService } from "../../../user/services/user-api";
import { AuthService } from "../../../user/services/auth";
import { MatPaginator } from "@angular/material/paginator";
let Billing = class Billing {
    constructor(invoiceApi, UserService, AuthService, dialog) {
        this.invoiceApi = invoiceApi;
        this.UserService = UserService;
        this.AuthService = AuthService;
        this.dialog = dialog;
        this.loading = true;
        this.invoiceColumns = ["number", "created", "invoice_pdf"];
        this.invoicesDataSource = new MatTableDataSource([]);
    }
    ngOnInit() {
        this.load();
        this.invoicesDataSource.paginator = this.paginator;
    }
    load() {
        this.invoiceApi.get().then((data) => {
            this.invoicesDataSource.data = data.data;
            this.loading = false;
        });
    }
};
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], Billing.prototype, "save", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], Billing.prototype, "paginator", void 0);
Billing = __decorate([
    Component({
        selector: "billing",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [InvoiceApi,
        UserService,
        AuthService,
        MatDialog])
], Billing);
export { Billing };
