import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { WasteApi } from '../../../app/services/waste-api';
let BrokerWasteApi = class BrokerWasteApi extends WasteApi {
    getReadEndpoint(consignmentId, wasteId) {
        return this.getEndpoint(consignmentId, wasteId);
    }
    getWriteEndpoint(consignmentId, wasteId) {
        return this.getEndpoint(consignmentId, wasteId);
    }
    getEndpoint(consignmentId, wasteId) {
        return environment.endpoint.broker.consignmentWaste(consignmentId, wasteId);
    }
};
BrokerWasteApi = __decorate([
    Injectable()
], BrokerWasteApi);
export { BrokerWasteApi };
