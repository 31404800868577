import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteConsignee = class ConsignmentNoteConsignee extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "consigneeCertificate";
        this.certificateType = "consignee";
        this.subscriptions = [];
    }
    ngOnInit() {
        this.subscriptions.push(this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.Certificate = !!consignmentNote
                ? consignmentNote.consigneeCertificate
                : null;
        }));
    }
    ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe());
    }
};
ConsignmentNoteConsignee = __decorate([
    Component({
        selector: "consignment-note-consignee",
        template: require('../../../../directives/view-consignment-note/consignee.html'),
        styles: [require('../../../../directives/view-consignment-note/component.scss')],
    })
], ConsignmentNoteConsignee);
export { ConsignmentNoteConsignee };
