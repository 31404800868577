import { __decorate } from "tslib";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnyWasteMaterialModule } from '../material.module';
import { BrokerDashboard } from './components/dashboard/component';
import { ListConsignments } from './components/consignments/list/component';
import { ConsignmentItem } from './components/consignments/list/item/component';
import { ListPagination } from '../../app/components/pagination/component';
import { BrokerConsignmentApi } from './services/consignment-api';
import { Api } from '../../app/services/api';
import { BrokerQuoteApi } from './services/quote-api';
import { EditConsignment } from './components/consignments/edit/component';
import { EditWaste } from './components/waste/edit/component';
import { BrokerWasteApi } from './services/waste-api';
import { RequestQuote } from './components/quotes/request/component';
import { BrokerCarrierApi } from './services/carrier-api';
import { BrokerMenu } from './components/menu/component';
import { ViewQuote } from './components/quotes/view/component';
import { DirectivesModule } from '../directives/module';
import { BrokerListWaste } from './components/waste/list/component';
import { routing } from '../../app/routing.module';
import { Dashboard } from './services/dashboard';
import { BrokerViewWaste } from './components/waste/view/component';
import { ViewConsignmentNote } from "./components/consignment-notes/component";
import { ConsignmentNoteDescription } from "./components/consignment-notes/view/description";
import { ConsignmentNoteNotification } from "./components/consignment-notes/view/notification";
import { Company } from "./components/consignment-notes/view/company-address";
import { ConsignmentNoteConsignor } from "./components/consignment-notes/view/consignor";
import { ConsignmentNoteDeclined } from "./components/consignment-notes/view/declined";
import { ConsignmentNoteCertificate } from "./components/consignment-notes/view/certificate";
import { ConsignmentNoteCarrier } from "./components/consignment-notes/view/carrier";
import { ConsignmentNoteConsignee } from "./components/consignment-notes/view/consignee";
import { ConsignmentNoteWastePhotos } from "./components/consignment-notes/view/waste-photos";
import { ConsignmentNoteQuote } from "./components/consignment-notes/view/quote";
import { BrokerConsignmentNoteApi } from "./services/consignment-note-api";
import { BrokerWasteTransferNoteApi } from "./services/waste-transfer-note-api";
import { ViewWasteTransferNote } from "./components/waste-transfer-notes/component";
import { WasteTransferNoteDescription } from "./components/waste-transfer-notes/waste-transfer-notes-view/description";
import { WasteTransferNoteTransferor } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferor";
import { WasteTransferNoteTransferee } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferee";
import { WasteTransferNoteTransfer } from "./components/waste-transfer-notes/waste-transfer-notes-view/transfer";
import { WasteTransferNoteTransfereeSignature } from "./components/waste-transfer-notes/waste-transfer-notes-view/transferee-signature";
import { DangerousGoodsNoteDescription } from "./components/waste-transfer-notes/waste-transfer-notes-view/dangerous-goods-note";
import { ConsignmentDangerousGoodsNoteDescription } from "./components/consignment-notes/view/dangerous-goods-note";
let BrokerModule = class BrokerModule {
};
BrokerModule = __decorate([
    NgModule({
        imports: [CommonModule, routing, AnyWasteMaterialModule, DirectivesModule],
        declarations: [
            BrokerMenu,
            BrokerDashboard,
            ListConsignments,
            ConsignmentItem,
            ListPagination,
            EditConsignment,
            BrokerListWaste,
            BrokerViewWaste,
            EditWaste,
            RequestQuote,
            ViewQuote,
            ViewConsignmentNote,
            ConsignmentNoteDescription,
            ConsignmentNoteNotification,
            Company,
            ConsignmentNoteConsignor,
            ConsignmentNoteDeclined,
            ConsignmentNoteCertificate,
            ConsignmentNoteCarrier,
            ConsignmentNoteConsignee,
            ConsignmentNoteWastePhotos,
            ConsignmentNoteQuote,
            ConsignmentDangerousGoodsNoteDescription,
            ViewWasteTransferNote,
            WasteTransferNoteDescription,
            WasteTransferNoteTransferor,
            WasteTransferNoteTransferee,
            WasteTransferNoteTransfer,
            WasteTransferNoteTransfereeSignature,
            DangerousGoodsNoteDescription,
        ],
        entryComponents: [
            EditConsignment,
            BrokerListWaste,
            BrokerViewWaste,
            EditWaste,
            RequestQuote,
            ViewQuote,
            ViewConsignmentNote,
        ],
        providers: [
            Api,
            Dashboard,
            BrokerConsignmentApi,
            BrokerQuoteApi,
            BrokerWasteApi,
            BrokerCarrierApi,
            BrokerConsignmentNoteApi,
            BrokerWasteTransferNoteApi,
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA],
        exports: [BrokerMenu, BrokerDashboard],
    })
], BrokerModule);
export { BrokerModule };
