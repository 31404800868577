import { __decorate } from "tslib";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnyWasteMaterialModule } from '../material.module';
import { AddressSummary } from './address/summary/component';
import { EditAddress } from './address/edit/component';
import { ConsignmentSummary } from './consignment/summary/component';
import { QuoteSummary } from './quote/summary/component';
import { CollectionSummary } from './collection/summary/component';
import { DropOffSummary } from './drop-off/summary/component';
import { AddImage } from './add-image/component';
import { SignatureDetails } from './signature-details/component';
import { CarrierSummary } from './carrier/summary/component';
import { ConsigneeSummary } from './consignee/summary/component';
import { ConfirmEdit } from './consignment-note/confirm-edit-modal/component';
import { EditCompanyAddress } from './consignment-note/edit-company-address-modal/component';
import { EditText } from './consignment-note/edit-text-modal/component';
import { EditWasteTable } from './consignment-note/edit-waste-table-modal/component';
import { AddFile } from './add-file/component';
import { AddressByLoader } from './addressByLoader/component';
let DirectivesModule = class DirectivesModule {
};
DirectivesModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            AnyWasteMaterialModule,
        ],
        declarations: [
            AddImage,
            AddFile,
            AddressSummary,
            EditAddress,
            ConfirmEdit,
            EditText,
            EditWasteTable,
            ConsignmentSummary,
            CollectionSummary,
            CarrierSummary,
            DropOffSummary,
            ConsigneeSummary,
            QuoteSummary,
            SignatureDetails,
            EditCompanyAddress,
            AddressByLoader
        ],
        entryComponents: [
            EditAddress,
            ConfirmEdit,
            EditText,
            EditCompanyAddress,
            EditWasteTable,
        ],
        providers: [],
        schemas: [
            CUSTOM_ELEMENTS_SCHEMA,
        ],
        exports: [
            AddImage,
            AddFile,
            AddressSummary,
            ConsignmentSummary,
            CollectionSummary,
            CarrierSummary,
            DropOffSummary,
            ConsigneeSummary,
            QuoteSummary,
            SignatureDetails,
            AddressByLoader
        ],
    })
], DirectivesModule);
export { DirectivesModule };
