import { __decorate, __metadata } from "tslib";
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
let ConfirmEdit = class ConfirmEdit {
    constructor(dialogRef, FormBuilder) {
        this.dialogRef = dialogRef;
        this.FormBuilder = FormBuilder;
        this.form = FormBuilder.group({
            reason: [null, Validators.required],
        });
    }
    onSubmit() {
        this.dialogRef.close(this.form.controls['reason'].value);
    }
};
ConfirmEdit = __decorate([
    Component({
        selector: 'consignment-note-confirm-edit',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [MatDialogRef,
        FormBuilder])
], ConfirmEdit);
export { ConfirmEdit };
