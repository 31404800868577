import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { UserService } from "../../../../modules/user/services/user-api";
let WideFooter = class WideFooter {
    constructor(UserService) {
        this.UserService = UserService;
    }
    userLoggedIn() {
        return this.UserService.isLoggedIn();
    }
};
WideFooter = __decorate([
    Component({
        selector: "wide-footer",
        template: require('./component.html')
    }),
    __metadata("design:paramtypes", [UserService])
], WideFooter);
export { WideFooter };
