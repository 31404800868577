import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { QuoteApi } from '../../../app/services/quote-api';
let BrokerQuoteApi = class BrokerQuoteApi extends QuoteApi {
    getEndpoint(status) {
        return environment.endpoint.broker.quotes(status);
    }
    getConsignmentQuoteEndpoint(consignmentId, quoteId, action) {
        return environment.endpoint.broker.consignmentQuotes(consignmentId, quoteId, action);
    }
    getQuoteConsignmentEndpoint(quoteId, consignmentId) {
        return environment.endpoint.broker.quoteConsignment(quoteId, consignmentId);
    }
};
BrokerQuoteApi = __decorate([
    Injectable()
], BrokerQuoteApi);
export { BrokerQuoteApi };
