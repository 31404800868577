import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProvideQuote } from '../provide/component';
import { AllocateJob } from '../../jobs/allocate/component';
let ViewQuote = class ViewQuote {
    constructor(dialog, dialogRef, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ngOnInit() {
        this.Consignment = this.data.Consignment;
        this.Waste = this.data.Consignment.Waste;
        this.Quote = this.data.Quote;
        this.status = this.data.status;
    }
    provide(Quote) {
        let config = new MatDialogConfig();
        config.data = Quote;
        config.disableClose = true;
        let provideQuoteDialog = this.dialog.open(ProvideQuote, config);
        provideQuoteDialog.afterClosed().subscribe(closeModal => {
            closeModal && this.dialogRef.close();
        });
    }
    allocate() {
        let config = new MatDialogConfig();
        config.data = this.Consignment;
        config.disableClose = true;
        let dialogRef = this.dialog.open(AllocateJob, config);
        dialogRef.afterClosed().subscribe((allocated) => {
            allocated && this.dialogRef.close();
        });
    }
    decline(Quote) {
        // let config = new MatDialogConfig();
        //
        // config.data = Quote;
        //
        // this.dialog.open(DeclineQuote, config);
        alert('Decline Quote not yet implemented');
    }
};
ViewQuote = __decorate([
    Component({
        selector: 'view-quote',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef, Object])
], ViewQuote);
export { ViewQuote };
