import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { InviteRecyclersApi } from "../../../services/invite-recyclers-api";
let InviteRecyclers = class InviteRecyclers {
    constructor(InviteRecyclersApi, dialogRef) {
        this.InviteRecyclersApi = InviteRecyclersApi;
        this.dialogRef = dialogRef;
    }
    ngOnInit() {
        let formBuilder = new FormBuilder();
        this.form = formBuilder.group({ email: [null, [Validators.required]] });
    }
    onSubmit() {
        this.loading = true;
        this.InviteRecyclersApi.inviteRecyclers(this.form.getRawValue())
            .then(() => {
            this.dialogRef.close();
        }).catch(() => {
            this.dialogRef.close();
        });
    }
};
InviteRecyclers = __decorate([
    Component({
        selector: "invite-recyclers",
        template: require('./component.html'),
        styles: [require('./component.scss')]
    }),
    __metadata("design:paramtypes", [InviteRecyclersApi,
        MatDialogRef])
], InviteRecyclers);
export { InviteRecyclers };
