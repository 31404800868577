import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
let EditText = class EditText {
    constructor(config, dialogRef, FormBuilder) {
        this.config = config;
        this.dialogRef = dialogRef;
        this.FormBuilder = FormBuilder;
        this.form = FormBuilder.group({
            text: [config.text, Validators.required],
        });
    }
    onSubmit() {
        let data = this.form.dirty ? this.form.controls['text'].value : null;
        this.dialogRef.close(data);
    }
};
EditText = __decorate([
    Component({
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(0, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [Object, MatDialogRef,
        FormBuilder])
], EditText);
export { EditText };
