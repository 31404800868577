import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CompaniesHouseService } from "../../../../../../app/services/v2/companies-house.service";
import { Validators, FormGroupDirective } from "@angular/forms";
import { CompanyRegistrationService } from "../../../../../../app/services/v2/company-registration.service";
import { predefinedCountries } from "./countries";
import { AdminOrganisationApi } from "../../../../../../modules/admin/services/organisation-api";
import { UserService } from "../../../../../user/services/user-api";
import { AuthService } from "../../../../services/auth";
let CompanyInfo = class CompanyInfo {
    constructor(rootFormGroup, companyHouseApi, companyRegistrationApi, adminOrganisationApi, UserService, AuthService) {
        this.rootFormGroup = rootFormGroup;
        this.companyHouseApi = companyHouseApi;
        this.companyRegistrationApi = companyRegistrationApi;
        this.adminOrganisationApi = adminOrganisationApi;
        this.UserService = UserService;
        this.AuthService = AuthService;
        this.getCompanyFormStatus = new EventEmitter();
        this.getCompanyType = new EventEmitter();
        this.countryList = predefinedCountries;
        this.isEdit = false;
        this.buttonTitle = "Register Company";
        this.companyNumber = "";
        this.lookupCompany = (number) => __awaiter(this, void 0, void 0, function* () {
            const checkCompany = yield this.adminOrganisationApi.checkIfCompanyExists(number);
            const { companyExists } = checkCompany;
            if (!companyExists) {
                const data = yield this.companyHouseApi.getCompanyInfo(number);
                this.companyExists = false;
                this.companyHouseInfo = data;
                const { address_line_1, country, locality, postal_code } = data["registered_office_address"];
                this.companyHouseInfo.formattedAddress = `${address_line_1}, ${country}, ${locality}, ${postal_code}`;
                this.companyHouseInfo.formattedSicCodes =
                    data["sic_codes"] &&
                        Object.keys(data["sic_codes"])
                            .map(function (k) {
                            return data["sic_codes"][k];
                        })
                            .join(", ");
                this.form.controls["name"].patchValue(this.companyHouseInfo.company_name);
                this.form.controls["sicCode"].patchValue(this.companyHouseInfo.formattedSicCodes);
                this.form.controls["registeredOfficeAddress"].patchValue(this.companyHouseInfo.formattedAddress);
            }
            else {
                this.companyExists = true;
                this.form.controls["name"].patchValue("");
                this.form.controls["sicCode"].patchValue("");
                this.form.controls["registeredOfficeAddress"].patchValue("");
            }
        });
    }
    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName);
        this.companyExists = false;
        if (!!this.data) {
            this.buttonTitle = "Update Company";
            this.isEdit = true;
            this.loadFormData();
        }
    }
    ngAfterViewInit() {
        this.form.statusChanges.subscribe((res) => this.getCompanyFormStatus.emit(res));
        this.form.valueChanges.subscribe((res) => {
            this.getCompanyType.emit(res);
        });
    }
    loadFormData() {
        var _a;
        const { companyNumber } = this.data;
        this.companyNumber = companyNumber;
        (_a = this.form) === null || _a === void 0 ? void 0 : _a.patchValue(Object.assign({}, this.data));
    }
    chooseCompanyType(event) {
        const { value } = event;
        this.companyType = value;
        const fieldArr = [
            "registeredOfficeAddress",
            "collectionArea",
            "industry",
            "billingAddress",
        ];
        for (const field of fieldArr) {
            value === "producer"
                ? this.form.controls[field].clearValidators()
                : this.form.controls[field].addValidators(Validators.required);
            this.form.controls[field].updateValueAndValidity();
        }
    }
    submitCompanyDetails() {
        const company = this.form.value;
        this.isEdit
            ? this.adminOrganisationApi
                .patch(company)
                .then(() => {
                this.UserService.setOrganisation();
                alert("Changes are saved.");
            })
                .catch((error) => console.log(error))
            : this.companyRegistrationApi
                .registerCompany(company)
                .then((CompanyDetails) => {
                this.AuthService.refreshToken();
            })
                .then(() => this.next())
                .catch((error) => console.log(error));
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], CompanyInfo.prototype, "formGroupName", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], CompanyInfo.prototype, "next", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], CompanyInfo.prototype, "getCompanyFormStatus", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], CompanyInfo.prototype, "getCompanyType", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], CompanyInfo.prototype, "data", void 0);
CompanyInfo = __decorate([
    Component({
        selector: "app-company-info",
        template: require('./company-info.component.html'),
        styles: [require('./company-info.component.scss')],
    }),
    __metadata("design:paramtypes", [FormGroupDirective,
        CompaniesHouseService,
        CompanyRegistrationService,
        AdminOrganisationApi,
        UserService,
        AuthService])
], CompanyInfo);
export { CompanyInfo };
