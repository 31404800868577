import { __awaiter, __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { WasteFacilityApi } from "../../../services/waste-facility-api";
import { VehicleApi } from "../../../services/vehicle-api";
import moment from "moment-timezone";
import { CarrierJobApi } from "../../../services/job-api";
import { AllocateJobFactory } from "../../../../../factories/allocation";
import { Dashboard } from "../../../../carrier/services/dashboard";
import { CarrierWasteApi } from "../../../services/waste-api";
import { Regions, districtCouncils, countyCouncils, } from "../../../../../app/constant/destinations";
import { UserService } from "../../../../user/services/user-api";
let AllocateJob = class AllocateJob {
    constructor(dialog, dialogRef, Dashboard, JobApi, WasteFacilityApi, VehicleApi, fb, WasteApi, UserService, Consignment) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.Dashboard = Dashboard;
        this.JobApi = JobApi;
        this.WasteFacilityApi = WasteFacilityApi;
        this.VehicleApi = VehicleApi;
        this.fb = fb;
        this.WasteApi = WasteApi;
        this.UserService = UserService;
        this.Consignment = Consignment;
        this.destinationType = ["Region", "County Council", "District Council"];
        this.transportList = [
            "Shipping Container",
            "Freight Train",
            "Passenger & Cargo Plane",
            "Cargo Plane",
            "N/A",
        ];
        //   public areaType;
        this.Regions = Regions;
        this.districtCouncils = districtCouncils;
        this.countyCouncils = countyCouncils;
        this.loading = false;
        this.collected = false;
    }
    ngOnInit() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.admin = this.isAdmin();
            this.form = AllocateJobFactory.form();
            this.todayDate = moment().startOf("day").toDate();
            this.form.valueChanges.subscribe(() => this.setStartDate());
            yield Promise.all([this.loadWasteFacilities(), this.loadVehicles()]);
            this.checked(this.Consignment.dangerousForm);
            this.form.patchValue({
                vehicleId: this.Consignment.vehicleId || null,
                collectionDate: moment(this.Consignment.collectionDate)
                    .startOf("day")
                    .toDate(),
                dangerousForm: this.Consignment.dangerousForm || false,
                wasteFacilityId: this.getWasteFacilityById(this.Consignment.wasteFacilityId) || null,
                dropOffDate: moment(this.Consignment.dropOffDate)
                    .startOf("day")
                    .toDate(),
                PPE: (_a = this.Consignment.DangerousGoods) === null || _a === void 0 ? void 0 : _a.PPE,
                areaType: (_b = this.Consignment.DangerousGoods) === null || _b === void 0 ? void 0 : _b.areaType,
                destination: (_c = this.Consignment.DangerousGoods) === null || _c === void 0 ? void 0 : _c.destination,
                dischargePlace: (_d = this.Consignment.DangerousGoods) === null || _d === void 0 ? void 0 : _d.dischargePlace,
                freightForwarderReference: (_e = this.Consignment.DangerousGoods) === null || _e === void 0 ? void 0 : _e.freightForwarderReference,
                loadingPlace: (_f = this.Consignment.DangerousGoods) === null || _f === void 0 ? void 0 : _f.loadingPlace,
                shipName: (_g = this.Consignment.DangerousGoods) === null || _g === void 0 ? void 0 : _g.shipName,
                shippersReference: (_h = this.Consignment.DangerousGoods) === null || _h === void 0 ? void 0 : _h.shippersReference,
                transport: (_j = this.Consignment.DangerousGoods) === null || _j === void 0 ? void 0 : _j.transport,
                wasteCarrierLicense: (_k = this.Consignment.DangerousGoods) === null || _k === void 0 ? void 0 : _k.wasteCarrierLicense,
            });
            if (this.Consignment.collectedTimestamp) {
                this.form.controls.vehicleId.disable();
                this.form.controls.collectionDate.disable();
                this.collected = true;
            }
            this.loading = false;
        });
    }
    isAdmin() {
        return this.UserService.isInGroup("admin");
    }
    allocate() {
        this.loading = true;
        let Job = this.form.getRawValue();
        Job.jobId = this.Consignment.jobId;
        Job.wasteFacilityId = Job.wasteFacilityId.wasteFacilityId;
        !!this.Consignment.collectionId &&
            (Job.collectionId = this.Consignment.collectionId);
        !!this.Consignment.dropOffId &&
            (Job.dropOffId = this.Consignment.dropOffId);
        this.JobApi.save(this.Consignment.consignmentId, Job).then(() => {
            this.loading = false;
            this.dialogRef.close(true);
        });
    }
    addCollection() {
        this.collectionInfo.push(this.fb.group({
            roundNumber: [null, null],
            collectionNumber: [null, null],
        }));
    }
    getWasteFacilityById(id) {
        return this.wasteFacilityList.find((item) => item.wasteFacilityId === id);
    }
    setStartDate() {
        this.startDate = moment(this.form.controls.collectionDate.value)
            .startOf("day")
            .toDate();
    }
    loadWasteFacilities() {
        let wasteFacilityIdControl = this.form.controls.wasteFacilityId;
        wasteFacilityIdControl.disable();
        return this.WasteFacilityApi.list().then((WasteFacilities) => {
            this.wasteFacilityList = WasteFacilities;
            wasteFacilityIdControl.enable();
        });
    }
    loadVehicles() {
        let vehicleIdControl = this.form.controls.vehicleId;
        vehicleIdControl.disable();
        return this.VehicleApi.list().then((Vehicles) => {
            this.vehiclesList = Vehicles;
            !this.collected && vehicleIdControl.enable();
        });
    }
    checked(check) {
        // Enable or disable the form fields on checkbox value
        [
            "shippersReference",
            "wasteCarrierLicense",
            "transport",
            "PPE",
            "destination",
            "areaType",
        ].forEach((item) => this.form.controls[item][check ? "enable" : "disable"]());
    }
    remove(index) {
        this.collectionInfo.removeAt(index);
    }
    get collectionInfo() {
        return this.form.controls.collectionInfo;
    }
};
AllocateJob = __decorate([
    Component({
        selector: "allocate-quote",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(9, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        Dashboard,
        CarrierJobApi,
        WasteFacilityApi,
        VehicleApi,
        FormBuilder,
        CarrierWasteApi,
        UserService, Object])
], AllocateJob);
export { AllocateJob };
