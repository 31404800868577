import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AllocateJob } from '../allocate/component';
import { CarrierConsignmentApi } from '../../../services/consignment-api';
let ViewJob = class ViewJob {
    constructor(dialog, dialogRef, ConsignmentApi, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.ConsignmentApi = ConsignmentApi;
        this.data = data;
    }
    ngOnInit() {
        this.Consignment = this.data.Consignment;
        this.status = this.data.status;
    }
    allocate(consignmentId) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        this.ConsignmentApi.get(consignmentId).then((Consignment) => {
            config.data = Consignment;
            let dialogRef = this.dialog.open(AllocateJob, config);
            dialogRef.afterClosed().subscribe((allocated) => {
                allocated && this.dialogRef.close();
            });
        });
    }
};
ViewJob = __decorate([
    Component({
        selector: 'view-job',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        CarrierConsignmentApi, Object])
], ViewJob);
export { ViewJob };
