import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../../../app/services/api";
import { environment } from "../../../app/constant/environment";
let PaymentManagementApi = class PaymentManagementApi {
    constructor(Api) {
        this.Api = Api;
        this.endpoint = environment.endpoint.admin.paymentManagement();
    }
    get() {
        return this.Api.request(this.endpoint, "GET");
    }
    put(paymentMethodId) {
        return this.Api.request(this.endpoint, "PUT", { paymentMethodId });
    }
    delete(paymentMethodId) {
        return this.Api.request(this.endpoint, "DELETE", { paymentMethodId });
    }
    insert(card) {
        return this.Api.request(this.endpoint, "POST", card);
    }
};
PaymentManagementApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], PaymentManagementApi);
export { PaymentManagementApi };
