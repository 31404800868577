import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BrokerConsignmentApi } from './consignment-api';
let Dashboard = class Dashboard {
    constructor(ConsignmentApi) {
        this.ConsignmentApi = ConsignmentApi;
        this.statuses = ['new', 'waiting'];
        this._loading = new BehaviorSubject(false);
        this._list = new BehaviorSubject({
            created: [],
            waiting: [],
            quoted: [],
            accepted: [],
            live: [],
            closed: []
        });
    }
    /**
     * Load all the different statuses...
     */
    load() {
        this._loading.next(true);
        Promise.all([
            this.loadFiltered('created'),
            this.loadFiltered('waiting'),
            this.loadFiltered('quoted'),
            this.loadFiltered('accepted'),
            this.loadFiltered('live'),
            this.loadFiltered('closed'),
        ])
            .then(() => this._loading.next(false));
    }
    loadFiltered(status) {
        return this.ConsignmentApi
            .list(status)
            .then((Consignments) => Consignments
            .map((Consignment) => {
            this.setConsignmentStatus(Consignment);
            return Consignment;
        }))
            .then((Consignments) => {
            let list = this._list.getValue();
            list[status] = Consignments;
            this._list.next(list);
        });
    }
    save(Consignment) {
        return this.ConsignmentApi
            .save(Consignment);
    }
    get(consignmentId) {
        return this.ConsignmentApi
            .get(consignmentId);
    }
    remove(consignmentId) {
        return this.ConsignmentApi
            .remove(consignmentId);
    }
    setConsignmentStatus(Consignment) {
        switch (true) {
            case !!Consignment.collectionDeclinedTimestamp:
            case !!Consignment.dropOffDeclinedTimestamp:
                Consignment.status = 'declined';
                break;
            case !!Consignment.completedTimestamp:
                Consignment.status = 'completed';
                break;
            case !!Consignment.collectedTimestamp:
                Consignment.status = 'collected';
                break;
            default:
                Consignment.status = 'pending';
        }
    }
};
Dashboard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [BrokerConsignmentApi])
], Dashboard);
export { Dashboard };
