import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { ConsignmentApi } from "../../../app/services/consignment-api";
import { environment } from "../../../app/constant/environment";
import { UserService } from "../../user/services/user-api";
import { Api } from "../../../app/services/api";
let CarrierConsignmentApi = class CarrierConsignmentApi extends ConsignmentApi {
    constructor(Api, UserService) {
        super(Api);
        this.Api = Api;
        this.UserService = UserService;
    }
    post(Consignment) {
        let body = Object.assign({}, Consignment);
        body.brokerName = this.UserService.getOrganisation().name;
        body.carrierName = this.UserService.getOrganisation().name;
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, "POST", body);
    }
    getEndpoint(consignmentId) {
        return environment.endpoint.carrier.consignments(consignmentId);
    }
};
CarrierConsignmentApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api, UserService])
], CarrierConsignmentApi);
export { CarrierConsignmentApi };
