import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { RequestQuote } from "../../quotes/request/component";
import { EditConsignment } from "../edit/component";
import { BrokerListWaste } from "../../waste/list/component";
import { Dashboard } from "../../../services/dashboard";
import { BrokerConsignmentApi } from "../../../services/consignment-api";
import { BrokerDashboard } from "../../dashboard/component";
import { ViewConsignmentNote } from "../../consignment-notes/component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ViewWasteTransferNote } from "../../waste-transfer-notes/component";
import moment from "moment";
let ListConsignments = class ListConsignments {
    constructor(Dashboard, BrokerDashboard, dialog, ConsignmentApi, ref) {
        this.Dashboard = Dashboard;
        this.BrokerDashboard = BrokerDashboard;
        this.dialog = dialog;
        this.ConsignmentApi = ConsignmentApi;
        this.ref = ref;
        this.loading = false;
        this.consignmentListDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "code",
            "collectFrom",
            "collectionDate",
            "description",
            "add",
        ];
        this.liveDisplayedColumns = [
            "code",
            "description",
            "removedFrom",
            "status",
            "add",
        ];
    }
    ngOnInit() {
        this.setDataSourceAttributes();
        this.Dashboard._list.subscribe((list) => {
            this.list = list[this.status];
            list[this.status].map((data, index) => {
                list[this.status][index].formattedCollectionDate =
                    data.collectionDate &&
                        moment(data.collectionDate).format("DD/MM/YYYY");
                list[this.status][index].formattedDropOffDate =
                    data.dropOffDate && moment(data.dropOffDate).format("DD/MM/YYYY");
            });
            this.consignmentListDataSource.data = list[this.status];
            this.ref.detectChanges();
            this.consignmentListDataSource.sort = this.sort;
            this.consignmentListDataSource.paginator = this.paginator;
        });
        this.Dashboard._loading.subscribe((loading) => (this.loading = loading));
    }
    viewConsignmentNote(consignment) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = consignment;
        const consignmentNoteModal = consignment.hazardous
            ? ViewConsignmentNote
            : ViewWasteTransferNote;
        let dialog = this.dialog.open(consignmentNoteModal, config);
        dialog.afterClosed().subscribe(() => this.refresh());
    }
    requestQuote(Consignment) {
        let config = new MatDialogConfig();
        config.data = Consignment;
        config.disableClose = true;
        let dialogRef = this.dialog.open(RequestQuote, config);
        dialogRef.afterClosed().subscribe(() => this.refresh());
    }
    edit(consignmentId) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = consignmentId;
        let dialogRef = this.dialog.open(EditConsignment, config);
        dialogRef
            .afterClosed()
            .subscribe((Consignment) => !!Consignment && this.save(Consignment));
    }
    remove(consignmentId) {
        confirm("Are you sure you want to delete Consignment?") &&
            this.Dashboard.remove(consignmentId).then(() => this.refresh());
    }
    listWaste(Consignment) {
        let config = new MatDialogConfig();
        config.data = Consignment;
        config.disableClose = true;
        let dialogRef = this.dialog.open(BrokerListWaste, config);
        dialogRef.afterClosed().subscribe(() => this.refresh());
    }
    applyConsignmentListFilter(event) {
        const searchValue = event.target.value;
        this.consignmentListDataSource.filter = searchValue.trim().toLowerCase();
    }
    setDataSourceAttributes() {
        const { filterPredicate } = this.consignmentListDataSource;
        this.consignmentListDataSource.filterPredicate = (consignmentList, filter) => {
            const searchableData = Object.assign(Object.assign({}, consignmentList), consignmentList.wasteRemovedFromAddress);
            return filterPredicate(searchableData, filter);
        };
        this.consignmentListDataSource.sort = this.sort;
        this.consignmentListDataSource.paginator = this.paginator;
    }
    refresh() {
        this.Dashboard.load();
    }
    save(Consignment) {
        this.ConsignmentApi.save(Consignment).then((Consignment) => this.listWaste(Consignment));
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], ListConsignments.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator),
    __metadata("design:type", MatPaginator)
], ListConsignments.prototype, "paginator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListConsignments.prototype, "status", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListConsignments.prototype, "title", void 0);
ListConsignments = __decorate([
    Component({
        selector: "list-consignments",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [Dashboard,
        BrokerDashboard,
        MatDialog,
        BrokerConsignmentApi,
        ChangeDetectorRef])
], ListConsignments);
export { ListConsignments };
