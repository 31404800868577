import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CarrierQuoteApi } from './quote-api';
import { CarrierJobApi } from './job-api';
import { CarrierConsignmentApi } from './consignment-api';
let Dashboard = class Dashboard {
    constructor(QuoteApi, ConsignmentApi, JobApi) {
        this.QuoteApi = QuoteApi;
        this.ConsignmentApi = ConsignmentApi;
        this.JobApi = JobApi;
        this._loading = new BehaviorSubject(false);
        this._list = new BehaviorSubject({
            requested: [],
            provided: [],
            accepted: [],
            consignments: [],
            live: [],
            closed: [],
        });
    }
    /**
     * Load all the different statuses...
     */
    load() {
        this._loading.next(true);
        this._list.next({
            requested: [],
            provided: [],
            accepted: [],
            consignments: [],
            live: [],
            closed: [],
        });
        Promise.all([
            this.loadFilteredQuotes('requested'),
            this.loadFilteredQuotes('provided'),
            this.loadFilteredQuotes('accepted'),
            this.loadUnallocatedConsignments(),
            this.loadFilteredJobs('live'),
            this.loadFilteredJobs('closed'),
        ])
            .then(() => this._loading.next(false), () => this._loading.next(false));
    }
    saveConsignment(Consignment) {
        return this.ConsignmentApi
            .save(Consignment);
    }
    removeConsignment(consignmentId) {
        return this.ConsignmentApi
            .remove(consignmentId);
    }
    loadFilteredQuotes(status) {
        return this.QuoteApi
            .list(status)
            .then((Quotes) => Quotes.map(this.addQuoteStatus), error => alert(error))
            .then((Quotes) => {
            let list = this._list.getValue();
            list[status] = Quotes;
            this._list.next(list);
        });
    }
    loadUnallocatedConsignments() {
        return this.ConsignmentApi
            .list('accepted')
            .then((Consignments) => {
            let list = this._list.getValue();
            list.consignments = Consignments;
            this._list.next(list);
        });
    }
    loadFilteredJobs(status) {
        return this.JobApi
            .list(status)
            .then((Jobs) => Jobs.map(this.addJobStatus), error => alert(error))
            .then((Jobs) => {
            let list = this._list.getValue();
            list[status] = Jobs;
            this._list.next(list);
        });
    }
    addQuoteStatus(Quote) {
        switch (true) {
            case !!Quote.jobAcceptTimestamp:
                Quote.status = 'awaiting allocation';
                break;
            case !!Quote.provideTimestamp:
                Quote.status = 'pending';
                break;
            default:
                Quote.status = 'requested';
        }
        return Quote;
    }
    addJobStatus(Job) {
        switch (true) {
            case !!Job.collectionDeclinedTimestamp:
            case !!Job.dropOffDeclinedTimestamp:
                Job.status = 'declined';
                break;
            case !!Job.completedTimestamp:
                Job.status = 'completed';
                break;
            case !!Job.collectedTimestamp:
                Job.status = 'collected';
                break;
            default:
                Job.status = 'awaiting collection';
        }
        return Job;
    }
};
Dashboard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [CarrierQuoteApi,
        CarrierConsignmentApi,
        CarrierJobApi])
], Dashboard);
export { Dashboard };
