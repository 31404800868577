import { __decorate } from "tslib";
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
// import {LocalStorageModule} from 'angular-2-local-storage';
import { AnyWasteMaterialModule } from "../modules/material.module";
import { routing, appRouting } from "./routing.module";
import { AppComponent } from "./component";
import { UserModule } from "../modules/user/module";
import { BrokerModule } from "../modules/broker/module";
import { CarrierModule } from "../modules/carrier/module";
import { Welcome } from "./components/pages/welcome/component";
import { About } from "./components/pages/about/component";
import { Dashboard } from "./components/dashboard/component";
import { DriverMenu } from "./components/menu/driver/component";
import { CustomerMenu } from "./components/menu/customer/component";
import { AdminModule } from "../modules/admin/module";
import { AwSnackBar } from "./services/aw-snack-bar";
import { WideFooter } from "./components/footer/wide-footer/component";
import { BasicFooter } from "./components/footer/basic-footer/component";
let AppModule = class AppModule {
};
AppModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            HttpClientModule,
            routing,
            AnyWasteMaterialModule,
            // LocalStorageModule.withConfig(
            //     {
            //         prefix:      'AnyWaste',
            //         storageType: 'localStorage',
            //     }),
            UserModule,
            BrokerModule,
            CarrierModule,
            AdminModule,
        ],
        declarations: [
            AppComponent,
            Welcome,
            About,
            DriverMenu,
            CustomerMenu,
            Dashboard,
            WideFooter,
            BasicFooter
        ],
        entryComponents: [],
        providers: [
            { provide: LOCALE_ID, useValue: "en-GB" },
            appRouting,
            AwSnackBar,
        ],
        bootstrap: [AppComponent],
        schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    })
], AppModule);
export { AppModule };
