import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, Output, EventEmitter } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PaymentManagementApi } from "../../services/payment-management-api";
import { AddPaymentMethod } from "./add-payment-method/component";
import { VerifyPassword } from "./verify-password/component";
import { UserService } from "../../../user/services/user-api";
import { AuthService } from "../../../user/services/auth";
let PaymentManagement = class PaymentManagement {
    constructor(paymentManagementApi, UserService, AuthService, dialog) {
        this.paymentManagementApi = paymentManagementApi;
        this.UserService = UserService;
        this.AuthService = AuthService;
        this.dialog = dialog;
        this._cardPaymentMethods = new BehaviorSubject([]);
        this._bankAccountPaymentMethods = new BehaviorSubject([]);
        this.cardDataSource = new MatTableDataSource([]);
        this.bankAccountsDataSource = new MatTableDataSource([]);
        this.loading = true;
        this.cardDisplayedColumns = ["brand", "expires", "isDefault", "delete"];
        this.bankAccountdisplayedColumns = ["bank_name", "sort_code", "isDefault", "delete"];
    }
    ngOnInit() {
        this._cardPaymentMethods.subscribe((items) => {
            this.cardDataSource.data = items;
        });
        this._bankAccountPaymentMethods.subscribe((items) => {
            this.bankAccountsDataSource.data = items;
        });
        this.load();
    }
    load() {
        this.paymentManagementApi.get().then((data) => {
            this.loading = false;
            this._cardPaymentMethods.next(data.cards);
            this._bankAccountPaymentMethods.next(data.bankAccounts.map(element => {
                element.sort_code = element.sort_code.match(/.{1,2}/g).join('-');
                return element;
            }));
        });
    }
    openAddPaymentMethodDialog() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = { addPaymentMethod: (card) => this.addPaymentMethod(card) };
        this.dialog.open(AddPaymentMethod, config);
    }
    openVerifyPasswordDialog(title, callback) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = {
            title,
            verifyPassword: (password) => __awaiter(this, void 0, void 0, function* () {
                let loginDetail = Object.assign({ email: this.UserService.getUserAttributes()["email"] }, password);
                return this.UserService.login(loginDetail);
            }),
            success: () => {
                callback();
            }
        };
        this.dialog.open(VerifyPassword, config);
    }
    deletePaymentMethod(cardId) {
        this.openVerifyPasswordDialog("Please verify your password before you delete a payment method.", () => {
            this.loading = true;
            this.paymentManagementApi.delete(cardId).then(() => {
                this.dialog.closeAll();
                this.load();
            });
        });
        this.loading = true;
    }
    setDefaultPaymentMethod(cardId) {
        this.openVerifyPasswordDialog("Please verify your password before you change default payment method.", () => {
            this.loading = true;
            this.paymentManagementApi.put(cardId).then(() => {
                this.dialog.closeAll();
                this.load();
            });
        });
    }
    addPaymentMethod(card) {
        this.loading = true;
        this.paymentManagementApi.insert(Object.assign({}, card)).then(() => {
            this.dialog.closeAll();
            this.load();
        });
    }
};
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PaymentManagement.prototype, "save", void 0);
PaymentManagement = __decorate([
    Component({
        selector: "update-subscription",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [PaymentManagementApi,
        UserService,
        AuthService,
        MatDialog])
], PaymentManagement);
export { PaymentManagement };
