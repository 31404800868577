import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "./api";
import { BehaviorSubject } from "rxjs";
let ConsignmentNoteApi = class ConsignmentNoteApi {
    constructor(Api) {
        this.Api = Api;
        this._loading = new BehaviorSubject(false);
        this._consignmentNote = new BehaviorSubject(null);
    }
    setConsignmentNote(consignmentNote) {
        this._consignmentNote.next(consignmentNote);
    }
    getConsignmentNote(jobId) {
        this._loading.next(true);
        const endpoint = this.getEndpoint(jobId);
        return this.Api.request(endpoint, "GET")
            .then((consignmentNote) => {
            this._consignmentNote.next(consignmentNote);
            this._loading.next(false);
        })
            .catch(() => this._consignmentNote.next(null));
    }
    update(update, section, field) {
        this._loading.next(true);
        const endpoint = this.getEndpoint(update.jobId, section, field);
        return this.Api.request(endpoint, "PUT", update)
            .then((consignmentNote) => this._consignmentNote.next(consignmentNote))
            .catch(() => this._consignmentNote.next(null))
            .then(() => this._loading.next(false));
    }
    getConsignmentNoteHistory(jobId, dateTime) {
        const endpoint = `${this.getEndpoint()}/get/${jobId}/${dateTime}`;
        this.Api.request(endpoint, "GET");
    }
};
ConsignmentNoteApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], ConsignmentNoteApi);
export { ConsignmentNoteApi };
