import { RouterModule } from "@angular/router";
import { Welcome } from "./components/pages/welcome/component";
import { GuestGuard } from "../modules/user/services/guest-guard";
import { About } from "./components/pages/about/component";
import Login from "../modules/user/components/login/component";
import { AuthGuard } from "../modules/user/services/auth-guard";
import { Registration } from "../modules/user/components/register/component";
import { ForgotPasswordModal } from "../modules/user/components/password/forgot/component";
import { Dashboard } from "./components/dashboard/component";
import { ListVehicles } from "../modules/carrier/components/vehicles/list/component";
import { ManageUsersList } from "../modules/admin/components/manage-users/list/component";
import { CompanyRegistration } from "../modules/user/v2/components/company-registration/company-registration.component";
import { RegistrationGuard } from "../modules/user/services/registration-guard";
import { ManageOrg } from "../modules/admin/components/manage-orgs/edit/component";
import { PreferredRecyclers } from "../modules/admin/components/preferred-recyclers/component";
import { UpdateSubscription } from "../modules/admin/components/update-subscription/component";
import { ManageWasteFacilitiesList } from "../modules/admin/components/manage-waste-facilities/list/component";
import { PaymentManagement } from "../modules/admin/components/payment-management/component";
import { Billing } from "../modules/admin/components/billing/component";
import { EAReturnsComparisonReport } from "../modules/admin/components/reports/ea-returns-comparison-report/component";
const routes = [
    {
        path: "welcome",
        component: Welcome,
        canActivate: [GuestGuard],
    },
    {
        path: "about",
        component: About,
        canActivate: [GuestGuard],
    },
    {
        path: "login",
        component: Login,
        canActivate: [GuestGuard],
    },
    {
        path: "register",
        component: Registration,
        canActivate: [GuestGuard],
    },
    {
        path: "complete-registration",
        component: CompanyRegistration,
        canActivate: [RegistrationGuard],
    },
    {
        path: "forgot-password",
        component: ForgotPasswordModal,
        canActivate: [GuestGuard],
    },
    {
        path: "dashboard",
        component: Dashboard,
        canActivate: [AuthGuard],
    },
    {
        path: "vehicles",
        component: ListVehicles,
        canActivate: [AuthGuard],
    },
    {
        path: "manage-users",
        component: ManageUsersList,
        canActivate: [AuthGuard],
    },
    {
        path: "manage-organisation",
        component: ManageOrg,
        canActivate: [AuthGuard],
    },
    {
        path: "manage-waste-facilities",
        component: ManageWasteFacilitiesList,
        canActivate: [AuthGuard]
    },
    {
        path: "preferred-recyclers",
        component: PreferredRecyclers,
        canActivate: [AuthGuard],
    },
    {
        path: "update-subscription",
        component: UpdateSubscription,
        canActivate: [AuthGuard],
    },
    {
        path: "payment-management",
        component: PaymentManagement,
        canActivate: [AuthGuard],
    },
    {
        path: "billing",
        component: Billing,
        canActivate: [AuthGuard],
    },
    {
        path: "ea-returns-comparison-report",
        component: EAReturnsComparisonReport,
        canActivate: [AuthGuard]
    },
    { path: "**", redirectTo: "welcome", pathMatch: "full" },
];
export const appRouting = [];
export const routing = RouterModule.forRoot(routes);
