import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListWaste } from '../../../../../app/components/waste/list/component';
import { CarrierViewWaste } from '../view/component';
import { UserService } from "../../../../user/services/user-api";
import { CarrierWasteApi } from "../../../services/waste-api";
let CarrierListWaste = class CarrierListWaste extends ListWaste {
    constructor(UserService, WasteApi, dialog, dialogRef, Consignment) {
        super(UserService, WasteApi, dialog, dialogRef, Consignment);
        this.UserService = UserService;
        this.WasteApi = WasteApi;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.Consignment = Consignment;
    }
    view(wasteId) {
        let config = new MatDialogConfig;
        config.disableClose = true;
        config.data = {
            consignmentId: this.Consignment.consignmentId,
            wasteId: wasteId
        };
        this.dialog.open(CarrierViewWaste, config);
    }
};
CarrierListWaste = __decorate([
    Component({
        selector: 'carrier-list-waste',
        template: require('../../../../../app/components/waste/list/component.html'),
        styles: [require('../../../../../app/components/waste/list/component.scss')]
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [UserService,
        CarrierWasteApi,
        MatDialog,
        MatDialogRef, Object])
], CarrierListWaste);
export { CarrierListWaste };
