import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user-api';
import { CognitoUser } from 'amazon-cognito-identity-js';
let VerifyModal = class VerifyModal {
    constructor(UserService, dialogRef, CognitoUser) {
        this.UserService = UserService;
        this.dialogRef = dialogRef;
        this.CognitoUser = CognitoUser;
        this.loading = false;
    }
    ngOnInit() {
        this.form = (new FormBuilder).group({
            code: [null, [Validators.required]],
        });
    }
    onSubmit() {
        let verify = this.form.getRawValue();
        // start loading
        this.loading = true;
        this.error = null;
        this.UserService
            .confirmRegistration(this.CognitoUser, verify.code)
            .then(confirmed => this.dialogRef.close(confirmed), error => this.handleLoginError(error));
    }
    handleLoginError(error) {
        // hide loading
        this.loading = false;
        // display error
        this.error = error;
    }
};
VerifyModal = __decorate([
    Component({
        selector: 'register-modal',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [UserService,
        MatDialogRef,
        CognitoUser])
], VerifyModal);
export { VerifyModal };
