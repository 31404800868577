import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteConsignee = class ConsignmentNoteConsignee extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "consigneeCertificate";
        this.certificateType = "consignee";
        this.subscriptions = [];
    }
    ngOnInit() {
        this.subscriptions.push(this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.Certificate = !!consignmentNote
                ? consignmentNote.consigneeCertificate
                : null;
        }));
    }
    ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe());
    }
    editField() {
        let update = {
            jobId: this.jobId,
        };
        const oldString = this.Certificate.wasteOperationNumber;
        this.updateTextString(update, "wasteOperationNumber", oldString, "Waste Operation Number");
    }
    editAddress() {
        let update = {
            jobId: this.jobId,
        };
        const oldAddress = this.Certificate.wasteManagementAddress;
        Promise.resolve()
            .then(() => this.getNewAddress(oldAddress, update))
            .then(() => this.getConfirmation(update))
            .then(() => this.update(update, "wasteManagementAddress"))
            .catch(() => this.handleNotUpdated("Waste Management Address not updated."));
    }
};
ConsignmentNoteConsignee = __decorate([
    Component({
        selector: "consignment-note-consignee",
        template: require('../../../../directives/view-consignment-note/consignee.html'),
        styles: [require('../../../../directives/view-consignment-note/component.scss')],
    })
], ConsignmentNoteConsignee);
export { ConsignmentNoteConsignee };
