import { __decorate, __metadata } from "tslib";
import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserOrganisationsApi } from "../../../../carrier/services/organisaton-users-api";
import { EditUserModal } from "../edit-user/component";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
let ManageUsersList = class ManageUsersList {
    constructor(UserOrganisationsApi, dialog) {
        this.UserOrganisationsApi = UserOrganisationsApi;
        this.dialog = dialog;
        this.loading = true;
        this.userDataSource = new MatTableDataSource([]);
        this.haveLimit = false;
        this.displayedColumns = [
            "userId",
            "name",
            "email",
            'phone_number',
            "admin",
            "add",
        ];
        this.subscriptions = [];
    }
    ngOnInit() {
        this.userDataSource.sort = this.sort;
        this.userDataSource.paginator = this.paginator;
        this.initialise();
    }
    initialise() {
        this.loading = true;
        this.UserOrganisationsApi.loading.subscribe((data) => {
            this.loading = data;
        });
        this.subscriptions.push(this.UserOrganisationsApi.users$.subscribe((data) => {
            this.userDataSource.data = data.Items;
            this.haveLimit = data.userLimit.haveLimit;
            this.limitMessage = data.userLimit.message;
            this.isAdmin();
        }));
        this.UserOrganisationsApi.initialise();
    }
    applyUserFilter(event) {
        const searchValue = event.target.value;
        this.userDataSource.filter = searchValue.trim().toLowerCase();
    }
    isAdmin() {
        var _a;
        (_a = this.userDataSource.data) === null || _a === void 0 ? void 0 : _a.map((item) => {
            var _a;
            const admin = (_a = item.groups) === null || _a === void 0 ? void 0 : _a.find((group) => {
                return group.GroupName === "admin";
            });
            item.admin = !!admin;
        });
    }
    edit(user) {
        const orgGroups = this.userDataSource.data[0].groups;
        const dialogRef = this.dialog.open(EditUserModal, {
            data: {
                user,
                orgGroups,
            },
        });
        dialogRef.afterClosed().subscribe(() => {
            this.initialise();
        });
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], ManageUsersList.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], ManageUsersList.prototype, "paginator", void 0);
ManageUsersList = __decorate([
    Component({
        selector: "manage-users-list",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [UserOrganisationsApi,
        MatDialog])
], ManageUsersList);
export { ManageUsersList };
