import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "./api";
let WasteApi = class WasteApi {
    constructor(Api) {
        this.Api = Api;
    }
    list(consignmentId) {
        let endpoint = this.getReadEndpoint(consignmentId);
        return this.Api.request(endpoint, "GET");
    }
    get(consignmentId, wasteId) {
        let endpoint = this.getReadEndpoint(consignmentId, wasteId);
        return this.Api.request(endpoint);
    }
    save(consignmentId, Waste) {
        return !!Waste.wasteId
            ? this.put(consignmentId, Waste.wasteId, Waste)
            : this.post(consignmentId, Waste);
    }
    remove(consignmentId, wasteId) {
        let endpoint = this.getWriteEndpoint(consignmentId, wasteId);
        return this.Api.request(endpoint, "DELETE");
    }
    post(consignmentId, Waste) {
        let endpoint = this.getWriteEndpoint(consignmentId);
        return this.Api.request(endpoint, "POST", Waste);
    }
    put(consignmentId, wasteId, Waste) {
        let endpoint = this.getWriteEndpoint(consignmentId, wasteId);
        return this.Api.request(endpoint, "PUT", Waste);
    }
};
WasteApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], WasteApi);
export { WasteApi };
