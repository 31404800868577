import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
import { EditWasteTable } from "../../../../directives/consignment-note/edit-waste-table-modal/component";
let ConsignmentNoteDescription = class ConsignmentNoteDescription extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "descriptionOfTheWaste";
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.DescriptionOfTheWaste = !!consignmentNote
                ? consignmentNote.descriptionOfTheWaste
                : null;
        });
    }
    editProcess() {
        let update = {
            jobId: this.jobId,
        };
        const oldProcess = this.DescriptionOfTheWaste.process;
        this.updateTextString(update, "process", oldProcess, "The process giving rise to the waste(s) was", "Process");
    }
    editSicCode() {
        let update = {
            jobId: this.jobId,
        };
        const oldSicCode = this.DescriptionOfTheWaste.sicCode;
        this.updateTextString(update, "sicCode", oldSicCode, "SIC (2007) for the process giving rise to the waste", "Sic Code");
    }
    editWasteTable() {
        let update = {
            jobId: this.jobId,
        };
        const oldWaste = this.DescriptionOfTheWaste.wasteTable;
        Promise.resolve()
            .then(() => this.getNewWasteTable(oldWaste, update))
            .then(() => this.getConfirmation(update))
            .then(() => this.update(update, "wasteTable"))
            .catch(() => this.handleNotUpdated("Waste Table not updated."));
    }
    getNewWasteTable(oldWaste, update) {
        return new Promise((resolve, reject) => {
            let config = new MatDialogConfig();
            config.disableClose = true;
            config.data = oldWaste;
            this.dialog
                .open(EditWasteTable, config)
                .afterClosed()
                .subscribe((newWaste) => {
                !!newWaste &&
                    newWaste.forEach((item) => {
                        Object.keys(item).forEach((key) => {
                            item[key] = item[key] || null;
                        });
                    });
                update.update = newWaste;
                if (!newWaste) {
                    return reject();
                }
                resolve();
            });
        });
    }
};
ConsignmentNoteDescription = __decorate([
    Component({
        selector: "consignment-note-description",
        template: require('../../../../directives/view-consignment-note/description.html'),
        styles: [require('../../../../directives/view-consignment-note/description.scss')],
    })
], ConsignmentNoteDescription);
export { ConsignmentNoteDescription };
