import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
let Company = class Company {
    ngOnInit() {
        var _a, _b;
        this.FormattedAddress = Object.assign({}, this.Address);
        this.FormattedAddress.address = (_b = (_a = this.FormattedAddress) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], Company.prototype, "Address", void 0);
Company = __decorate([
    Component({
        selector: "consignment-note-company",
        template: require('../../../../directives/view-consignment-note/company-address.html'),
        styles: [],
    })
], Company);
export { Company };
