import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let QuoteSummary = class QuoteSummary {
    constructor() {
    }
    ngOnInit() {
        // Convert indexById to Array
        this.Quote.Quotes = !!this.Quote.Quotes && Object.keys(this.Quote.Quotes).map((key) => this.Quote.Quotes[key]) || null;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], QuoteSummary.prototype, "Quote", void 0);
QuoteSummary = __decorate([
    Component({
        selector: 'quote-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [])
], QuoteSummary);
export { QuoteSummary };
