import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { UserService } from "../../../modules/user/services/user-api";
let Dashboard = class Dashboard {
    constructor(UserService) {
        this.UserService = UserService;
    }
    inGroup(group) {
        return this.UserService.isInGroup(group);
    }
    checkCompanyTypeIsInTheList(companyTypes) {
        return this.UserService.checkCompanyTypeIsInTheList(companyTypes);
    }
};
Dashboard = __decorate([
    Component({
        selector: "app-dashboard",
        template: require('./component.html'),
    }),
    __metadata("design:paramtypes", [UserService])
], Dashboard);
export { Dashboard };
