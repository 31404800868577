import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, } from "@angular/material/dialog";
import { ListWaste } from "../../../../../app/components/waste/list/component";
import { UserService } from "../../../../user/services/user-api";
import { BrokerWasteApi } from "../../../services/waste-api";
import { BrokerViewWaste } from "../view/component";
let BrokerListWaste = class BrokerListWaste extends ListWaste {
    constructor(UserService, WasteApi, dialog, dialogRef, Consignment) {
        super(UserService, WasteApi, dialog, dialogRef, Consignment);
        this.UserService = UserService;
        this.WasteApi = WasteApi;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.Consignment = Consignment;
    }
    view(wasteId) {
        var _a;
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = {
            consignmentId: (_a = this.Consignment) === null || _a === void 0 ? void 0 : _a.consignmentId,
            wasteId: wasteId,
        };
        this.dialog.open(BrokerViewWaste, config);
    }
    consignmentCarrier() {
        return false;
    }
};
BrokerListWaste = __decorate([
    Component({
        selector: "broker-list-waste",
        template: require('../../../../../app/components/waste/list/component.html'),
        styles: [require('../../../../../app/components/waste/list/component.scss')],
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [UserService,
        BrokerWasteApi,
        MatDialog,
        MatDialogRef, Object])
], BrokerListWaste);
export { BrokerListWaste };
