import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { OrganisationApi } from '../../../app/services/organisation-api';
let AdminOrganisationApi = class AdminOrganisationApi extends OrganisationApi {
    getEndpoint(organisationId) {
        return environment.endpoint.admin.organisations(organisationId);
    }
    getCheckEndpoint(companyNumber) {
        return environment.endpoint.admin.checkCompanyNumber(companyNumber);
    }
};
AdminOrganisationApi = __decorate([
    Injectable()
], AdminOrganisationApi);
export { AdminOrganisationApi };
