import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerQuoteApi } from '../../../services/quote-api';
let ViewQuote = class ViewQuote {
    constructor(QuoteApi, dialog, dialogRef, data) {
        this.QuoteApi = QuoteApi;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
    }
    ngOnInit() {
        this.Quote = this.data;
    }
    acceptQuote(Quote) {
        this.loading = true;
        this.QuoteApi
            .acceptQuote(Quote)
            .then(() => {
            this.loading = false;
            this.dialogRef.close();
        });
    }
    declineQuote(Quote) {
        alert('Decline Quote not implemented yet');
    }
};
ViewQuote = __decorate([
    Component({
        selector: 'view-quote',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [BrokerQuoteApi,
        MatDialog,
        MatDialogRef, Object])
], ViewQuote);
export { ViewQuote };
