import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "./api";
let ConsignmentApi = class ConsignmentApi {
    constructor(Api) {
        this.Api = Api;
    }
    list(status) {
        let endpoint = this.getEndpoint(status);
        return this.Api.request(endpoint);
    }
    get(consignmentId) {
        let endpoint = this.getEndpoint(consignmentId);
        return this.Api.request(endpoint);
    }
    getPdf(consignmentId) {
        let endpoint = this.getEndpoint(consignmentId) + "/pdf";
        return this.Api.request(endpoint);
    }
    save(Consignment) {
        return !!Consignment.consignmentId
            ? this.put(Consignment)
            : this.post(Consignment);
    }
    remove(consignmentId) {
        let endpoint = this.getEndpoint(consignmentId);
        return this.Api.request(endpoint, "DELETE");
    }
    getCollection(consignmentId) {
        let endpoint = this.getEndpoint(consignmentId) + "/collection";
        return this.Api.request(endpoint);
    }
    put(Consignment) {
        let endpoint = this.getEndpoint(Consignment.consignmentId);
        return this.Api.request(endpoint, "PUT", Consignment);
    }
    post(Consignment) {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, "POST", Consignment);
    }
};
ConsignmentApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], ConsignmentApi);
export { ConsignmentApi };
