import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { ReportApi } from '../../../app/services/report-api';
let AdminReportApi = class AdminReportApi extends ReportApi {
    getEndpoint(month) {
        return environment.endpoint.admin.reports(month);
    }
};
AdminReportApi = __decorate([
    Injectable()
], AdminReportApi);
export { AdminReportApi };
