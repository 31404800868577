import { __decorate, __metadata } from "tslib";
import { Component, ViewEncapsulation } from "@angular/core";
import { UserService } from "../modules/user/services/user-api";
import { Router } from '@angular/router';
let AppComponent = class AppComponent {
    constructor(UserService, router) {
        this.UserService = UserService;
        this.router = router;
    }
    ngOnInit() { }
    userLoggedIn() {
        return this.UserService.isLoggedIn();
    }
    inGroup(group) {
        return this.UserService.isInGroup(group);
    }
    checkCompanyTypeIsInTheList(companyTypes) {
        return this.UserService.checkCompanyTypeIsInTheList(companyTypes);
    }
    isFooterType(type) {
        const pagesBasedBasicFooter = [
            "/register",
            "/login",
            "/complete-registration"
        ];
        if (pagesBasedBasicFooter.find(f => f === this.router.url)) {
            return type === "basic";
        }
        return type === "wide";
    }
};
AppComponent = __decorate([
    Component({
        selector: "anywaste-app",
        template: require('./component.html'),
        styles: [require('./component.scss')],
        encapsulation: ViewEncapsulation.None,
    }),
    __metadata("design:paramtypes", [UserService,
        Router])
], AppComponent);
export { AppComponent };
