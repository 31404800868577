import { __decorate, __metadata } from "tslib";
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrokerConsignmentNoteApi } from '../../../../services/consignment-note-api';
let ConsignmentNote = class ConsignmentNote {
    constructor(ConsignmentNoteApi, dialog) {
        this.ConsignmentNoteApi = ConsignmentNoteApi;
        this.dialog = dialog;
    }
};
ConsignmentNote = __decorate([
    Component({}),
    __metadata("design:paramtypes", [BrokerConsignmentNoteApi,
        MatDialog])
], ConsignmentNote);
export { ConsignmentNote };
