import { __decorate, __metadata } from "tslib";
import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CarrierConsignmentApi } from "../../../services/consignment-api";
import { Dashboard } from "../../../services/dashboard";
import { AllocateJob } from "../allocate/component";
import { CloseJob } from "../close/component";
import { ViewConsignmentNote } from "../../consignment-notes/component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Api } from "../../../../../app/services/api";
import { ViewWasteTransferNote } from "../../waste-transfer-notes/component";
import moment from "moment";
import { UserService } from "../../../../../modules/user/services/user-api";
let JobList = class JobList {
    constructor(ConsignmentApi, Dashboard, dialog, UserService, Api) {
        this.ConsignmentApi = ConsignmentApi;
        this.Dashboard = Dashboard;
        this.dialog = dialog;
        this.UserService = UserService;
        this.Api = Api;
        this.status = null;
        this.loading = false;
        this.jobListDataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            "brokerName",
            "description",
            "collectionDate",
            "status",
            "add",
        ];
    }
    ngOnInit() {
        this.jobListDataSource.sort = this.sort;
        this.jobListDataSource.paginator = this.paginator;
        this.Dashboard._list.subscribe((list) => {
            list[this.status].map((data, index) => {
                list[this.status][index].formattedCollectionDate =
                    data.collectionDate &&
                        moment(data.collectionDate).format("DD/MM/YYYY");
                list[this.status][index].formattedDropOffDate =
                    data.dropOffDate && moment(data.dropOffDate).format("DD/MM/YYYY");
            });
            this.jobListDataSource.data = list[this.status];
        });
        this.Dashboard._loading.subscribe((loading) => {
            this.loading = loading;
        });
    }
    refresh() {
        this.Dashboard.load();
    }
    listWaste(Job) {
        this.ConsignmentApi.get(Job.consignmentId).then((Consignment) => {
            let config = new MatDialogConfig();
            config.data = Consignment;
            config.disableClose = true;
            const modalComponent = Consignment.hazardous
                ? ViewConsignmentNote
                : ViewWasteTransferNote;
            let dialogRef = this.dialog.open(modalComponent, config);
            dialogRef.afterClosed().subscribe(() => this.refresh());
        }, () => alert("Could not get the Consignment."));
    }
    view(Consignment) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = Consignment;
        const modalComponent = Consignment.hazardous
            ? ViewConsignmentNote
            : ViewWasteTransferNote;
        let dialog = this.dialog.open(modalComponent, config);
        dialog.afterClosed().subscribe(() => this.refresh());
    }
    applyJobListFilter(event) {
        const searchValue = event.target.value;
        this.jobListDataSource.filter = searchValue.trim().toLowerCase();
    }
    allocate(consignmentId) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        this.ConsignmentApi.get(consignmentId).then((Consignment) => {
            config.data = Consignment;
            let dialog = this.dialog.open(AllocateJob, config);
            dialog.afterClosed().subscribe(() => this.refresh());
        });
    }
    completeJob(Job) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        this.ConsignmentApi.get(Job.consignmentId).then((Consignment) => {
            config.data = Consignment;
            let dialog = this.dialog.open(CloseJob, config);
            dialog.afterClosed().subscribe(() => this.refresh());
        });
    }
};
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", MatSort)
], JobList.prototype, "sort", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", MatPaginator)
], JobList.prototype, "paginator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], JobList.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], JobList.prototype, "status", void 0);
JobList = __decorate([
    Component({
        selector: "job-list",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [CarrierConsignmentApi,
        Dashboard,
        MatDialog,
        UserService,
        Api])
], JobList);
export { JobList };
