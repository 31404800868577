import { __decorate } from "tslib";
import { Injectable } from "@angular/core";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { environment } from "../../../app/constant/environment";
import jwtDecode from "jwt-decode";
let AuthService = class AuthService {
    getJwt() {
        return this.jwt;
    }
    getAuthToken() {
        return new Promise((resolve, reject) => {
            this.setCognitoUser();
            this.CognitoUser !== null &&
                this.CognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    resolve(session.idToken.jwtToken);
                });
        });
    }
    refreshToken() {
        const that = this;
        this.CognitoUser.getSession(function (err, data) {
            const getRefreshToken = data.getRefreshToken();
            that.CognitoUser.refreshSession(getRefreshToken, (err, session) => {
                const IdToken = session.getIdToken().getJwtToken();
                that.jwt = jwtDecode(IdToken);
            });
        });
    }
    setCredentials(result) {
        return new Promise((resolve, reject) => {
            try {
                let logins = {};
                logins["cognito-idp." +
                    environment.cognito.region +
                    ".amazonaws.com/" +
                    environment.cognito.poolData.UserPoolId] = result.getIdToken().getJwtToken();
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: environment.cognito.identityPoolId,
                    Logins: logins,
                });
                // stash the suff that we need to locally
                this.jwt = jwtDecode(result.getIdToken().getJwtToken());
                if (!this.jwt) {
                    return reject();
                }
                resolve(true);
            }
            catch (error) {
                reject(new Error(error));
            }
        });
    }
    getCognitoUser() {
        return this.CognitoUser;
    }
    getCognitoUserName() {
        return this.CognitoUser.getUsername();
    }
    setCognitoUser(username) {
        !!username && (username = username.toLowerCase());
        let userPool = new CognitoUserPool(environment.cognito.poolData);
        let userData = {
            Username: username,
            Pool: userPool,
        };
        this.CognitoUser = !!username
            ? new CognitoUser(userData)
            : userPool.getCurrentUser();
        return !!this.CognitoUser;
    }
    signOut() {
        this.jwt = null;
        !!this.CognitoUser && this.CognitoUser.signOut();
    }
};
AuthService = __decorate([
    Injectable()
], AuthService);
export { AuthService };
