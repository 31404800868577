import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
let VerifyPassword = class VerifyPassword {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ngOnInit() {
        let formBuilder = new FormBuilder();
        this.form = formBuilder.group({
            password: [null, [Validators.required]]
        });
    }
    onSubmit() {
        this.data.verifyPassword(this.form.getRawValue()).then(() => {
            this.data.success();
        })
            .catch(error => {
            this.error = error;
        });
    }
};
VerifyPassword = __decorate([
    Component({
        selector: "verify-password",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], VerifyPassword);
export { VerifyPassword };
