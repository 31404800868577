import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
let ProvideQuoteFactory = class ProvideQuoteFactory {
    static form(ProvideQuote) {
        let _FormBuilder = new FormBuilder();
        let FormGroup = _FormBuilder.group({
            'price': [null, [Validators.required]],
            'collectionDate': [null, [Validators.required]],
            'carrierNotes': [null, [Validators.required]],
            'isDegradable': [null, []],
            'disposalAndRecovery': [null, []]
        });
        !!ProvideQuote && FormGroup.setValue(ProvideQuote);
        return FormGroup;
    }
};
ProvideQuoteFactory = __decorate([
    Injectable()
], ProvideQuoteFactory);
export { ProvideQuoteFactory };
