import { __decorate } from "tslib";
import { Component } from '@angular/core';
let About = class About {
};
About = __decorate([
    Component({
        selector: 'guest-about',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], About);
export { About };
