import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { WasteTransferNoteApi as WasteTransferNoteApiService } from "../../../app/services/v2/waste-transfer-note.service";
import { environment } from "../../../app/constant/environment";
import { Api } from "../../../app/services/api";
let BrokerWasteTransferNoteApi = class BrokerWasteTransferNoteApi extends WasteTransferNoteApiService {
    constructor(Api) {
        super(Api);
        this.Api = Api;
    }
    getEndpoint(consignmentId) {
        return environment.endpoint.broker.wasteTransferNotes(consignmentId);
    }
};
BrokerWasteTransferNoteApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], BrokerWasteTransferNoteApi);
export { BrokerWasteTransferNoteApi };
