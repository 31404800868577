import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteDeclined = class ConsignmentNoteDeclined extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "quote";
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.Decline = !!consignmentNote
                ? !!consignmentNote.collectionDeclined
                    ? consignmentNote.collectionDeclined
                    : consignmentNote.dropOffDeclined
                : null;
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ConsignmentNoteDeclined.prototype, "Type", void 0);
ConsignmentNoteDeclined = __decorate([
    Component({
        selector: "consignment-note-declined",
        template: require('../../../../directives/view-consignment-note/declined.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    })
], ConsignmentNoteDeclined);
export { ConsignmentNoteDeclined };
