import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { RequestQuoteFactory } from "../../../../../factories/request-quote";
import { BrokerCarrierApi } from "../../../services/carrier-api";
import { UserService } from "../../../../user/services/user-api";
import { BrokerQuoteApi } from "../../../services/quote-api";
let RequestQuote = class RequestQuote {
    constructor(dialog, dialogRef, QuoteApi, CarrierApi, UserService, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.QuoteApi = QuoteApi;
        this.CarrierApi = CarrierApi;
        this.UserService = UserService;
        this.data = data;
        this.loading = false;
        this._carriers = new BehaviorSubject([]);
    }
    ngOnInit() {
        this.consignment = this.data;
        this._carriers.subscribe((items) => (this.carriers = items));
        this.form = RequestQuoteFactory.form();
        this.form.controls["brokerName"].setValue(this.UserService.getOrganisation().name);
        this.loadCarriers();
    }
    onSubmit() {
        this.loading = true;
        // create a quote api call
        const quote = this.form.getRawValue();
        const RequestQuote = {
            brokerId: quote.brokerId,
            brokerName: quote.brokerName,
            carrierName: quote.Carrier.name,
            carrierId: quote.Carrier.carrierId,
            description: quote.description,
            brokerNotes: quote.brokerNotes,
            wasteImageContentType: quote.wasteImage.type,
        };
        const wasteImage = quote.wasteImage;
        this.QuoteApi.requestQuote(this.consignment.consignmentId, RequestQuote)
            .then((Quote) => this.QuoteApi.saveWasteImage(Quote.wasteImagePutUrl, wasteImage), () => this.close)
            .then(() => this.close(), () => this.close());
    }
    close() {
        this.loading = false;
        this.dialogRef.close();
    }
    loadCarriers() {
        let carrierControl = this.form.controls["Carrier"];
        carrierControl.disable();
        this.CarrierApi.list().then((data) => {
            this._carriers.next(data);
            carrierControl.enable();
        });
    }
};
RequestQuote = __decorate([
    Component({
        selector: "request-quote",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(5, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialog,
        MatDialogRef,
        BrokerQuoteApi,
        BrokerCarrierApi,
        UserService, Object])
], RequestQuote);
export { RequestQuote };
