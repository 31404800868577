import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../../../app/constant/environment";
import { Api } from "../../../app/services/api";
let VehicleApi = class VehicleApi {
    constructor(Api) {
        this.Api = Api;
    }
    list() {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint);
    }
    get(vehicleId) {
        let endpoint = this.getEndpoint(vehicleId);
        return this.Api.request(endpoint);
    }
    save(Vehicle) {
        return !!Vehicle.vehicleId ? this.put(Vehicle) : this.post(Vehicle);
    }
    remove(vehicleId) {
        let endpoint = this.getEndpoint(vehicleId);
        return this.Api.request(endpoint, "DELETE");
    }
    put(Vehicle) {
        let endpoint = this.getEndpoint(Vehicle.vehicleId);
        return this.Api.request(endpoint, "PUT", Vehicle);
    }
    post(Vehicle) {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, "POST", Vehicle);
    }
    getEndpoint(vehicleId) {
        return environment.endpoint.carrier.vehicles(vehicleId);
    }
};
VehicleApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], VehicleApi);
export { VehicleApi };
