import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordNewFormFactory } from './new';
let PasswordResetFormFactory = class PasswordResetFormFactory {
    static form() {
        return (new FormBuilder).group({
            code: [null, Validators.required],
            newPassword: PasswordNewFormFactory.form(),
        });
    }
};
PasswordResetFormFactory = __decorate([
    Injectable()
], PasswordResetFormFactory);
export { PasswordResetFormFactory };
