import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { ConsignmentNoteEditWaste } from "../../../carrier/components/consignment-notes/view/edit-waste-modal/component";
let EditWasteTable = class EditWasteTable {
    constructor(dialogRef, dialog, FormBuilder, WasteList) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.FormBuilder = FormBuilder;
        this.WasteList = WasteList;
    }
    ngOnInit() {
        this.form = this.FormBuilder.array([], this.numberOfItemsValidator);
        this.WasteList.map((Waste) => this.form.push(this.getForm(Waste)));
    }
    add() {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = {
            quoted: false,
            WasteForm: this.getForm(),
        };
        this.dialog
            .open(ConsignmentNoteEditWaste, config)
            .afterClosed()
            .subscribe((result) => {
            !!result && this.form.insert(this.form.length, result);
            !!result && this.form.markAsDirty();
        });
    }
    edit(index) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = {
            quoted: false,
            WasteForm: this.form.at(index),
        };
        this.dialog.open(ConsignmentNoteEditWaste, config);
    }
    remove(index) {
        this.form.removeAt(index);
        this.form.markAsDirty();
    }
    onSubmit() {
        let data = this.form.dirty ? this.form.value : null;
        this.dialogRef.close(data);
    }
    getForm(Waste) {
        let form = this.FormBuilder.group({
            qty: [null, [Validators.required]],
            ewcCode: [null, [Validators.required]],
            description: [null, [Validators.required]],
            components: [null],
            physicalForm: [null, [Validators.required]],
            hazardCodes: [null],
            container: [null, [Validators.required]],
            physicalDestructionRequired: [null],
            dataDestructionRequired: [null],
        });
        !!Waste && form.patchValue(Waste);
        return form;
    }
    numberOfItemsValidator(FormArray) {
        return !!FormArray.length
            ? null
            : { numberOfItems: "At least one item required." };
    }
};
EditWasteTable = __decorate([
    Component({
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef,
        MatDialog,
        FormBuilder, Array])
], EditWasteTable);
export { EditWasteTable };
