import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { WasteFacilityApi } from '../../../services/waste-facility-api';
import { WasteFacilityFormFactory } from '../../../../../factories/waste-facility';
import { EditAddress } from '../../../../directives/address/edit/component';
let EditWasteFacility = class EditWasteFacility {
    constructor(WasteFacilityApi, dialogRef, dialog, data) {
        this.WasteFacilityApi = WasteFacilityApi;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.loading = false;
        this._item = new BehaviorSubject({
            organisationId: null,
            wasteFacilityId: null,
            address: null,
            wastePermitNumber: null,
            additionalLicences: null,
            notes: null,
        });
        this.form = WasteFacilityFormFactory.form();
    }
    ngOnInit() {
        this.wasteFacilityId = this.data;
        this._item.subscribe(item => this.form.setValue(item));
        !!this.wasteFacilityId && this.set();
    }
    onSubmit() {
        this.dialogRef.close(this.form.value);
    }
    set() {
        this.loading = true;
        this.form.disable();
        this.WasteFacilityApi
            .get(this.wasteFacilityId)
            .then((data) => {
            this.loading = false;
            this.form.enable();
            this._item.next(data);
        });
    }
    editAddress() {
        let config = new MatDialogConfig;
        config.disableClose = true;
        config.data = {
            address: this.form.get('address').value,
        };
        let dialogRef = this.dialog.open(EditAddress, config);
        dialogRef.afterClosed().subscribe((result) => {
            !!result && this.form.get('address').setValue(result);
        });
        return dialogRef;
    }
};
EditWasteFacility = __decorate([
    Component({
        selector: 'edit-waste-facility',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [WasteFacilityApi, MatDialogRef, MatDialog, Object])
], EditWasteFacility);
export { EditWasteFacility };
