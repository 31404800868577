import { __decorate, __metadata } from "tslib";
import { Component, Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { RegistrationFormFactory } from "../../../../factories/registration";
import { UserService } from "../../services/user-api";
import { VerifyModal } from "../verify/component";
let Registration = class Registration {
    constructor(UserService, dialog, router) {
        this.UserService = UserService;
        this.dialog = dialog;
        this.router = router;
    }
    ngOnInit() {
        this.form = RegistrationFormFactory.form();
    }
    onSubmit() {
        let Register = this.form.getRawValue();
        this.loading = true;
        this.error = null;
        this.UserService.register(Register).then((result) => this.confirmRegistration(result.user), (error) => this.handleError(error));
    }
    confirmRegistration(CognitoUser) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = CognitoUser;
        let dialogRef = this.dialog.open(VerifyModal, config);
        dialogRef.afterClosed().subscribe((success) => this.forwardToLogin());
    }
    forwardToLogin() {
        this.loading = false;
        this.router.navigate(["/login"]);
    }
    handleError(error) {
        this.loading = false;
        switch (error.code) {
            case "InvalidPasswordException":
                this.error = "Your password must contain at least 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter";
                break;
            case "UsernameExistsException":
                this.error = "Username already exists. Please log in";
                break;
            default:
                this.error = error;
        }
    }
};
Registration = __decorate([
    Component({
        selector: "user-register",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    Injectable(),
    __metadata("design:paramtypes", [UserService,
        MatDialog,
        Router])
], Registration);
export { Registration };
