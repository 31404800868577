import { __awaiter, __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { BrokerWasteTransferNoteApi } from "../../../services/waste-transfer-note-api";
import { environment } from "../../../../../app/constant/environment";
import { Api } from "../../../../../app/services/api";
let DangerousGoodsNoteDescription = class DangerousGoodsNoteDescription {
    constructor(WasteTransferNoteApi, Api) {
        this.WasteTransferNoteApi = WasteTransferNoteApi;
        this.Api = Api;
    }
    ngOnInit() {
        this.WasteTransferNoteApi._wasteTransferNote.subscribe((wasteTransferNote) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.jobId = wasteTransferNote ? wasteTransferNote.jobId : null;
            if (this.jobId) {
                const endpoint = environment.endpoint.broker.dangerousGoodsNote(this.jobId);
                this.loading = true;
                this.dgn = yield this.Api.request(endpoint, "GET");
                this.dgn = ((_a = this.dgn) === null || _a === void 0 ? void 0 : _a.dangerousGoods)
                    ? this.dgn.dangerousGoods
                    : this.dgn.DangerousGoods;
                this.loading = false;
            }
        }));
    }
};
DangerousGoodsNoteDescription = __decorate([
    Component({
        selector: "dangerous-goods-note-description",
        template: require('../../../../directives/waste-transfer-notes-view/dangerous-goods-note.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    }),
    __metadata("design:paramtypes", [BrokerWasteTransferNoteApi,
        Api])
], DangerousGoodsNoteDescription);
export { DangerousGoodsNoteDescription };
