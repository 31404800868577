import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, Output, Input, EventEmitter } from "@angular/core";
import { CheckoutService } from "../../../../../../app/services/v2/payments/checkout.service";
import { UserService } from "../../../../services/user-api";
let PaymentInfo = class PaymentInfo {
    constructor(checkoutApi, userService) {
        this.checkoutApi = checkoutApi;
        this.userService = userService;
        this.update = new EventEmitter();
        this.isLoading = false;
        this.plans = [];
        this._plans = () => [
            {
                stripeProdName: "Bronze Plan",
                planName: "Bronze",
                price: "£25",
                unit: "month",
                freeTrialDuration: "30 day",
                isSelected: false,
                priceId: "",
            },
            {
                stripeProdName: "Silver Plan",
                planName: "Silver",
                price: "£50",
                unit: "month",
                freeTrialDuration: "30 day",
                isSelected: false,
            },
            {
                stripeProdName: "Gold Plan",
                planName: "Gold",
                price: "£100",
                unit: "month",
                freeTrialDuration: "30 day",
                isSelected: false,
            },
            {
                stripeProdName: "Platinum Plan",
                planName: "Platinum",
                price: "£300",
                unit: "month",
                freeTrialDuration: "30 day",
                isSelected: false,
            },
        ];
        this.startCheckout = (planType) => __awaiter(this, void 0, void 0, function* () {
            const organisation = this.userService.getOrganisation();
            const { data: plans } = yield this.checkoutApi.listPlans();
            const { data: prices } = yield this.checkoutApi.listPlanPrices();
            const { id: productId } = plans.find((plan) => planType === plan.name);
            const { id: priceId } = prices.find((price) => price.product === productId);
            this.checkoutApi.createCheckoutSession(priceId, organisation.stripeCustomerId).then((response) => {
                window.location.replace(response.url);
            });
        });
        this.updateSubscriptionPlan = (priceId) => {
            if (confirm("Are you sure you want to change your plan?")) {
                this.isLoading = true;
                this.update.emit(priceId);
            }
        };
    }
    ngOnInit() {
        if (this.updatePlan) {
            this.checkoutApi.listPlans().then(plans => {
                this.plans = this._plans().map((item) => {
                    const plan = plans.data.find(p => p.name === item.stripeProdName);
                    if (plan) {
                        item.priceId = plan.default_price;
                        if (item.priceId === this.selectedPlan)
                            item.isSelected = true;
                    }
                    return item;
                });
                this.isLoading = false;
            });
        }
        else {
            this.plans = this._plans();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PaymentInfo.prototype, "updatePlan", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PaymentInfo.prototype, "selectedPlan", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], PaymentInfo.prototype, "update", void 0);
PaymentInfo = __decorate([
    Component({
        selector: "app-payment",
        template: require('./payment.component.html'),
        styles: [require('./payment.component.scss')],
    }),
    __metadata("design:paramtypes", [CheckoutService,
        UserService])
], PaymentInfo);
export { PaymentInfo };
