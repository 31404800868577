import { __decorate, __metadata } from "tslib";
import { Component, ViewEncapsulation } from '@angular/core';
import { Dashboard } from '../../services/dashboard';
let CarrierDashboard = class CarrierDashboard {
    constructor(Dashboard) {
        this.Dashboard = Dashboard;
        this.loading = false;
    }
    ngOnInit() {
        this.Dashboard._loading.subscribe(loading => {
            this.loading = loading;
        });
        this.Dashboard._list.subscribe((data) => {
            this.requested = data.requested;
            this.accepted = data.accepted;
            this.live = data.live;
            this.closed = data.closed;
        });
        this.Dashboard.load();
    }
    refresh() {
        this.Dashboard.load();
    }
};
CarrierDashboard = __decorate([
    Component({
        selector: 'carrier-dashboard',
        template: require('./component.html'),
        styles: [require('./component.scss')],
        encapsulation: ViewEncapsulation.None,
    }),
    __metadata("design:paramtypes", [Dashboard])
], CarrierDashboard);
export { CarrierDashboard };
