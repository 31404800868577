import { __decorate } from "tslib";
import { Component } from "@angular/core";
let CustomerMenu = class CustomerMenu {
};
CustomerMenu = __decorate([
    Component({
        selector: "customer-menu",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], CustomerMenu);
export { CustomerMenu };
