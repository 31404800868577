import { __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
let DropOffSummary = class DropOffSummary {
    constructor() {
    }
    ngOnInit() {
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], DropOffSummary.prototype, "DropOff", void 0);
DropOffSummary = __decorate([
    Component({
        selector: 'drop-off-summary',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [])
], DropOffSummary);
export { DropOffSummary };
