import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { environment } from '../../../app/constant/environment';
import { ConsignmentNoteApi } from '../../../app/services/consignment-note-api';
let BrokerConsignmentNoteApi = class BrokerConsignmentNoteApi extends ConsignmentNoteApi {
    getEndpoint(jobId) {
        return environment.endpoint.broker.consignmentNotes(jobId);
    }
};
BrokerConsignmentNoteApi = __decorate([
    Injectable()
], BrokerConsignmentNoteApi);
export { BrokerConsignmentNoteApi };
