var RegistrationFormFactory_1;
import { __decorate } from "tslib";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { PasswordNewFormFactory } from "./password/new";
let RegistrationFormFactory = RegistrationFormFactory_1 = class RegistrationFormFactory {
    static form(Register) {
        let _FormBuilder = new FormBuilder();
        let FormGroup = _FormBuilder.group({
            given_name: [null, [Validators.required]],
            family_name: [null, [Validators.required]],
            email: [null, [Validators.required]],
            phone_number: [null, [Validators.required]],
            acceptTerms: [null, [RegistrationFormFactory_1.acceptTermsAndConditions]],
            newPassword: PasswordNewFormFactory.form(),
        });
        !!Register && FormGroup.setValue(Register);
        return FormGroup;
    }
    static acceptTermsAndConditions(AcceptTerms) {
        return !!AcceptTerms.value
            ? null
            : {
                validAcceptTerms: {
                    valid: "You must accept AnyWaste.com terms and conditions.",
                },
            };
    }
};
RegistrationFormFactory = RegistrationFormFactory_1 = __decorate([
    Injectable()
], RegistrationFormFactory);
export { RegistrationFormFactory };
