import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote } from "./consignment-note/component";
let ConsignmentNoteConsignor = class ConsignmentNoteConsignor extends ConsignmentNote {
    constructor() {
        super(...arguments);
        this.section = "consignorCertificate";
        this.certificateType = "consignor";
    }
};
ConsignmentNoteConsignor = __decorate([
    Component({
        selector: "consignment-note-consignors",
        template: require('../../../../directives/view-consignment-note/consignor.html'),
        styles: [require('../../../../directives/view-consignment-note/component.scss')],
    })
], ConsignmentNoteConsignor);
export { ConsignmentNoteConsignor };
