import { __decorate } from "tslib";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "./services/auth-guard";
import { AuthService } from "./services/auth";
import { GuestGuard } from "./services/guest-guard";
import { LoginModal } from "./components/login/modal/component";
import Login from "./components/login/component";
import { Registration } from "./components/register/component";
import { ForgotPasswordModal } from "./components/password/forgot/component";
import { LoginForm } from "./components/login/form/component";
import { AnyWasteMaterialModule } from "../material.module";
import { FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { UserService } from "./services/user-api";
import { CompaniesHouseService } from "../../app/services/v2/companies-house.service";
import { EnvironmentAgencyService } from "../../app/services/v2/environment-agency.service";
import { UserMenu } from "./components/menu/component";
import { routing } from "../../app/routing.module";
import { Api } from "../../app/services/api";
import { CompleteRegistrationModal } from "./components/password/complete-registration/component";
import { ChangePasswordModal } from "./components/password/change/component";
import { ResetPasswordModal } from "./components/password/reset/component";
import { VerifyModal } from "./components/verify/component";
import { CompanyRegistration } from "./v2/components/company-registration/company-registration.component";
import { CompanyInfo } from "./v2/components/company-registration/company-info/company-info.component";
import { CompanyRegistrationService } from "../../app/services/v2/company-registration.service";
import { SiteRegistrationService } from "../../app/services/v2/site-registration.service";
import { CheckoutService } from "../../app/services/v2/payments/checkout.service";
import { SiteInfo } from "./v2/components/company-registration/site-list/site-list.component";
import { NewSiteModalComponent } from "./v2/components/company-registration/register-site/new-site-modal/new-site-modal.component";
import { EnvironmentAgencyFormGroup } from "./v2/components/company-registration/register-site/new-site-modal/environment-agency-form/environment-agency-form.component";
import { RegisterContactFormGroup } from "./v2/components/company-registration/register-site/new-site-modal/register-contact/register-contact.component";
import { PaymentInfo } from "./v2/components/company-registration/payment/payment.component";
import { RegistrationGuard } from "./services/registration-guard";
import { DirectivesModule } from "../directives/module";
import { OpeningHoursFormGroup } from "./v2/components/company-registration/register-site/new-site-modal/opening-hours/opening-hours.component";
let UserModule = class UserModule {
};
UserModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            routing,
            AnyWasteMaterialModule,
            ReactiveFormsModule,
            DirectivesModule,
        ],
        declarations: [
            UserMenu,
            Login,
            LoginForm,
            Registration,
            CompanyRegistration,
            CompanyInfo,
            SiteInfo,
            PaymentInfo,
            VerifyModal,
            LoginModal,
            ForgotPasswordModal,
            CompleteRegistrationModal,
            ChangePasswordModal,
            ResetPasswordModal,
            NewSiteModalComponent,
            EnvironmentAgencyFormGroup,
            RegisterContactFormGroup,
            OpeningHoursFormGroup,
        ],
        entryComponents: [
            VerifyModal,
            LoginModal,
            ForgotPasswordModal,
            CompleteRegistrationModal,
            ChangePasswordModal,
            ResetPasswordModal,
            NewSiteModalComponent,
        ],
        providers: [
            Api,
            AuthGuard,
            RegistrationGuard,
            AuthService,
            GuestGuard,
            UserService,
            CompaniesHouseService,
            EnvironmentAgencyService,
            CompanyRegistrationService,
            FormGroupDirective,
            SiteRegistrationService,
            CheckoutService,
        ],
        exports: [UserMenu, CompanyInfo, PaymentInfo],
    })
], UserModule);
export { UserModule };
