import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteCarrier = class ConsignmentNoteCarrier extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "carrierCertificate";
        this.certificateType = "carrier";
        this.subscriptions = [];
    }
    ngOnInit() {
        this.subscriptions.push(this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.Certificate = !!consignmentNote
                ? consignmentNote.carrierCertificate
                : null;
        }));
    }
    ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe());
    }
    editCarrierRegistration() {
        this.editField("Carrier Registration Number", "carrierRegistrationNumber");
    }
    editVehicleRegistration() {
        this.editField("Vehicle Registration Number", "vehicleRegistrationNumber");
    }
    editField(title, fieldName) {
        let update = {
            jobId: this.jobId,
        };
        const oldString = this.Certificate[fieldName];
        this.updateTextString(update, fieldName, oldString, title);
    }
};
ConsignmentNoteCarrier = __decorate([
    Component({
        selector: "consignment-note-carrier",
        template: require('../../../../directives/view-consignment-note/carrier.html'),
        styles: [require('../../../../directives/view-consignment-note/carrier.scss')],
    })
], ConsignmentNoteCarrier);
export { ConsignmentNoteCarrier };
