import { __decorate, __metadata } from "tslib";
import { Component, Input, EventEmitter, Output } from '@angular/core';
let CarrierConsignmentItem = class CarrierConsignmentItem {
    constructor() {
        this.listWaste = new EventEmitter();
        this.editItem = new EventEmitter();
        this.allocateItem = new EventEmitter();
        this.deleteItem = new EventEmitter();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], CarrierConsignmentItem.prototype, "item", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], CarrierConsignmentItem.prototype, "listWaste", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], CarrierConsignmentItem.prototype, "editItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], CarrierConsignmentItem.prototype, "allocateItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], CarrierConsignmentItem.prototype, "deleteItem", void 0);
CarrierConsignmentItem = __decorate([
    Component({
        selector: '[consignment-item]',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], CarrierConsignmentItem);
export { CarrierConsignmentItem };
