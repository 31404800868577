import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "./user-api";
let GuestGuard = class GuestGuard {
    constructor(Router, UserService, dialog) {
        this.Router = Router;
        this.UserService = UserService;
        this.dialog = dialog;
    }
    canActivate(route, state) {
        if (!this.UserService.isLoggedIn()) {
            return true;
        }
        else {
            this.Router.navigate(['/dashboard']);
        }
    }
};
GuestGuard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Router, UserService, MatDialog])
], GuestGuard);
export { GuestGuard };
