import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteCertificate = class ConsignmentNoteCertificate extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.subscriptions = [];
    }
    ngOnInit() {
        this.subscriptions.push(this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.Certificate = !!consignmentNote
                ? consignmentNote[this.certificateType + "Certificate"]
                : null;
        }));
    }
    ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe());
    }
    editName() {
        let update = {
            jobId: this.jobId,
        };
        const oldString = this.Certificate.signeeName;
        this.updateTextString(update, "signeeName", oldString, this.certificateType + " Name");
    }
    editAddress() {
        let update = {
            jobId: this.jobId,
        };
        const oldAddress = this.Certificate.signeeCompany;
        Promise.resolve()
            .then(() => this.getNewAddress(oldAddress, update))
            .then(() => this.getConfirmation(update))
            .then(() => this.update(update, "signeeCompany"))
            .catch(() => this.handleNotUpdated("Address not updated."));
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ConsignmentNoteCertificate.prototype, "section", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ConsignmentNoteCertificate.prototype, "certificateType", void 0);
ConsignmentNoteCertificate = __decorate([
    Component({
        selector: "consignment-note-certificate",
        template: require('../../../../directives/view-consignment-note/certificate.html'),
        styles: [require('../../../../directives/view-consignment-note/component.scss')],
    })
], ConsignmentNoteCertificate);
export { ConsignmentNoteCertificate };
