import { __decorate, __metadata } from "tslib";
import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "../../../../user/services/user-api";
import { FormBuilder, Validators } from "@angular/forms";
let ManageOrg = class ManageOrg {
    constructor(dialog, fb, UserService) {
        this.dialog = dialog;
        this.fb = fb;
        this.UserService = UserService;
    }
    ngOnInit() {
        this.buildForm();
        this.organisation = this.UserService.getOrganisation();
    }
    buildForm() {
        this.form = this.fb.group({
            companyDetails: this.fb.group({
                organisationId: [null, null],
                companyType: [null, [Validators.required]],
                name: [null, [Validators.required]],
                country: [
                    {
                        name: "England",
                        alpha2Code: "GB",
                        alpha3Code: "GBR",
                        numericCode: "826",
                        callingCode: "+44",
                    },
                    [Validators.required],
                ],
                phone: [null, [Validators.required]],
                companyNumber: [null, [Validators.required]],
                registeredOfficeAddress: [null, [Validators.required]],
                collectionArea: [null, [Validators.required]],
                sicCode: [null, [Validators.required]],
                industry: [null, [Validators.required]],
                billingAddress: [null, [Validators.required]],
                vatNumber: [null, null]
            }),
        });
    }
    onGetCompanyType({ companyType }) {
        this.companyType = companyType;
    }
    onGetCompanyFormStatus(status) {
        this.companyFormStatus = status === "VALID";
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ManageOrg.prototype, "companyType", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ManageOrg.prototype, "companyFormStatus", void 0);
ManageOrg = __decorate([
    Component({
        selector: "manage-org",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [MatDialog,
        FormBuilder,
        UserService])
], ManageOrg);
export { ManageOrg };
