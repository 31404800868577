import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordNewFormFactory } from '../../../../../factories/password/new';
let CompleteRegistrationModal = class CompleteRegistrationModal {
    constructor(dialogRef, requiredAttributes) {
        this.dialogRef = dialogRef;
        this.requiredAttributes = requiredAttributes;
        this.loading = false;
    }
    ngOnInit() {
        this.form = (new FormBuilder)
            .group({
            given_name: [null, [Validators.required]],
            family_name: [null, [Validators.required]],
            newPassword: PasswordNewFormFactory.form(),
        });
    }
    onSubmit() {
        this.dialogRef.close(this.form.getRawValue());
    }
};
CompleteRegistrationModal = __decorate([
    Component({
        selector: 'user-new-password-required',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], CompleteRegistrationModal);
export { CompleteRegistrationModal };
