import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { CarrierWasteTransferNoteApi } from "../../../services/waste-transfer-note-api";
let WasteTransferNoteTransferor = class WasteTransferNoteTransferor {
    constructor(WasteTransferNoteApi) {
        this.WasteTransferNoteApi = WasteTransferNoteApi;
    }
    ngOnInit() {
        this.WasteTransferNoteApi._wasteTransferNote.subscribe((wasteTransferNote) => {
            this.jobId = wasteTransferNote ? wasteTransferNote.jobId : null;
            this.Transferor = wasteTransferNote
                ? wasteTransferNote.transferor
                : null;
        });
    }
};
WasteTransferNoteTransferor = __decorate([
    Component({
        selector: "waste-transfer-note-transferor",
        template: require('../../../../directives/waste-transfer-notes-view/transferor.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    }),
    __metadata("design:paramtypes", [CarrierWasteTransferNoteApi])
], WasteTransferNoteTransferor);
export { WasteTransferNoteTransferor };
