import { __awaiter, __decorate, __metadata } from "tslib";
import { Component, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";
import moment from "moment-timezone";
let SignatureDetails = class SignatureDetails {
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.todayDate = moment().startOf("day").toDate();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", FormGroup)
], SignatureDetails.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SignatureDetails.prototype, "today", void 0);
SignatureDetails = __decorate([
    Component({
        selector: 'signature-details',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], SignatureDetails);
export { SignatureDetails };
