import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UserService } from "./user-api";
import { LoginModal } from "../components/login/modal/component";
import { SiteRegistrationService } from "../../../app/services/v2/site-registration.service";
let RegistrationGuard = class RegistrationGuard {
    constructor(Router, UserService, siteRegistrationApi, dialog) {
        this.Router = Router;
        this.UserService = UserService;
        this.siteRegistrationApi = siteRegistrationApi;
        this.dialog = dialog;
    }
    canActivate() {
        return new Observable((observer) => {
            this.UserService.isLoggedIn()
                ? this.checkRegistirationIsCompleted(observer)
                : this.UserService.logInFromStorage()
                    .then(() => this.checkRegistirationIsCompleted(observer))
                    .then(() => observer.next(true), () => this.loginModal(observer));
        });
    }
    checkRegistirationIsCompleted(observer) {
        const organisation = this.UserService.getOrganisation();
        if (organisation !== null) {
            this.siteRegistrationApi
                .getWasteFacility(organisation.organisationId)
                .then((wasteFacilities) => {
                if (wasteFacilities && wasteFacilities.length > 0 && (organisation.companyType === "producer" || organisation.subscriptionId)) {
                    this.Router.navigate(["/"]);
                }
            })
                .then(() => observer.next(true));
        }
        else {
            observer.next(true);
        }
    }
    loginModal(observer) {
        // if not show login box
        let dialogRef = this.dialog.open(LoginModal);
        dialogRef.afterClosed().subscribe((loggedIn) => {
            !loggedIn && this.Router.navigate(["/"]);
            observer.next(loggedIn);
        });
    }
};
RegistrationGuard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Router,
        UserService,
        SiteRegistrationService,
        MatDialog])
], RegistrationGuard);
export { RegistrationGuard };
