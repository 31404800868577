import { __decorate, __metadata, __param } from "tslib";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { UserService } from "../../../../modules/user/services/user-api";
import { EditWaste } from "../../../../modules/broker/components/waste/edit/component";
import { WasteApi } from "../../../services/waste-api";
let ListWaste = class ListWaste {
    constructor(UserService, WasteApi, dialog, dialogRef, Consignment) {
        this.UserService = UserService;
        this.WasteApi = WasteApi;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.Consignment = Consignment;
        this.loading = false;
        this.canEdit = false;
        this._Waste = new BehaviorSubject([]);
    }
    ngOnInit() {
        this.canEdit =
            (this.consignmentBroker() && !this.consignmentAccepted()) ||
                (this.consignmentBroker() &&
                    this.consignmentCarrier() &&
                    !this.consignmentAllocated());
        this.subscribe = this._Waste.subscribe((items) => (this.Waste = items));
        this.load();
    }
    ngOnDestroy() {
        this.subscribe.unsubscribe();
    }
    load() {
        this.loading = true;
        this.WasteApi.list(this.Consignment.consignmentId).then((Waste) => {
            this._Waste.next(Waste);
            this.loading = false;
        });
    }
    edit(wasteId) {
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = {
            consignmentId: this.Consignment.consignmentId,
            quoted: !!this.Consignment.quoteProvideTimestamp &&
                !this.UserService.isInGroup("carrier"),
            wasteId: wasteId,
        };
        let dialogRef = this.dialog.open(EditWaste, config);
        dialogRef
            .afterClosed()
            .subscribe((Waste) => !!Waste && this.save(Waste));
    }
    remove(wasteId) {
        confirm("Are you sure you want to delete waste?") &&
            this.WasteApi.remove(this.Consignment.consignmentId, wasteId).then(() => this.load());
    }
    consignmentBroker() {
        return (this.Consignment.brokerId ==
            this.UserService.getOrganisation().organisationId);
    }
    consignmentCarrier() {
        return (this.Consignment.carrierId ==
            this.UserService.getOrganisation().organisationId);
    }
    consignmentAccepted() {
        return !!this.Consignment.jobAcceptTimestamp;
    }
    consignmentAllocated() {
        return !!this.Consignment.jobAllocateTimestamp;
    }
    save(Waste) {
        let consignmentId = this.Consignment.consignmentId;
        this.WasteApi.save(consignmentId, Waste).then((Waste) => this.load());
    }
};
ListWaste = __decorate([
    Component({
        selector: "list-waste",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __param(4, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [UserService,
        WasteApi,
        MatDialog,
        MatDialogRef, Object])
], ListWaste);
export { ListWaste };
