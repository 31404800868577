import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { AuthenticationDetails, CognitoUserPool, } from "amazon-cognito-identity-js";
import { environment } from "../../../app/constant/environment";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AuthService } from "./auth";
import { Router } from "@angular/router";
// import {RequestOptions} from '@angular/http';
import { Api } from "../../../app/services/api";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CompleteRegistrationModal } from "../components/password/complete-registration/component";
let UserService = class UserService {
    constructor(Api, AuthService, dialog, Router) {
        this.Api = Api;
        this.AuthService = AuthService;
        this.dialog = dialog;
        this.Router = Router;
        this.UserAttributes = {};
    }
    login(Login) {
        Login.email = Login.email.toLowerCase();
        return new Promise((resolve, reject) => {
            if (this.AuthService.setCognitoUser(Login.email)) {
                let authenticationDetails = new AuthenticationDetails({
                    Username: Login.email,
                    Password: Login.password,
                });
                this.AuthService.getCognitoUser().authenticateUser(authenticationDetails, {
                    newPasswordRequired: (userAttributes, requiredAttributes) => this.newPasswordRequired(userAttributes, requiredAttributes),
                    onSuccess: (session) => {
                        this.setSession(session).then(() => resolve(true), (error) => reject(error));
                    },
                    onFailure: (error) => reject(error),
                });
            }
            else {
                reject("Could not set cognito user.");
            }
        });
    }
    getUserDetail() {
        let endpoint = environment.endpoint.user.user();
        return this.Api.request(endpoint, "GET");
    }
    getUsername() {
        let CognitoUser = this.AuthService.getCognitoUser();
        return (!!CognitoUser && CognitoUser.getUsername()) || null;
    }
    getOrganisation() {
        return this.Organisation;
    }
    checkCompanyTypeIsInTheList(companyTypes) {
        if (this.Organisation)
            return (companyTypes.findIndex((f) => f === this.Organisation.companyType.toLowerCase()) > -1);
        return false;
    }
    getUserAttributes() {
        return this.UserAttributes;
    }
    register(Register) {
        Register.email = Register.email.toLowerCase();
        return new Promise((resolve, reject) => {
            let userPool = new CognitoUserPool(environment.cognito.poolData);
            let userAttributes = [];
            userAttributes.push(new CognitoUserAttribute({
                Name: "email",
                Value: Register.email,
            }));
            userAttributes.push(new CognitoUserAttribute({
                Name: "given_name",
                Value: Register.given_name,
            }));
            userAttributes.push(new CognitoUserAttribute({
                Name: "family_name",
                Value: Register.family_name,
            }));
            userAttributes.push(new CognitoUserAttribute({
                Name: "phone_number",
                Value: Register.phone_number,
            }));
            userPool.signUp(Register.email, Register.newPassword.password, userAttributes, null, (error, result) => {
                if (error) {
                    reject(error);
                }
                resolve(result);
            });
        });
    }
    confirmRegistration(CognitoUser, verificationCode) {
        return new Promise((resolve, reject) => {
            if (this.AuthService.setCognitoUser(CognitoUser.getUsername())) {
                this.AuthService.getCognitoUser().confirmRegistration(verificationCode, true, (error, result) => {
                    if (error) {
                        reject(error);
                    }
                    resolve(result);
                });
            }
        });
    }
    isLoggedIn() {
        return !!this.AuthService.getJwt();
    }
    changePassword(ChangePassword) {
        return new Promise((resolve, reject) => {
            let CognitoUser = this.AuthService.getCognitoUser();
            if (!!CognitoUser) {
                CognitoUser.changePassword(ChangePassword.oldPassword, ChangePassword.newPassword.password, (error, result) => {
                    if (error) {
                        reject(error);
                    }
                    resolve(result);
                });
            }
            else {
                reject();
            }
        });
    }
    forgotPassword(email) {
        email = email.toLowerCase();
        return new Promise((resolve, reject) => {
            if (this.AuthService.setCognitoUser(email)) {
                this.AuthService.getCognitoUser().forgotPassword({
                    onSuccess: () => resolve(),
                    onFailure: (error) => reject(error),
                });
            }
            else {
                reject();
            }
        });
    }
    resetPassword(verificationCode, newPassword) {
        return new Promise((resolve, reject) => {
            let CognitoUser = this.AuthService.getCognitoUser();
            if (!!CognitoUser) {
                CognitoUser.confirmPassword(verificationCode, newPassword, {
                    onSuccess: () => resolve(),
                    onFailure: (error) => reject(error),
                });
            }
            else {
                reject();
            }
        });
    }
    logout() {
        this.Organisation = null;
        this.UserAttributes = null;
        this.AuthService.signOut();
        this.Router.navigate(["/login"]);
    }
    isInGroup(group) {
        let jwt = this.AuthService.getJwt();
        return (!!jwt &&
            !!jwt["cognito:groups"] &&
            jwt["cognito:groups"].indexOf(group) !== -1);
    }
    logInFromStorage() {
        return new Promise((resolve, reject) => {
            if (this.AuthService.setCognitoUser()) {
                this.AuthService.getCognitoUser().getSession((error, session) => {
                    if (!error && session.isValid()) {
                        resolve(this.setSession(session));
                    }
                    else {
                        reject(error);
                    }
                });
            }
            else {
                reject();
            }
        });
    }
    newPasswordRequired(userAttributes, requiredAttributes) {
        delete userAttributes.email_verified;
        delete userAttributes.email;
        let config = new MatDialogConfig();
        config.disableClose = true;
        config.data = requiredAttributes;
        this.dialog
            .open(CompleteRegistrationModal, config)
            .afterClosed()
            .subscribe((CompleteRegistration) => {
            userAttributes["given_name"] = CompleteRegistration.given_name;
            userAttributes["family_name"] = CompleteRegistration.family_name;
            this.AuthService.getCognitoUser().completeNewPasswordChallenge(CompleteRegistration.newPassword.password, userAttributes, {
                onSuccess: () => this.forwardToDashboard("Password has been changed Successfully. Please click OK and continue."),
                onFailure: () => this.forwardToLogin("Could not change password"),
            });
        });
    }
    setSession(session) {
        return this.AuthService.setCredentials(session)
            .then(() => this.setUserAttributes())
            .then(() => this.setOrganisation());
    }
    setOrganisation() {
        return new Promise((resolve, reject) => {
            try {
                let endpoint = environment.endpoint.user.organisations("me");
                // let options  = new RequestOptions();
                this.Api.request(endpoint, "GET").then((Organisation) => resolve((this.Organisation = Organisation)), (error) => {
                    error.error.error === "Could not fetch user organisation."
                        ? resolve((this.Organisation = null))
                        : reject(error);
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    setUserAttributes() {
        return new Promise((resolve, reject) => {
            try {
                let CognitoUser = this.AuthService.getCognitoUser();
                CognitoUser.getUserAttributes((Error, UserAttributes) => {
                    if (!Error) {
                        let attributes = {};
                        UserAttributes.map((value) => {
                            attributes[value["Name"]] = value["Value"];
                        });
                        resolve((this.UserAttributes = attributes));
                    }
                    else {
                        reject(Error);
                    }
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    forwardToDashboard(message) {
        !!message && alert(message);
        this.Router.navigate(["/dashboard"]);
    }
    forwardToLogin(message) {
        !!message && alert(message);
        this.Router.navigate(["/login"]);
    }
};
UserService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api,
        AuthService,
        MatDialog,
        Router])
], UserService);
export { UserService };
