import { __awaiter, __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { UserService } from "../../../user/services/user-api";
import { SubscriptionApi } from "../../services/subscription-api";
let UpdateSubscription = class UpdateSubscription {
    constructor(userService, SubscriptionApi) {
        this.userService = userService;
        this.SubscriptionApi = SubscriptionApi;
        this.selectedPlan = null;
        this.loading = true;
        this.updateSubscription = (selectedPriceId) => __awaiter(this, void 0, void 0, function* () {
            if (this.organisation.subscriptionId) {
                yield this.SubscriptionApi.update({
                    subscriptionId: this.organisation.subscriptionId,
                    selectedPriceId,
                }).then((data) => {
                    this.load();
                });
            }
            else {
                yield this.SubscriptionApi.create({
                    selectedPriceId
                }).then((data) => {
                    setTimeout(() => {
                        this.userService.setOrganisation().then(() => {
                            this.organisation = this.userService.getOrganisation();
                            this.load();
                        });
                    }, 2000);
                });
            }
        });
    }
    ngOnInit() {
        this.organisation = this.userService.getOrganisation();
        this.load();
    }
    load() {
        this.loading = true;
        this.selectedPlan = null;
        if (this.organisation.subscriptionId) {
            setTimeout(() => {
                this.SubscriptionApi.retrive(this.organisation.subscriptionId).then((subscriptionId) => {
                    this.selectedPlan = subscriptionId;
                    this.loading = false;
                });
            }, 2000);
        }
    }
};
UpdateSubscription = __decorate([
    Component({
        selector: "update-subscription",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [UserService,
        SubscriptionApi])
], UpdateSubscription);
export { UpdateSubscription };
