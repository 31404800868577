import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteWastePhotos = class ConsignmentNoteWastePhotos extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "wastePhotos";
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.WastePhotos = !!consignmentNote
                ? consignmentNote.wastePhotos
                : null;
        });
    }
};
ConsignmentNoteWastePhotos = __decorate([
    Component({
        selector: "consignment-note-waste-photos",
        template: require('../../../../directives/waste-transfer-notes-view/waste-photos.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/waste-photos.scss'),
        ],
    })
], ConsignmentNoteWastePhotos);
export { ConsignmentNoteWastePhotos };
