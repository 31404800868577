import { __decorate } from "tslib";
import { Component } from '@angular/core';
let ListPagination = class ListPagination {
};
ListPagination = __decorate([
    Component({
        selector: 'list-pagination',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], ListPagination);
export { ListPagination };
