import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "./api";
import moment from "moment";
let JobApi = class JobApi {
    constructor(Api) {
        this.Api = Api;
    }
    list(status) {
        let endpoint = this.getEndpoint(status);
        return this.Api.request(endpoint);
    }
    get(jobId) {
        let endpoint = this.getEndpoint(jobId);
        return this.Api.request(endpoint);
    }
    save(consignmentId, Job) {
        return this.post(consignmentId, Job);
    }
    closeJob(jobId, Job) {
        let body = Object.assign(Job);
        let endpoint = this.getEndpoint(jobId, "close");
        return this.Api.request(endpoint, "POST", body);
    }
    saveEvidenceFile(putUrl, blob) {
        return this.Api.s3PutObject(putUrl, blob);
    }
    remove(jobId) {
        let endpoint = this.getEndpoint(jobId);
        return this.Api.request(endpoint, "DELETE");
    }
    post(consignmentId, Job) {
        let body = Object.assign(Job);
        body.collectionDate = moment(body.collectionDate).format("YYYY-MM-DD");
        body.dropOffDate = moment(body.dropOffDate).format("YYYY-MM-DD");
        let endpoint = this.getConsignmentEndpoint(consignmentId, "allocate");
        return this.Api.request(endpoint, "POST", body);
    }
};
JobApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], JobApi);
export { JobApi };
