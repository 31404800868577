import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "./api";
import { environment } from "../constant/environment";
let OrganisationApi = class OrganisationApi {
    constructor(Api) {
        this.Api = Api;
    }
    list() {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint);
    }
    get(organisationId) {
        let endpoint = this.getEndpoint(organisationId);
        return this.Api.request(endpoint);
    }
    save(Organisation) {
        return !!Organisation.organisationId
            ? this.put(Organisation.organisationId, Organisation)
            : this.post(Organisation);
    }
    remove(organisationId) {
        let endpoint = this.getEndpoint(organisationId);
        return this.Api.request(endpoint, "DELETE");
    }
    post(Organisation) {
        let endpoint = this.getEndpoint();
        return this.Api.request(endpoint, "POST", Organisation);
    }
    put(organisationId, Organisation) {
        let endpoint = this.getEndpoint(organisationId);
        return this.Api.request(endpoint, "PUT", Organisation);
    }
    patch(Organisation) {
        let endpoint = environment.endpoint.admin.updateOrganisation();
        return this.Api.request(endpoint, "PATCH", Organisation);
    }
    checkIfCompanyExists(companyNumber) {
        let endpoint = environment.endpoint.admin.checkCompanyNumber(companyNumber);
        return this.Api.request(endpoint);
    }
};
OrganisationApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], OrganisationApi);
export { OrganisationApi };
