import { __decorate, __metadata } from "tslib";
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BrokerQuoteApi } from '../../../../services/quote-api';
import { ViewQuote } from '../../../quotes/view/component';
import { Dashboard } from '../../../../services/dashboard';
let ConsignmentItem = class ConsignmentItem {
    constructor(QuoteApi, Dashboard, dialog) {
        this.QuoteApi = QuoteApi;
        this.Dashboard = Dashboard;
        this.dialog = dialog;
        this.requestQuote = new EventEmitter();
        this.listWaste = new EventEmitter();
        this.editItem = new EventEmitter();
        this.deleteItem = new EventEmitter();
        this.open = true;
    }
    ngOnInit() {
        // Convert indexById to Array
        this.item.Quotes = !!this.item.Quotes && Object.keys(this.item.Quotes).map((key) => this.item.Quotes[key]) || null;
    }
    viewQuote(QuoteRequest) {
        let config = new MatDialogConfig;
        config.data = QuoteRequest;
        config.disableClose = true;
        let dialog = this.dialog.open(ViewQuote, config);
        dialog
            .afterClosed()
            .subscribe(() => this.Dashboard.load());
    }
    acceptQuote(Quote) {
        this.QuoteApi
            .acceptQuote(Quote)
            .then(() => this.Dashboard.load());
    }
    declineQuote(Quote) {
        alert('Decline Quote not implemented yet...');
    }
    toggleQuotes() {
        this.open = !this.open;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ConsignmentItem.prototype, "item", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ConsignmentItem.prototype, "status", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], ConsignmentItem.prototype, "requestQuote", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], ConsignmentItem.prototype, "listWaste", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], ConsignmentItem.prototype, "editItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], ConsignmentItem.prototype, "deleteItem", void 0);
ConsignmentItem = __decorate([
    Component({
        selector: '[consignment-item]',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [BrokerQuoteApi,
        Dashboard,
        MatDialog])
], ConsignmentItem);
export { ConsignmentItem };
