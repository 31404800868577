import { __decorate, __metadata } from "tslib";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../constant/environment";
let EnvironmentAgencyService = class EnvironmentAgencyService {
    constructor(Http) {
        this.Http = Http;
    }
    validateWastePermit(permitNumber) {
        let endpoint = this.getPermitEndpoint(permitNumber);
        return this.Http.get(endpoint).toPromise();
    }
    validateWasteExemption(permitNumber) {
        let endpoint = this.getExemptionEndpoint(permitNumber);
        return this.Http.get(endpoint).toPromise();
    }
    validateAddress(address, verificationType) {
        let endpoint = this.getValidateAddressEndpoint(verificationType);
        return this.Http.get(endpoint, {
            params: { address: address },
        }).toPromise();
    }
    getValidateAddressEndpoint(verificationType) {
        return environment.endpoint.admin.environmentAgency(verificationType === "permit"
            ? "environment-agency-permit"
            : "environment-agency-exemption", null);
    }
    getPermitEndpoint(permitNumber) {
        return environment.endpoint.admin.environmentAgency("environment-agency-permit", permitNumber);
    }
    getExemptionEndpoint(permitNumber) {
        return environment.endpoint.admin.environmentAgency("environment-agency-exemption", permitNumber);
    }
};
EnvironmentAgencyService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient])
], EnvironmentAgencyService);
export { EnvironmentAgencyService };
