import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { environment } from "../../../app/constant/environment";
import { Api } from "../../../app/services/api";
let InviteRecyclersApi = class InviteRecyclersApi {
    constructor(Api) {
        this.Api = Api;
    }
    inviteRecyclers(email) {
        let endpoint = environment.endpoint.admin.inviteRecyclers();
        return this.Api.request(endpoint, "POST", email);
    }
};
InviteRecyclersApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], InviteRecyclersApi);
export { InviteRecyclersApi };
