import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordNewFormFactory } from './new';
let PasswordChangeFormFactory = class PasswordChangeFormFactory {
    static form() {
        return (new FormBuilder).group({
            oldPassword: [null, Validators.required],
            newPassword: PasswordNewFormFactory.form(),
        });
    }
};
PasswordChangeFormFactory = __decorate([
    Injectable()
], PasswordChangeFormFactory);
export { PasswordChangeFormFactory };
