import { __decorate } from "tslib";
import { Component } from "@angular/core";
import { ConsignmentNote as ConsignmentNoteBase } from "./consignment-note/component";
let ConsignmentNoteQuote = class ConsignmentNoteQuote extends ConsignmentNoteBase {
    constructor() {
        super(...arguments);
        this.section = "quote";
    }
    ngOnInit() {
        this.ConsignmentNoteApi._consignmentNote.subscribe((consignmentNote) => {
            this.jobId = !!consignmentNote ? consignmentNote.jobId : null;
            this.Quote = !!consignmentNote ? consignmentNote.quote : null;
        });
    }
};
ConsignmentNoteQuote = __decorate([
    Component({
        selector: "consignment-note-quote",
        template: require('../../../../directives/view-consignment-note/quote.html'),
        styles: [
            require('../../../../directives/waste-transfer-notes-view/component.scss'),
        ],
    })
], ConsignmentNoteQuote);
export { ConsignmentNoteQuote };
