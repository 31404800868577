import { __decorate, __metadata } from "tslib";
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
let AddFile = class AddFile {
    constructor() {
        this.maxFileSizeMB = 10; // This is a standard attachment size in email
        this.invalidFileSize = null;
    }
    onFileChange(event) {
        this.invalidFileSize = null;
        let fileList = event.target.files;
        if (fileList.length > 0) {
            let reader = new FileReader();
            reader.onload = ((file) => (event) => {
                this.fileSizeValid(file) && this.addFile(file);
            })(event.target.files[0]);
            reader.readAsArrayBuffer(event.target.files[0]);
        }
        else {
            this.removeFile();
        }
    }
    addFile(blob) {
        this.FormControl.patchValue(blob);
    }
    fileSizeValid(blob) {
        const fileSizeInMB = Number((blob.size / (1024 * 1024)).toFixed(2));
        const valid = (fileSizeInMB < this.maxFileSizeMB);
        if (!valid) {
            this.fileInput.nativeElement.value = null;
            this.invalidFileSize = fileSizeInMB;
        }
        return valid;
    }
    removeFile() {
        this.FormControl.reset();
        this.FormControl.reset();
    }
};
__decorate([
    ViewChild('fileInput'),
    __metadata("design:type", Object)
], AddFile.prototype, "fileInput", void 0);
__decorate([
    Input(),
    __metadata("design:type", FormControl)
], AddFile.prototype, "FormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AddFile.prototype, "label", void 0);
AddFile = __decorate([
    Component({
        selector: 'add-file',
        template: require('./component.html'),
        styles: [require('./component.scss')],
    })
], AddFile);
export { AddFile };
