import { __decorate, __metadata } from "tslib";
import { Injectable } from "@angular/core";
import { Api } from "../api";
import { BehaviorSubject } from "rxjs";
let WasteTransferNoteApi = class WasteTransferNoteApi {
    constructor(Api) {
        this.Api = Api;
        this._loading = new BehaviorSubject(false);
        this._wasteTransferNote = new BehaviorSubject(null);
    }
    setWasteTransferNote(wasteTransferNote) {
        this._wasteTransferNote.next(wasteTransferNote);
    }
    getWasteTransferNote(jobId) {
        this._loading.next(true);
        const endpoint = this.getEndpoint(jobId);
        return this.Api.request(endpoint, "GET")
            .then((wasteTransferNote) => {
            this._wasteTransferNote.next(wasteTransferNote);
            this._loading.next(false);
        })
            .catch(() => this._wasteTransferNote.next(null));
    }
};
WasteTransferNoteApi = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Api])
], WasteTransferNoteApi);
export { WasteTransferNoteApi };
