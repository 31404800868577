import { __decorate, __metadata } from "tslib";
import { Component } from "@angular/core";
import { UserService } from "../../../user/services/user-api";
let AdminMenu = class AdminMenu {
    constructor(UserService) {
        this.UserService = UserService;
    }
    loggedIn() {
        return this.UserService.isLoggedIn();
    }
    checkCompanyTypeIsInTheList(companyTypes) {
        return this.UserService.checkCompanyTypeIsInTheList(companyTypes);
    }
    isAdmin() {
        return this.UserService.isInGroup("admin");
    }
};
AdminMenu = __decorate([
    Component({
        selector: "admin-menu",
        template: require('./component.html'),
        styles: [require('./component.scss')],
    }),
    __metadata("design:paramtypes", [UserService])
], AdminMenu);
export { AdminMenu };
